import React from 'react';
import TableCell from '@mui/material/TableCell';

import Button from '@cloudhub-ux/core/Button';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@cloudhub-ux/core/dialogs';
import { colors } from 'theme';
import AdminContext from 'admin/context/AdminContext';

const User = ({ pushJudges, onClose, open }) => {
  const [judges, setjudges] = React.useState([]);
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const onChangeSelection = (selection) => {
    setjudges(
      selection.map((s) => ({
        JudgeName: s.Name,
        JudgeId: s.id,
        Strengths: s.Strengths,
      }))
    );
  };

  const cellComponent = ({ row, column }) => (
    <TableCell>
      {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
    </TableCell>
  );
  const cols = [
    { name: 'Name', title: 'Full Name' },
    { name: 'Email', title: 'Email' },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogHeader>Add Judges</DialogHeader>
      <DialogContent>
        <RemoteDataGrid
          url="/system/user/judge"
          axiosinstance={adminaxiosinstance}
          header={() => <></>}
          actionsComponent={() => <></>}
          cellComponent={cellComponent}
          columns={cols}
          allowColumnResizing={false}
          onChangeSelection={onChangeSelection}
          pagingComponent={null}
        />
      </DialogContent>
      <DialogActions>
        <Button color={colors.primary} onClick={() => pushJudges(judges)}>
          Add Judges
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default User;
