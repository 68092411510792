import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import AdminRoute from 'admin/authroutes/AdminRoute';
import Infopage from './Infopage';
import Testimony from './Testimony';
import Partner from './Partner';
import Nominee from './Nominee';
import Entrycategory from './Entrycategory';
import Mediahouse from './Mediahouse';
import Message from './Message';
import Mediastation from './Mediastation';

const WesiteRoutes = () => (
  <Router style={{ flex: 1 }}>
    <AdminRoute component={Infopage} path="infopages" />
    <AdminRoute component={Testimony} path="testimonies" />
    <AdminRoute component={Partner} path="partners" />

    <AdminRoute component={Nominee} path="nominees" />
    <AdminRoute component={Entrycategory} path="categories" />
    <AdminRoute component={Mediahouse} path="mediahouses" />
    <AdminRoute component={Mediastation} path="mediastations" />
    <AdminRoute component={Message} path="messages" />
  </Router>
);

export default WesiteRoutes;
