/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { navigate } from '@cloudhub-ux/core/reach';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Button from '@cloudhub-ux/core/Button';
import { sizes, colors } from 'theme';

const SessionExpired = (props) => {
  const { location } = props || {};
  const onSignIn = () => {
    navigate('/auth/signin', {
      state: {
        pathnamebeforelogin:
          location && location.pathname ? location.pathname : '',
      },
    });
  };

  return (
    <Block center middle>
      <Block flex={false} padding={sizes.padding}>
        <Text center style={{ fontSize: 52 }}>
          😢
        </Text>
        <Text h5>Your session has expired</Text>

        <Button contained rounded color={colors.primary} onClick={onSignIn}>
          <Text milkyWhite>Sign in</Text>
        </Button>
      </Block>
    </Block>
  );
};

export default SessionExpired;
