import React from 'react';
import useAdminAxios from 'admin/context/useAdminAxios';

import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Input from '@cloudhub-ux/core/Input';
import FieldButton from '@cloudhub-ux/core/FieldButton';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors } from 'theme';

const SyncSms = ({ onSynced = () => {} }) => {
  const { postData } = useAdminAxios({});
  const syncSms = async (values) => {
    try {
      const data = await postData({
        url: '/entry/votesms/syncsms',
        params: values,
      });
      if (data) {
        onSynced();
      }
    } catch (error) {}
  };
  const cureSms = async () => {
    try {
      const data = await postData({
        url: '/entry/votesms/cure',
      });
      if (data) {
        onSynced();
      }
    } catch (error) {}
  };
  return (
    <Form
      onSubmit={syncSms}
      render={({ submitting, pristine, handleSubmit }) => (
        <>
          <FieldBlock row>
            <Field
              type="text"
              label="Last Received SMS ID"
              component={Input}
              name="lastReceived"
            />
            <FieldButton
              color={colors.primary}
              onClick={handleSubmit}
              disabled={pristine || submitting}
            >
              Sync
            </FieldButton>
          </FieldBlock>
          <FieldButton color={colors.primary} onClick={cureSms}>
            Cure
          </FieldButton>
        </>
      )}
    />
  );
};

export default SyncSms;
