import React, { Fragment } from 'react';
import Dialog, {
  DialogHeader,
  DialogActions,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';
import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import TextArea from '@cloudhub-ux/core/TextArea';
import Input from '@cloudhub-ux/core/Input';
import CheckBox from '@cloudhub-ux/core/CheckBox';
import { Form, Field } from '@cloudhub-ux/core/form';
import useAdminAxios from 'admin/context/useAdminAxios';
import { colors, sizes } from 'theme';

const JudgingLevelForm = ({
  editingRow,
  onCancelEdit = () => {},
  onSave = () => {},
}) => {
  const { postData } = useAdminAxios({});

  const handleFormSubmit = async (values) => {
    try {
      const data = await postData({
        url: `/judging/judginglevel/${values.id ? 'update' : 'new'}`,
        params: values,
      });
      if (data) {
        onSave();
      }
    } catch (error) {}
  };

  return (
    <Dialog open={editingRow !== null} maxWidth="lg" minHeight={400}>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={editingRow}
        render={({ handleSubmit, pristine, submitting }) => (
          <Fragment>
            <DialogHeader onClose={onCancelEdit}>
              {(editingRow || {}).Name || 'Add Level'}
            </DialogHeader>
            <DialogContent>
              <Block padding={sizes.padding}>
                <Field
                  name="Name"
                  label="Level Name"
                  component={Input}
                  required
                />
                <Field
                  name="Description"
                  label="Description"
                  component={TextArea}
                />
                <Field
                  name="Moderation"
                  tag="Is this a moderation level?"
                  height={sizes.icons.small}
                  component={CheckBox}
                />
              </Block>
            </DialogContent>
            <DialogActions>
              <Button contained onClick={onCancelEdit} color={colors.secondary}>
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={pristine || submitting}
                color={colors.primary}
              >
                Save
              </Button>
            </DialogActions>
          </Fragment>
        )}
      />
    </Dialog>
  );
};

export default JudgingLevelForm;
