import React from 'react';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';
import SendSmsForm from 'admin/forms/SendSmsForm';
import JudgeForm from './JudgeForm';

const JudgingLevel = () => {
  const [editingRow, seteditingRow] = React.useState(null);
  const [phonenumbers, setphonenumbers] = React.useState([]);
  const [smsDialogOpen, openSmsDialog] = React.useState(false);
  const gridRef = React.useRef();
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const { postData } = useAdminAxios({});
  const deleteRows = async (rows) => {
    try {
      const ids = rows.map((item) => item.id);
      const data = await postData({
        url: '/judging/judginglevel/delete',
        params: { levels: ids },
      });
      if (data) {
        gridRef.current.reload();
      }
    } catch (error) {}
  };

  const onChangeSelection = (selection) => {
    const numbers = [];
    if (Array.isArray(selection)) {
      selection.map((s) => {
        if (numbers.indexOf(s.Phone) === -1) {
          numbers.push(s.Phone);
        }
        return null;
      });
    }
    setphonenumbers(numbers);
    // gridRef.current.reload();
  };
  const onOpenSmsDialog = () => {
    openSmsDialog(true);
  };

  const renderHeader = ({ onSearch, onRefresh, title, onAdd }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={onAdd} disabled={!phonenumbers}>
            <AddIcon /> Add
          </Button>
          <Button onClick={onOpenSmsDialog} disabled={!phonenumbers}>
            <SendIcon /> Send SMS to Selected
          </Button>
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
        </Block>
      </Block>
      <Block />
    </Block>
  );

  const renderTemplates = ({ row, column }) => (
    // row, column, style
    <TableCell>
      <Text bold>{row[column.name] || ''}</Text>
    </TableCell>
  );
  const cols = [
    { name: 'Name', title: 'Level Name' },
    { name: 'Description', title: 'Description' },
  ];

  const columnWidths = [
    { columnName: 'Name', width: 150 },
    { columnName: 'Description', width: 500 },
  ];

  return (
    <Block>
      {!editingRow && (
        <RemoteDataGrid
          title={<Text h2>Judges</Text>}
          columns={cols}
          columnWidths={columnWidths}
          allowColumnResizing={false}
          header={renderHeader}
          url="/system/user/judge"
          axiosinstance={adminaxiosinstance}
          cellComponent={renderTemplates}
          onAdd={() => seteditingRow({})}
          onDeleteRows={(row) => deleteRows(row)}
          onEdit={seteditingRow}
          ref={gridRef}
          pagingComponent={null}
          onChangeSelection={onChangeSelection}
        />
      )}
      <JudgeForm
        editingRow={editingRow}
        onCancelEdit={() => {
          seteditingRow(null);
        }}
      />

      <SendSmsForm
        numbers={phonenumbers}
        open={smsDialogOpen}
        onClose={() => openSmsDialog(false)}
      />
    </Block>
  );
};

export default JudgingLevel;
