import React from 'react';
import AdminContext from './AdminContext';

const useAdminAxios = (props) => {
  const { url, params, initialState } = {
    initialState: {},
    params: {},
    url: '',
    ...(props || {}),
  };
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(initialState);

  const postData = React.useMemo(
    () => async ({ url, params }) => {
      try {
        setLoading(true);
        const { data } = await adminaxiosinstance().post(`${url}`, {
          ...params,
        });

        return data;
      } catch (error) {
        const response = error.response || {};
        const data = response.data || response.body || {};

        setError(`${data.message || 'Server error'}`.replace(/_/g, ' '));
      }
      setLoading(false);
    },
    // eslint-disable-next-line
    []
  );

  const getData = React.useMemo(
    () => async ({ url, params }) => {
      try {
        setLoading(true);
        const { data } = await adminaxiosinstance().get(`${url}`, {
          params,
        });
        setLoading(false);

        return data;
      } catch (error) {
        const response = error.response || {};
        const data = response.data || response.body || {};
        setError(`${data.message || 'Server error'}`.replace(/_/g, ' '));
      }
      setLoading(false);
    },
    // eslint-disable-next-line
    [adminaxiosinstance, url]
  );

  const hydrateState = async () => {
    const data = await getData({ url, params });
    setData(data);
  };

  const reset = () => {
    setError('');
    setLoading(false);
    setData(initialState);
  };

  React.useEffect(() => {
    if (url) {
      hydrateState();
    }
    // eslint-disable-next-line
  }, [url, JSON.stringify(params)]);

  return { data, error, loading, getData, postData, reset, adminaxiosinstance };
};

export default useAdminAxios;
