import React from 'react';
import ReactDOM from 'react-dom';
import { preloadReady } from '@react-loadable/revised';

import swStore from 'serviceworkers/store/swStore';
import { SW_INIT, SW_UPDATE } from 'serviceworkers/store/swActions';
import * as serviceWorker from './serviceWorker';

// import './index.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import App from './App';

const dispatch = swStore.getState().dispatch;

if (process.env.NODE_ENV === 'production') {
  window.onload = async () => {
    try {
      await preloadReady();
    } catch (e) {
      console.error(e);
    } finally {
      ReactDOM.hydrate(<App />, document.getElementById('root'));
    }
  };

  serviceWorker.register({
    onSuccess: () => {
      dispatch({ type: SW_INIT });
    },
    onUpdate: (reg) => {
      dispatch({
        type: SW_UPDATE,
        payload: reg,
      });
    },
  });
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}
