import React from 'react';
import TableCell from '@mui/material/TableCell';
import Button from '@cloudhub-ux/core/Button';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import IconButton from '@cloudhub-ux/core/IconButton';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import Visibility from '@mui/icons-material/Visibility';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { colors } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';
import ResultsForm from '../forms/ResultsForm';
import SendSmsForm from '../../forms/SendSmsForm';
import ColumnSelector from './ColumnSelector';

const Entry = ({ JudgingLevelId, JudgingLevelName }) => {
  const [exportlist, setexportlist] = React.useState([]);
  const [maxvariance, setmaxvariance] = React.useState(0);
  const [viewing, setviewing] = React.useState(null);
  const [smsDialogOpen, openSmsDialog] = React.useState(false);
  const [phonenumbers, setphonenumbers] = React.useState([]);
  const [headers, setHeaders] = React.useState([]);
  const [exporting, setexporting] = React.useState(false);

  const { adminaxiosinstance } = React.useContext(AdminContext);

  const csvLinkElem = React.useRef();

  const { getData } = useAdminAxios({});

  const onOpenSmsDialog = () => {
    try {
      if (Array.isArray(phonenumbers) && phonenumbers.length > 0) {
        openSmsDialog(true);
      }
    } catch (error) {}
  };

  const getCriteria = React.useCallback(async () => {
    try {
      const data = await getData({
        url: '/judging/judgingcriteria/getcriteria',
        params: { JudgingLevelId },
      });
      if (data && data.item && data.item.MaximumVariance) {
        setmaxvariance(Number(data.item.MaximumVariance) || 0);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [JudgingLevelId]);

  React.useEffect(() => {
    getCriteria();
  }, [getCriteria, JudgingLevelId]);

  const transformEntry = (item) => {
    const { EntryName, JudgingResults, AverageScore, EntryId } = item || {};
    let JudgePoints = '';
    let Variance = 0;
    if (Array.isArray(JudgingResults)) {
      JudgePoints = JudgingResults.map((result) => {
        let points = 0;
        if (
          result.Points &&
          typeof result.Points === 'object' &&
          result.Final
        ) {
          const keys = Object.keys(result.Points);
          if (keys && keys.length > 0) {
            keys.forEach((k) => {
              points += result.Points[k];
            });
            return `${result.JudgeName}: ${points}`;
          }
        }

        return `${result.JudgeName}: ${points}`;
      }).join(', ');

      const allpoints = [...(JudgingResults || [])].map((result) => {
        if (
          result.Points &&
          typeof result.Points === 'object' &&
          result.Final
        ) {
          const keys = Object.keys(result.Points);
          if (keys && keys.length > 0) {
            let awarded = 0;
            keys.forEach((k) => {
              awarded += result.Points[k];
            });
            return awarded;
          }
        }
        return null;
      });
      const min =
        allpoints && allpoints.length > 0 ? Math.min(...allpoints) : 0;
      const max =
        allpoints && allpoints.length > 0 ? Math.max(...allpoints) : 0;
      Variance = max - min;
    }
    return {
      EntryName,
      AverageScore,
      JudgePoints,
      Variance,
      EntryId,
    };
  };
  const populateTable = async (event) => {
    try {
      event.preventDefault();
      const entries = (exportlist || [])
        .map(({ EntryId }) => EntryId)
        .filter(Boolean);
      const data = await getData({
        url: '/entry/entry/getentries',
        params: { entries, judginglevelid: JudgingLevelId },
      });

      if (data && Array.isArray(data.items)) {
        if ((exportlist || []).length > 0) {
          setexportlist((current) => {
            const newlist = (current || [])
              .map((entry) => {
                if (entry && entry.EntryId) {
                  const ind = data.items.findIndex(
                    ({ id }) => id === entry.EntryId
                  );
                  const fullentry = data.items[ind] || {};
                  return {
                    ...entry,
                    ...fullentry,
                    Name:
                      fullentry.Name ||
                      `${fullentry.FirstName} ${fullentry.Surname}`,
                    Freelancer: fullentry.Freelancer ? 'Yes' : 'No',
                    TeamMemberNames: (fullentry.TeamMemberNames || [])
                      .map(
                        (member) =>
                          `${(member || {}).Name}||${member}${
                            (member || {}).Phone ? `(${member.Phone})` : ''
                          }`
                      )
                      .join(', '),
                    DatePublished: moment(
                      fullentry.DatePublished || 0
                    ).format(),
                    Synopsis: (fullentry.Narration || fullentry.Synopsis || '')
                      .replace(/[^a-zA-Z0-9.,;"']/g, ' ')
                      .replace('"', "'"),
                    Title: (fullentry.Title || '')
                      .replace(/[^a-zA-Z0-9.,;"']/g, ' ')
                      .replace('"', "'"),
                    MediaOrganization:
                      (fullentry.MediaOrganization || {}).MediahouseName ||
                      fullentry.MediaOrganization ||
                      '',
                  };
                }
                return false;
              })
              .filter(Boolean);
            return newlist;
          });
        } else {
          const results = await getData({
            url: '/judging/judgingresult/entries',
            params: {
              JudgingLevel: JudgingLevelId,
            },
          });
          if (Array.isArray(results.items)) {
            setexportlist(() => {
              const newlist = (results.items || [])
                .map((item) =>
                  transformEntry({
                    ...(item || {}),
                    CategoryName: `${
                      ((item || {}).Category || {}).CategoryName || ''
                    } - ${item.StoryFormat || ''}`,
                    ...(judgingResults(item) || {}),
                  })
                )
                .map((entry) => {
                  if (entry && entry.EntryId) {
                    const ind = data.items.findIndex(
                      ({ id }) => id === entry.EntryId
                    );
                    const fullentry = data.items[ind] || {};
                    return {
                      ...entry,
                      ...fullentry,
                      Freelancer: fullentry.Freelancer ? 'Yes' : 'No',
                      TeamMemberNames: (fullentry.TeamMemberNames || [])
                        .map(
                          (member) =>
                            `${(member || {}).Name}||${member}${
                              (member || {}).Phone ? `(${member.Phone})` : ''
                            }`
                        )
                        .join(', '),
                      DatePublished: moment(
                        fullentry.DatePublished || 0
                      ).format(),
                      Synopsis: (
                        fullentry.Narration ||
                        fullentry.Synopsis ||
                        ''
                      )
                        .replace(/[^a-zA-Z0-9.,;"']/g, ' ')
                        .replace('"', "'"),
                      Title: (fullentry.Title || '')
                        .replace(/[^a-zA-Z0-9.,;"']/g, ' ')
                        .replace('"', "'"),
                      MediaOrganization:
                        (fullentry.MediaOrganization || {}).MediahouseName ||
                        fullentry.MediaOrganization ||
                        '',
                    };
                  }
                  return false;
                })
                .filter(Boolean);
              return newlist;
            });
          }
        }
        setTimeout(() => {
          setexporting(true);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSelection = (selection) => {
    if (Array.isArray(selection) && selection.length > 0) {
      const judgingresults = selection.map((item) => transformEntry(item));
      setexportlist(judgingresults);
      const ids = selection
        .map(({ Phone, TeammemberNames }) => {
          const otherPhones = (TeammemberNames || []).map((item) => item.Phone);
          return [Phone, ...otherPhones];
        })
        .flat();
      setphonenumbers(ids);
    }
  };

  const actionsComponent = (row) => (
    <IconButton onClick={() => setviewing(row)}>
      <Visibility color="primary" />
    </IconButton>
  );
  const renderHeader = ({ onRefresh }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{JudgingLevelName}</Text>
        <Block row flex={false}>
          <Button onClick={onOpenSmsDialog} disabled={!phonenumbers}>
            <SendIcon /> Send SMS to Selected
          </Button>
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
          <CSVLink
            data={exportlist}
            headers={headers}
            asyncOnClick
            ref={csvLinkElem}
          >
            <Button onClick={populateTable}>
              <ListAltIcon /> Export Selected to Excel
            </Button>
          </CSVLink>
        </Block>
      </Block>
      <Block />
    </Block>
  );
  const judgingResults = (row) => {
    let judgedtimes = 0;
    const judges = [...(row.JudgingResults || [])].length || 0;
    [...(row.JudgingResults || [])].forEach((result) => {
      if (result.Points && typeof result.Points === 'object') {
        const keys = Object.keys(result.Points);
        if (keys && keys.length > 0 && result.Final) {
          judgedtimes += 1;
        }
      }
    });
    const points = [...(row.JudgingResults || [])].map((result) => {
      if (result.Points && typeof result.Points === 'object') {
        const keys = Object.keys(result.Points);
        if (keys && keys.length > 0 && result.Final) {
          let awarded = 0;
          keys.forEach((k) => {
            awarded += result.Points[k];
          });
          return awarded;
        }
      }
      return 0;
    });
    // .filter(Boolean);
    const min = Math.min(...points);
    const max = Math.max(...points);
    // const variation = max - min;
    return {
      Judges: judges,
      Judged: judgedtimes,
      Variance: max - min,
      Disqualified: points.includes(0) && judgedtimes === judges ? 'Yes' : '',
    };
  };
  const cellComponent = ({ row, column }) => {
    if (column.name === 'Judged') {
      return (
        <TableCell>
          <Text bold color={row[column.name] > 0 ? colors.green : colors.red}>
            {row[column.name]}
          </Text>
        </TableCell>
      );
    }
    if (column.name === 'Variance') {
      return (
        <TableCell>
          <Text
            bold
            color={
              maxvariance && row[column.name] > maxvariance
                ? colors.red
                : colors.green
            }
          >
            {row[column.name] >= 0
              ? `${(row[column.name] || 0).toFixed(4)}`
              : ''}
          </Text>
        </TableCell>
      );
    }
    if (column.name === 'AverageScore') {
      return <TableCell>{`${(row[column.name] || 0).toFixed(2)}`}</TableCell>;
    }
    if (column.name === 'Category') {
      return <TableCell>{`${(row[column.name] || 0).toFixed(2)}`}</TableCell>;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };
  const cols = [
    { name: 'EntryName', title: 'Name' },
    { name: 'CategoryName', title: 'Category' },
    { name: 'AverageScore', title: 'Average Score' },
    { name: 'Judges' },
    { name: 'Judged', title: 'Judged (times)' },
    { name: 'Variance', title: 'Variation' },
    { name: 'Disqualified' },
  ];

  const columnWidths = [
    { columnName: 'Entryname', width: 200 },
    { columnName: 'Category', width: 200 },
    { columnName: 'Average Score', width: 200 },
    { columnName: 'Judges', width: 100 },
    { columnName: 'Judged', width: 100 },
    { columnName: 'Variance', width: 100 },
    { columnName: 'Disqualified', width: 100 },
  ];
  return (
    <Block>
      <RemoteDataGrid
        url="/judging/judgingresult/getall"
        axiosinstance={adminaxiosinstance}
        params={{ JudgingLevelId }}
        header={renderHeader}
        actionsComponent={({ row }) => actionsComponent(row)}
        cellComponent={cellComponent}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        onChangeSelection={onChangeSelection}
        pagingComponent={null}
        dataExtractor={(data) => {
          if (data && Array.isArray(data.items)) {
            return data.items.map((item) => ({
              ...(item || {}),
              CategoryName: `${
                ((item || {}).Category || {}).CategoryName || ''
              } - ${item.StoryFormat || ''}`,
              ...(judgingResults(item) || {}),
            }));
          }
          return [];
        }}
        pageSizes={[50, 100, 500, 1200, 2000]}
        // limit={1200}
      />
      <SendSmsForm
        numbers={phonenumbers}
        open={smsDialogOpen}
        onClose={() => openSmsDialog(false)}
      />
      {viewing && (
        <ResultsForm viewing={viewing} onClose={() => setviewing(null)} />
      )}
      <ColumnSelector
        headers={headers}
        setHeaders={setHeaders}
        open={exporting}
        onCancel={() => setexporting(false)}
        csvLinkElem={csvLinkElem}
      />
    </Block>
  );
};

export default Entry;
