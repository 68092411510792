import React from 'react';
import Tab from '@cloudhub-ux/core/Tab';
import Tabs from '@cloudhub-ux/core/Tabs';
import DialogTabs from '@cloudhub-ux/core/dialogs/DialogTabs';
import Block from '@cloudhub-ux/core/Block';
import Input from '@cloudhub-ux/core/Input';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Button from '@cloudhub-ux/core/Button';
import Alert from '@cloudhub-ux/core/Alert';
import DatePicker from '@cloudhub-ux/core/DatePicker';
import { Form, Field } from '@cloudhub-ux/core/form';
import toastr from '@cloudhub-ux/core/toastr';
import Dialog, { DialogActions, DialogHeader } from '@cloudhub-ux/core/dialogs';
import { sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import DialogLayer from 'localcomponents/DialogLayer';
import { StaticListSelector } from '@cloudhub-ux/core/baseweb';
import ImportEntries from './ImportEntries';
import ImportJudgingLevels from './ImportJudgingLevels';

const AwardyearForm = (props) => {
  const [tabvalue, setTabValue] = React.useState(0);
  const { editingRow } = props;

  const title = editingRow
    ? editingRow.AwardYearCode || 'Edit Award Year'
    : 'Add Award Year';

  const { postData, error, reset } = useAdminAxios({});

  const onSubmit = async (values, form) => {
    try {
      const data = await postData({
        url: '/system/awardyear/saveawardyear',
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);

        setTimeout(() => {
          form.reset();
          props.onCancelEdit();
          toastr.success('Record saved successfully');
        });
      }
    } catch (error) {}
  };

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      // eslint-disable-next-line no-mixed-operators
      minHeight={700}
    >
      <DialogHeader onClose={props.onCancelEdit}>{title}</DialogHeader>
      <Form
        onSubmit={onSubmit}
        initialValues={editingRow}
        render={({ handleSubmit }) => (
          <Block>
            <DialogTabs>
              <Tabs
                value={tabvalue}
                onChange={(e, tabvalue) => setTabValue(tabvalue)}
              >
                <Tab label="Basic Info" />
                <Tab label="Entries" />
                <Tab label="Judging Levels" />
              </Tabs>
            </DialogTabs>
            <DialogLayer>
              {tabvalue === 0 && (
                <Block>
                  <Field
                    label="Name"
                    type="text"
                    name="Name"
                    component={Input}
                    required
                  />

                  <FieldBlock row>
                    <Field
                      label="Opening Date"
                      name="OpeningDate"
                      component={DatePicker}
                      flex
                      required
                      showTime
                    />
                    <Field
                      label="Closing Date"
                      name="ClosingDate"
                      component={DatePicker}
                      flex
                      required
                      showTime
                    />
                  </FieldBlock>

                  <FieldBlock row>
                    <Field
                      label="Earliest Valid Story Published Date"
                      name="StoryEarliestDate"
                      component={DatePicker}
                      flex
                      required
                    />

                    <Field
                      label="Latest Valid Story Published Date"
                      name="StoryLatestDate"
                      component={DatePicker}
                      flex
                      required
                    />
                  </FieldBlock>
                  <FieldBlock row>
                    <Field
                      label="Status"
                      name="Status"
                      component={StaticListSelector}
                      flex
                      required
                      options={['OPEN', 'CLOSED']}
                    />
                  </FieldBlock>
                </Block>
              )}
              {tabvalue === 1 && (
                <ImportEntries AwardYearId={(editingRow || {}).id} />
              )}
              {tabvalue === 2 && (editingRow || {}).Name === 'Demo Year' && (
                <ImportJudgingLevels AwardYearId={(editingRow || {}).id} />
              )}
            </DialogLayer>
            <DialogActions>
              <Block>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </Block>
              <Button
                variant="contained"
                onClick={props.onCancelEdit}
                color="danger"
                style={{ marginRight: sizes.margin }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="success"
              >
                Save
              </Button>
            </DialogActions>
          </Block>
        )}
      />
    </Dialog>
  );
};

AwardyearForm.defaultProps = {
  onSave: () => {},
};

export default AwardyearForm;
