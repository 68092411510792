import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import IconButton from '@cloudhub-ux/core/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { colors, sizes } from 'theme';

const ParamertersTable = ({
  removeParameter,
  Parameters,
  seteditingparam = () => {},
}) => (
  <Block paper>
    <Table style={{ minWidth: '50%' }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row">
            <Text h4 bold>
              Parameter
            </Text>
          </TableCell>
          <TableCell align="left">
            <Text h4 bold>
              Questions
            </Text>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(Parameters) &&
          Parameters.map((p) => (
            <TableRow key={p.ParameterName}>
              <TableCell component="th" scope="row">
                {p.ParameterName}
              </TableCell>
              <TableCell align="left">
                {p.Questions ? p.Questions.length : 0}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => seteditingparam(p)}>
                  <Edit
                    style={{
                      color: colors.primary,
                      fontSize: sizes.h5,
                      fontWeight: 'bold',
                    }}
                  />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton onClick={() => removeParameter(p)}>
                  <Delete
                    style={{
                      color: colors.red,
                      fontSize: sizes.h5,
                      fontWeight: 'bold',
                    }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </Block>
);

export default ParamertersTable;
