import React from 'react';
import { navigate } from '@cloudhub-ux/core/reach';
import toastr from '@cloudhub-ux/core/toastr';
import Alert from '@cloudhub-ux/core/Alert';
import Block from '@cloudhub-ux/core/Block';
import { Form, Field } from '@cloudhub-ux/core/form';
import Button from '@cloudhub-ux/core/Button';
import useAdminAxios from 'admin/context/useAdminAxios';
import { RemoteSelector } from '@cloudhub-ux/core/baseweb';
import { Input } from '@mui/material';

const ImportEntriies = ({ AwardYearId }) => {
  const { postData, error, reset, adminaxiosinstance } = useAdminAxios({});

  const importEntries = async (values) => {
    try {
      if (AwardYearId) {
        const data = await postData({
          url: '/system/awardyear/importentriestodemo',
          params: { ...values, AwardYearId },
        });
        if (data) {
          toastr.success('Entries imported successfully');
          navigate('/admin/entries/entries');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form
      onSubmit={importEntries}
      initialValues={{
        EntriesPerCategory: 5,
      }}
      render={({ handleSubmit, pristine, submitting }) => (
        <Block>
          <Field
            name="AwardYear"
            label="Source Award Year"
            component={RemoteSelector}
            url="/system/awardyear/filtersearch"
            axiosinstance={adminaxiosinstance}
            labelExtractor={(item) => (item || {}).Name || ''}
            keyExtractor={(item) => (item || {}).id || ''}
            valueExtractor={(item) => {
              if (item) {
                return {
                  id: item.id,
                  Name: item.Name,
                  AwardYearCode: item.AwardYearCode,
                  _id: item._id,
                  _ref: item._id,
                };
              }
              return item;
            }}
          />
          <Field
            name="EntriesPerCategory"
            label="Entries per category"
            component={Input}
            type="number"
            number
          />
          {Boolean(error) && <Alert error message={error} onClose={reset} />}
          <Button disabled={pristine || submitting} onClick={handleSubmit}>
            Import Entries
          </Button>
        </Block>
      )}
    />
  );
};
export default ImportEntriies;
