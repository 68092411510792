import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import ActionsMenu from '@cloudhub-ux/core/ActionsMenu';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import ListMenuItem from '@cloudhub-ux/core/ListMenuItem';
import Text from '@cloudhub-ux/core/Text';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { navigate } from '@cloudhub-ux/core/reach';
import dayjs from 'dayjs';
import AdminContext from './context/AdminContext';

const AwardYearSelector = () => {
  const [menuopen, setMenuOpen] = React.useState(false);
  const {
    awardYear: { AwardYear, AwardYears, awardYearActions },
  } = React.useContext(AdminContext);

  const { changeAwardYear } = awardYearActions;

  const selectAwardYear = (year) => {
    changeAwardYear(year);
    setMenuOpen(false);
    navigate('/admin', { state: { reload: true } });
  };

  const renderYears = () =>
    AwardYears.map((year) => {
      const { Name, OpeningDate, ClosingDate, id } = year || {};

      return (
        <ListMenuItem
          key={id}
          primary={`${Name || ''}`}
          secondary={`${dayjs(OpeningDate).format('MMM DD, YYYY')} - ${dayjs(
            ClosingDate
          ).format('MMM DD, YYYY')}`}
          onClick={() => selectAwardYear(year)}
        />
      );
    });

  const ActiveAwardYear = (
    <Block flex={false} style={{ width: 300 }}>
      <ListMenuItem key={(AwardYear || {}).id}>
        <Block row>
          <ListItemText
            primary={
              <Block>
                <Text h6 bold>
                  {(AwardYear || {}).Name || ''}
                </Text>
                <Text caption>
                  {dayjs((AwardYear || {}).OpeningDate).format('MMM DD, YYYY')}{' '}
                  -{' '}
                  {dayjs((AwardYear || {}).ClosingDate).format('MMM DD, YYYY')}{' '}
                  - {(AwardYear || {}).Status || 'CLOSED'}
                </Text>
              </Block>
            }
          />
          <ListItemSecondaryAction>
            {!menuopen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </ListItemSecondaryAction>
        </Block>
      </ListMenuItem>
    </Block>
  );

  return (
    <Block flex={false} row middle>
      <ActionsMenu
        isOpen={menuopen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        anchorComponent={ActiveAwardYear}
        placement="bottom-start"
        style={{ zIndex: 99999 }}
      >
        <Block
          paper
          elevation={5}
          shadow
          flex={false}
          style={{ width: 300, maxHeight: 400, overflowY: 'auto' }}
        >
          <ListMenu>{renderYears()}</ListMenu>
        </Block>
      </ActionsMenu>
    </Block>
  );
};

export default AwardYearSelector;
