import moment from 'moment';
import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  SET_TOKEN,
  UPDATE_PROFILE,
  OPEN_SIGN_IN,
  CLOSE_SIGN_IN,
  OPEN_CHANGE_PASSWORD,
  CLOSE_CHANGE_PASSWORD,
  OPEN_UPDATE_PROFILE,
  CLOSE_UPDATE_PROFILE,
  SEND_CONFIRMATION_CODE,
  CONFIRM_ACCOUNT,
  OPEN_GET_STARTED,
  CLOSE_GET_STARTED,
} from './actions/authTypes';

const maxTime = process.env.NODE_ENV === 'production' ? 3 * 60000 : 30 * 60000;
let token;
try {
  token = localStorage.getItem('token');
} catch (error) {}

export const INITIAL_STATE = {
  token,
  isAuthenticated: false,
  autherror: '',
  currentUser: {
    Name: 'Guest',
    Email: '',
    Phone: '',
    UserType: ['Customer'],
  },
  signinopened: false,
  changepasswordopen: false,
  updateprofileopen: false,

  getstarted: false,
  allowsocial: false,
  allowsignup: false,
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER: {
      try {
        localStorage.setItem('token', action.payload.token);
      } catch (error) {}

      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        currentUser: action.payload.user,
        sessioncounter: maxTime,
        error: '',
      };
    }
    case UNAUTH_USER: {
      try {
        localStorage.removeItem('token');
      } catch (error) {}

      return { ...INITIAL_STATE, token: '' };
    }

    case AUTH_ERROR: {
      try {
        // localStorage.removeItem('token');
      } catch (error) {}

      return { ...state, autherror: action.payload };
    }
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case OPEN_SIGN_IN:
      return { ...state, signinopened: true };
    case CLOSE_SIGN_IN:
      return { ...state, signinopened: false };
    case OPEN_CHANGE_PASSWORD:
      return { ...state, changepasswordopen: true };
    case CLOSE_CHANGE_PASSWORD:
      return { ...state, changepasswordopen: false };
    case OPEN_UPDATE_PROFILE:
      return { ...state, updateprofileopen: true };
    case CLOSE_UPDATE_PROFILE:
      return { ...state, updateprofileopen: false };
    case SEND_CONFIRMATION_CODE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ConfirmationCodeDate: moment().format(),
        },
      };

    case OPEN_GET_STARTED:
      return { ...state, getstarted: true };
    case CLOSE_GET_STARTED:
      return { ...state, getstarted: false };
    case UPDATE_PROFILE: {
      return { ...state, currentUser: { ...action.payload } };
    }

    case CONFIRM_ACCOUNT:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          confirmed: true,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
