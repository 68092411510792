import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import ListMenuItem from '@cloudhub-ux/core/ListMenuItem';
import IconButton from '@cloudhub-ux/core/IconButton';
import FieldButton from '@cloudhub-ux/core/FieldButton';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import { Form, Field } from '@cloudhub-ux/core/form';
import {
  RemoteSelector,
  StaticListSelector,
} from '@cloudhub-ux/core/selectors';
import AdminContext from 'admin/context/AdminContext';
import { colors } from 'theme';
import Delete from '@mui/icons-material/Delete';

const JudgeStrengths = ({ input: { value, onChange } }) => {
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const addStrength = (strength) => {
    const ind = (value || []).findIndex(
      ({ Category, StoryFormat }) =>
        Category &&
        Category.CategoryName ===
          ((strength || {}).Category || {}).CategoryName &&
        StoryFormat === (strength || {}).StoryFormat
    );
    if (ind === -1) {
      onChange([...(value || []), strength]);
    }
  };
  const removeStrength = (index) => {
    onChange((value || []).map((item, ind) => (ind === index ? null : item)));
  };
  return (
    <Form
      onSubmit={addStrength}
      render={({ handleSubmit, pristine }) => (
        <Block row>
          <ListMenu>
            {(value || []).map((item, index) => (
              <ListMenuItem
                key={JSON.stringify(item)}
                primary={(item.Category || {}).CategoryName}
                secondary={item.StoryFormat}
                action={
                  <IconButton onClick={() => removeStrength(index)}>
                    <Delete style={{ color: colors.error }} />
                  </IconButton>
                }
              />
            ))}
          </ListMenu>
          <FieldBlock>
            <Field
              name="Category"
              component={RemoteSelector}
              label="Category"
              url="/website/entrycategory/filtersearch"
              axiosinstance={adminaxiosinstance}
              valueExtractor={({ CategoryCode, CategoryName }) => ({
                CategoryCode,
                CategoryName,
              })}
              labelExtractor={(row) => row.CategoryName || row}
              keyExtractor={({ id }) => id}
            />
            <Field
              name="StoryFormat"
              component={StaticListSelector}
              options={['Radio', 'TV', 'Print', 'Online']}
              label="Format"
            />
            <FieldButton
              color={colors.primary}
              onClick={handleSubmit}
              disabled={pristine}
            >
              Add
            </FieldButton>
          </FieldBlock>
        </Block>
      )}
    />
  );
};
JudgeStrengths.defaultProps = {
  input: {
    value: [],
    onChange: () => {},
  },
};
export default JudgeStrengths;
