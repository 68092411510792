import React from 'react';

import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import Button from '@cloudhub-ux/core/Button';
import CheckBox from '@cloudhub-ux/core/CheckBox';
import Text from '@cloudhub-ux/core/Text';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@cloudhub-ux/core/dialogs';
import Form from '@cloudhub-ux/core/form/Form';
import Field from '@cloudhub-ux/core/form/Field';
import { colors, sizes } from 'theme';

const headerArray = [
  { label: 'Story Title', key: 'Title' },
  { label: 'Published', key: 'DatePublished' },
  { label: 'Reference No.', key: 'RefNo' },
  { label: 'Entry Type', key: 'EntryType' },
  { label: 'Name', key: 'Name' },
  { label: 'Category', key: 'CategoryName' },
  { label: 'Format', key: 'StoryFormat' },
  { label: 'Media Organization', key: 'MediaOrganization' },
  { label: 'County', key: 'County' },
  { label: 'Gender', key: 'Gender' },
  { label: 'Date', key: 'Date' },
  { label: 'Nationality', key: 'Nationality' },
  { label: 'MediaCard Code', key: 'MediaCard.Code' },
  { label: 'MediaCard Expiry', key: 'MediaCard.Expiry' },
  { label: 'First Name', key: 'FirstName' },
  { label: 'Surname', key: 'Surname' },
  { label: 'Nationality', key: 'Nationality' },
  { label: 'Phone', key: 'Phone' },
  { label: 'Email', key: 'Email' },
  { label: 'Team Members', key: 'TeamMemberNames' },
  { label: 'Freelancer', key: 'Freelancer' },
  { label: 'Synopsis', key: 'Synopsis' },
];
const ColumnSelector = ({
  setHeaders = () => {},
  open,
  onCancel = () => {},
  csvLinkElem,
}) => {
  const exportList = (columns) => {
    try {
      const cols = [];
      Object.keys(columns).forEach((col) => {
        if (columns[col] === true) {
          cols.push(col);
        }
        if (columns[col] && typeof columns[col] === 'object') {
          Object.keys(columns[col]).forEach((subcol) => {
            if (columns[col][subcol] === true) {
              cols.push(`${col}.${subcol}`);
            }
          });
        }
      });
      const tableHeaders = headerArray
        .map((item) => {
          if (item && item.key && cols.includes(item.key)) {
            return item;
          }
          return null;
        })
        .filter(Boolean);

      setHeaders(tableHeaders);
      setTimeout(() => {
        if (csvLinkElem && csvLinkElem.current) {
          csvLinkElem.current.link.click();
          onCancel();
        }
      }, 200);
    } catch (error) {}
  };
  return (
    <Dialog
      maxWidth="sm"
      minHeight="80vh"
      style={{ margin: 'auto', height: 200 }}
      onClose={onCancel}
      open={open}
    >
      <Form
        onSubmit={exportList}
        initialValues={{
          FirstName: true,
          Surname: true,
          Title: true,
          Nationality: true,
          StoryFormat: true,
          MediaOrganization: true,
          Phone: true,
          Email: true,
          Gender: true,
          County: true,
          DatePublished: true,
        }}
        render={({ handleSubmit, submitting }) => (
          <React.Fragment>
            <DialogHeader onClose={onCancel}>Export Entries</DialogHeader>
            <DialogContent>
              <Text h4 bold style={{ alignSelf: 'center' }}>
                Select Columns to Export
              </Text>
              {headerArray.map((col) => (
                <Field
                  key={col.key}
                  name={col.key}
                  component={CheckBox}
                  height={sizes.icons.small}
                  tag={col.label}
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button contained color={colors.secondary} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                contained
                color={colors.primary}
                onClick={handleSubmit}
                disabled={submitting}
              >
                Export
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};
export default ColumnSelector;
