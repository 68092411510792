import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Input from '@cloudhub-ux/core/Input';
import TextArea from '@cloudhub-ux/core/TextArea';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import { Form, Field, formCalculate } from '@cloudhub-ux/core/form';
import Alert from '@cloudhub-ux/core/Alert';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import DialogHeader from '@cloudhub-ux/core/dialogs/DialogHeader';
import DialogContent from '@cloudhub-ux/core/dialogs/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import { colors } from 'theme';

import useAdminAxios from 'admin/context/useAdminAxios';
import ImagesUpload from '@cloudhub-ux/core/uploaders/uploader/ImagesUpload';
import { StaticListSelector } from '@cloudhub-ux/core/selectors';

const calculator = formCalculate({
  field: ['MediastationName'],
  updates: {
    MediastationCode: (value, values) =>
      `${values.MediastationName}`.toLocaleLowerCase().replace(/ /g, '_'),
  },
});

const MediastationForm = (props) => {
  const { postData, error, reset } = useAdminAxios();

  const onSaveMediastation = async (values) => {
    const data = await postData({
      url: '/website/mediastation/savemediastation',
      params: {
        ...values,
        AlternateCode: values.AlternateCode || null,
      },
    });

    if (data) {
      props.onSave(data);
      props.onCancelEdit();
    }
  };

  const { editingRow } = props;
  const values = editingRow || {};

  const title = values.id
    ? values.MediastationName || 'Edit Mediastation'
    : 'Add Mediastation';

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      minHeight={800}
    >
      <DialogHeader onClose={props.onCancelEdit}>{`${title}`}</DialogHeader>

      <Form
        onSubmit={onSaveMediastation}
        decorators={[calculator]}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent>
              <Block>
                <Block>
                  <FieldBlock row>
                    <Field
                      label="Media House Name"
                      name="MediastationName"
                      component={Input}
                      required
                      flex
                    />
                    <Field
                      label="Media House Type"
                      name="MediastationType"
                      component={StaticListSelector}
                      required
                      flex
                      options={['TV', 'Radio']}
                    />
                    <Field
                      label="Media House Status"
                      name="Status"
                      component={StaticListSelector}
                      required
                      flex
                      options={['ACTIVE', 'INACTIVE']}
                    />
                    <Field
                      label="Website"
                      name="Website"
                      component={Input}
                      flex
                    />
                  </FieldBlock>
                  <FieldBlock row>
                    <Field
                      label="Contact Person"
                      name="ContactPerson"
                      component={Input}
                      flex
                    />

                    <Field label="Phone" name="Phone" component={Input} flex />
                    <Field label="Email" name="Email" component={Input} flex />
                  </FieldBlock>

                  <Block row top>
                    <Block flex={false}>
                      <Field
                        label="Media House Code"
                        name="MediastationCode"
                        component={Input}
                        required
                      />
                      <Field
                        label="Voting Code"
                        name="VotingCode"
                        component={Input}
                        readOnly
                      />
                      <Field
                        label="Alternate Voting Code"
                        name="AlternateCode"
                        component={Input}
                        validate={(val) => {
                          if (val && !val.match(/\D/g)) {
                            return 'Code must contain a letter or special character';
                          }
                          return undefined;
                        }}
                        format={(val) => (val || '').replace(' ', '')}
                      />
                      <Block flex={false} style={{ width: 300 }}>
                        <Field
                          label="Mediastation Logo"
                          name="MediastationImage"
                          component={ImagesUpload}
                          maxSize={300}
                        />
                      </Block>
                    </Block>
                    <Block style={{ marginLeft: 5 }}>
                      <Field
                        label="Description"
                        name="Description"
                        component={TextArea}
                        maxLength={1000}
                        rows={15}
                      />
                    </Block>
                  </Block>
                </Block>
              </Block>
            </DialogContent>

            <DialogActions>
              <Block>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset()} />
                )}
              </Block>
              <Button
                contained
                color={colors.danger}
                onClick={props.onCancelEdit}
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                contained
                color={colors.success}
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                {submitting ? 'Please wait...' : 'Save'}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

MediastationForm.defaultProps = {
  onSave: () => {},
};

export default MediastationForm;
