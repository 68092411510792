import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Input from '@cloudhub-ux/core/Input';
import { Form, Field, formCalculate } from '@cloudhub-ux/core/form';
import Alert from '@cloudhub-ux/core/Alert';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import DialogHeader from '@cloudhub-ux/core/dialogs/DialogHeader';
import DialogContent from '@cloudhub-ux/core/dialogs/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import { colors } from 'theme';

import useAdminAxios from 'admin/context/useAdminAxios';
import { DraftEditor } from '@cloudhub-ux/core/editors';
import ImagesUpload from '@cloudhub-ux/core/uploaders/ImagesUpload';
import useMetrics from '@cloudhub-ux/core/customhooks/useMetrics';

const calculator = formCalculate({
  field: ['PageCode'],
  updates: {
    PageCode: (value, values) =>
      `${values.PageCode}`.toLocaleLowerCase().replace(/ /g, '_'),
  },
});

const InfopageForm = (props) => {
  const { height } = useMetrics();
  const { postData, error, reset } = useAdminAxios();

  const onSaveInfopage = async (values) => {
    const data = await postData({
      url: '/website/infopage/saveinfopage',
      params: {
        ...values,
      },
    });

    if (data) {
      props.onSave(data);
      props.onCancelEdit();
    }
  };

  const { editingRow } = props;
  const values = editingRow || {};

  const title = values.id ? values.Title || 'Edit Page' : 'Add Page';

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      minHeight={height}
    >
      <DialogHeader onClose={props.onCancelEdit}>{`${title}`}</DialogHeader>

      <Form
        onSubmit={onSaveInfopage}
        initialValues={editingRow || {}}
        decorators={[calculator]}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent>
              <Block>
                <Field label="Title" name="Title" component={Input} required />
                <Field
                  label="Page Resource Link"
                  name="Link"
                  component={Input}
                />
                <Block row>
                  <Block flex={false} style={{ padding: 5 }}>
                    <Block flex={false} style={{ width: 300, height: 200 }}>
                      <Block flex={false}>
                        <Field
                          label="Page Code"
                          name="PageCode"
                          component={Input}
                          required
                        />
                      </Block>
                      <Field
                        label="Page Image"
                        name="PageImage"
                        component={ImagesUpload}
                        maxSize={300}
                      />
                    </Block>
                  </Block>

                  <Block>
                    <Field
                      label="Brief"
                      name="Brief"
                      height={250}
                      component={DraftEditor}
                      required
                      maxLength={1500}
                    />

                    <Block style={{ height: 400 }}>
                      <Field
                        label="Description"
                        name="Description"
                        component={DraftEditor}
                        wrap={false}
                      />
                    </Block>
                  </Block>
                </Block>
                <Field
                  label="Other Images"
                  name="OtherImages"
                  component={ImagesUpload}
                  limit={20}
                />
              </Block>
            </DialogContent>

            <DialogActions>
              <Block>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset()} />
                )}
              </Block>
              <Button
                contained
                color={colors.danger}
                onClick={props.onCancelEdit}
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                contained
                color={colors.success}
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                {submitting ? 'Please wait...' : 'Save'}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

InfopageForm.defaultProps = {
  onSave: () => {},
};

export default InfopageForm;
