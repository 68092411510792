/* eslint-disable capitalized-comments */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';

import Block from '@cloudhub-ux/core/Block';
import Alert from '@cloudhub-ux/core/Alert';
import useAdminAxios from 'admin/context/useAdminAxios';

import InfopageForm from './forms/InfopageForm';

// const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Infopage = () => {
  const { getData, adminaxiosinstance } = useAdminAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const deleteRows = async (deletingRows) => {
    const ids = deletingRows.map((item) => item.id);
    getData({ url: '/website/infopage/delete', params: { infopages: ids } });
    gridRef.current.onDeleteSuccess(deletingRows);
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [
    { name: 'createdAt', title: 'Date' },
    { name: 'Title', title: 'Title' },
    { name: 'PageCode', title: 'url' },
  ];

  const columnWidths = [
    { columnName: 'createdAt', width: 120 },
    { columnName: 'Title', width: 150 },
    { columnName: 'PageCode', width: 150 },
  ];

  return (
    <React.Fragment>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="Infopages"
        url="/website/infopage/getall"
        axiosinstance={adminaxiosinstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
        pagingComponent={null}
      />
      {editingRow && (
        <InfopageForm
          editingRow={editingRow}
          onCancelEdit={() => setEditingRow(null)}
          onSave={(row) => gridRef.current.onSave(row)}
        />
      )}
    </React.Fragment>
  );
};

export default Infopage;
