import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Input from '@cloudhub-ux/core/Input';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import TextArea from '@cloudhub-ux/core/TextArea';
import CheckBox from '@cloudhub-ux/core/CheckBox';
import { formCalculate, Form, Field } from '@cloudhub-ux/core/form';
import Alert from '@cloudhub-ux/core/Alert';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import DialogHeader from '@cloudhub-ux/core/dialogs/DialogHeader';
import DialogContent from '@cloudhub-ux/core/dialogs/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import ImagesUpload from '@cloudhub-ux/core/uploaders/uploader/ImagesUpload';

const calculator = formCalculate({
  field: ['CategoryName'],
  updates: {
    CategoryCode: (value, values) =>
      `${values.CategoryName}`.toLocaleLowerCase().replace(/ /g, '_'),
  },
});

const EntrycategoryForm = (props) => {
  const { postData, error, reset } = useAdminAxios();

  const onSaveEntrycategory = async (values) => {
    const data = await postData({
      url: '/website/entrycategory/saveentrycategory',
      params: {
        ...values,
      },
    });

    if (data) {
      props.onSave(data);
      props.onCancelEdit();
    }
  };

  const { editingRow } = props;
  const values = editingRow || {};

  const title = values.id
    ? values.CategoryName || 'Edit Category'
    : 'Add Category';

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      minHeight={700}
    >
      <DialogHeader onClose={props.onCancelEdit}>{`${title}`}</DialogHeader>
      <Form
        onSubmit={onSaveEntrycategory}
        initialValues={editingRow || {}}
        decorators={[calculator]}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent>
              <Block>
                <FieldBlock row>
                  <Field
                    label="Category Name"
                    name="CategoryName"
                    component={Input}
                    required
                    flex
                  />
                </FieldBlock>
                <Field
                  name="AllowEntry"
                  tag="Allow Entry Submission for this Category?"
                  height={sizes.icons.medium}
                  component={CheckBox}
                />
                <Block row top>
                  <Block flex={false}>
                    <Field
                      label="Category Code"
                      name="CategoryCode"
                      component={Input}
                      required
                      readOnly
                    />
                    <Block flex={false} style={{ width: 300 }}>
                      <Field
                        label="Category Image"
                        name="CategoryImage"
                        component={ImagesUpload}
                        maxSize={300}
                      />
                      <Field
                        name="ImageSource.Entity"
                        component={Input}
                        label="Category Image Credit to"
                      />
                      <Field
                        name="ImageSource.Link"
                        component={Input}
                        label="Image Owners Website"
                      />
                    </Block>
                  </Block>
                  <Block style={{ marginLeft: 5 }}>
                    <Field
                      label="Description"
                      name="Description"
                      component={TextArea}
                      rows={15}
                      maxLength={1000}
                    />
                  </Block>
                </Block>
              </Block>
            </DialogContent>

            <DialogActions>
              <Block>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset()} />
                )}
              </Block>
              <Button
                contained
                color={colors.danger}
                onClick={props.onCancelEdit}
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                contained
                color={colors.success}
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                {submitting ? 'Please wait...' : 'Save'}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

EntrycategoryForm.defaultProps = {
  onSave: () => {},
};

export default EntrycategoryForm;
