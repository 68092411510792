import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Input from '@cloudhub-ux/core/Input';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import TextArea from '@cloudhub-ux/core/TextArea';
import { Form, Field } from '@cloudhub-ux/core/form';
import Alert from '@cloudhub-ux/core/Alert';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import DialogHeader from '@cloudhub-ux/core/dialogs/DialogHeader';
import DialogContent from '@cloudhub-ux/core/dialogs/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import { colors } from 'theme';

import useAdminAxios from 'admin/context/useAdminAxios';
import ImagesUpload from '@cloudhub-ux/core/uploaders/uploader/ImagesUpload';

const PartnerForm = (props) => {
  const { postData, error, reset } = useAdminAxios({});

  const onSavePartner = async (values) => {
    const data = await postData({
      url: '/website/partner/savepartner',
      params: { ...values },
    });

    if (data && typeof props.onSave === 'function') {
      props.onSave(data);
      props.onCancelEdit();
    }
  };

  const { editingRow } = props;
  const values = editingRow || {};

  const title = values.id
    ? values.PartnerName || 'Edit Partner'
    : 'Add Partner';

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      minHeight={700}
    >
      <DialogHeader onClose={props.onCancelEdit}>{`${title}`}</DialogHeader>

      <Form
        onSubmit={onSavePartner}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent>
              <Block>
                <Field
                  label="Partner Name"
                  name="PartnerName"
                  component={Input}
                  required
                />
                <FieldBlock row>
                  <Field
                    label="Contact Person"
                    name="ContactPerson"
                    component={Input}
                    flex
                  />
                  <Field label="Phone" name="Phone" component={Input} flex />
                </FieldBlock>
                <FieldBlock row>
                  <Field
                    label="Website"
                    name="Website"
                    component={Input}
                    required
                    flex
                  />
                  <Field label="Email" name="Email" component={Input} flex />
                </FieldBlock>
                <Block flex={false} row top>
                  <Field
                    label="Logo"
                    name="Logo"
                    component={ImagesUpload}
                    containerStyle={{ width: 300 }}
                    maxSize={300}
                  />

                  <Block>
                    <Field
                      label="Description"
                      name="Description"
                      component={TextArea}
                      rows={10}
                    />
                  </Block>
                </Block>
              </Block>
            </DialogContent>

            <DialogActions>
              <Block>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset()} />
                )}
              </Block>
              <Button
                contained
                color={colors.danger}
                onClick={props.onCancelEdit}
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                contained
                color={colors.success}
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                {submitting ? 'Please wait...' : 'Save'}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

PartnerForm.defaultProps = {
  onSave: () => {},
};

export default PartnerForm;
