import React from 'react';
import AuthContext from 'auth/context/AuthContext';
import axios from 'axios';
import SessionExpired from 'auth/SessionExpired';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import CircularProgress from '@mui/material/CircularProgress';
import AdminContext from './AdminContext';
import useAwardYear from './useAwardYear';

const AdminProvider = (props) => {
  const {
    token,
    currentUser,
    isAuthenticated,
    sessioncounter,
    axiosinstance,
    CONFIG,
    authActions: { unauthUser },
  } = React.useContext(AuthContext);
  const awardYear = useAwardYear();
  const { AwardYear } = awardYear || {};

  const adminaxiosinstance = React.useMemo(
    () => () =>
      axios.create({
        baseURL: `${CONFIG.API_ENDPOINT}/admin`,
        headers: {
          prxy: 'no-proxy',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
          userid: currentUser.id || '',
          username: currentUser.Name || '',
          awardyearid: (AwardYear || {}).id || '',
        },
      }),
    // eslint-disable-next-line
    [
      token,
      isAuthenticated,
      // eslint-disable-next-line
      JSON.stringify(currentUser),
      // eslint-disable-next-line
      JSON.stringify(AwardYear),
    ]
  );

  if (sessioncounter <= 0 && isAuthenticated) {
    try {
      localStorage.removeItem('token');
    } catch (error) {}
    unauthUser();
    return <SessionExpired />;
  }

  if (!currentUser.id) {
    return (
      <Block middle center>
        <CircularProgress />
        <Text h5>Fetching account details....</Text>
      </Block>
    );
  }
  return (
    <AdminContext.Provider
      value={{
        auth: {
          token,
          currentUser,
          isAuthenticated,
          axiosinstance,
          UserRoles: currentUser.UserRoles || [],
          UserType: currentUser.UserType,
        },
        adminaxiosinstance,
        awardYear,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
