import React, { useState } from 'react';

import Tab from '@cloudhub-ux/core/Tab';
import Tabs from '@cloudhub-ux/core/Tabs';
import Block from '@cloudhub-ux/core/Block';
import PhoneInput from '@cloudhub-ux/core/PhoneInput';
import Input from '@cloudhub-ux/core/Input';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Button from '@cloudhub-ux/core/Button';
import { Form, Field } from '@cloudhub-ux/core/form';
import toastr from '@cloudhub-ux/core/toastr';
import Alert from '@cloudhub-ux/core/Alert';
import Dialog, {
  DialogContent,
  DialogActions,
  DialogHeader,
} from '@cloudhub-ux/core/dialogs';
import { sizes } from 'theme';
import DialogTabs from '@cloudhub-ux/core/dialogs/DialogTabs';
import useMetrics from '@cloudhub-ux/core/customhooks/useMetrics';
import useAdminAxios from 'admin/context/useAdminAxios';
import JudgeStrengths from './JudgeStrengths';

const validate = (values) => {
  const errors = {};
  if (!values.Name) {
    errors.Name = 'Required';
  }

  if (!values.Email) {
    errors.Email = 'Required';
  }

  if (!values.Phone) {
    errors.Phone = 'Required';
  }
  return errors;
};

function TabContainer(props) {
  return <div style={{ padding: 20 }}>{props.children}</div>;
}

const JudgeForm = (props) => {
  const [tabvalue, setTabValue] = useState(0);
  const { height } = useMetrics();

  const { editingRow } = props;

  const title = editingRow ? editingRow.name || 'Edit User' : 'Add User';

  const { postData, error, reset } = useAdminAxios({});

  const onSubmit = async (values, form) => {
    try {
      const data = await postData({
        url: `/system/user/${values.id ? 'updateuser' : 'saveuser'}`,
        params: values,
      });

      props.onSave(data);

      setTimeout(() => {
        form.reset();
        props.onCancelEdit();
        toastr.success('Record saved successfully');
      });
    } catch (error) {}
  };

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      // eslint-disable-next-line no-mixed-operators
      minHeight={height - sizes.margin * 2}
    >
      <DialogHeader onClose={props.onCancelEdit}>{title}</DialogHeader>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={editingRow}
        render={({ handleSubmit }) => (
          <Block>
            <DialogTabs>
              <Tabs
                value={tabvalue}
                onChange={(e, tabvalue) => setTabValue(tabvalue)}
              >
                <Tab label="User Details" />
                <Tab label="Strengths" />
              </Tabs>
            </DialogTabs>
            <DialogContent>
              {tabvalue === 0 && (
                <TabContainer>
                  <Field
                    label="Full Name"
                    type="text"
                    name="Name"
                    component={Input}
                    readOnly={(editingRow || {}).id}
                    flex
                  />

                  <FieldBlock row>
                    <Field
                      label="Email"
                      email
                      name="Email"
                      component={Input}
                      flex
                    />

                    <Field
                      label="Phone"
                      name="Phone"
                      component={PhoneInput}
                      readOnly={(editingRow || {}).id}
                      flex
                    />
                  </FieldBlock>
                </TabContainer>
              )}
              {tabvalue === 1 && (
                <TabContainer>
                  <div>
                    <Field
                      type="text"
                      name="Strengths"
                      component={JudgeStrengths}
                    />
                  </div>
                </TabContainer>
              )}
              {Boolean(error) && (
                <Alert error message={error} onClose={reset} />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={props.onCancelEdit}
                color="danger"
                style={{ marginRight: sizes.margin }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="success"
              >
                Save
              </Button>
            </DialogActions>
          </Block>
        )}
      />
    </Dialog>
  );
};

JudgeForm.defaultProps = {
  onSave: () => {},
};

export default JudgeForm;
