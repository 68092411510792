import React from 'react';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Button from '@cloudhub-ux/core/Button';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import { Form, Field } from '@cloudhub-ux/core/form';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@cloudhub-ux/core/dialogs';
import { colors, sizes } from 'theme';
import AdminContext from 'admin/context/AdminContext';
import useAdminAxios from 'admin/context/useAdminAxios';
import { RemoteSelector, StaticListSelector } from '@cloudhub-ux/core/mui';
import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';

const Entry = ({ open, onClose, pushEntries }) => {
  const [entries, setentries] = React.useState([]);
  const [categories, setcategories] = React.useState([]);
  const [currentlist, setcurrentlist] = React.useState(undefined);
  const [currentformat, setcurrentformat] = React.useState(undefined);
  const [updated, setupdated] = React.useState(true);
  const [maxvariance, setmaxvariance] = React.useState(0);
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const { data, getData } = useAdminAxios({
    url: currentlist ? '/judging/judgingcriteria/getcriteria' : '',
    params: { JudgingLevelId: currentlist },
  });

  const getCategories = async () => {
    try {
      const data = await getData({
        url: '/website/entrycategory/getall',
      });
      if (data && data.items) {
        setcategories(data.items);
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (data && data.item && data.item.MaximumVariance) {
      setmaxvariance(Number(data.item.MaximumVariance) || 0);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(data)]);

  const requesturl =
    !currentlist ||
    categories.findIndex(({ CategoryCode }) => currentlist === CategoryCode) !==
      -1
      ? '/entry/entry/getall'
      : '/judging/judgingresult/getall';

  const requestparams = !currentlist
    ? { Status: 'SUBMITTED' }
    : categories.findIndex(
        ({ CategoryCode }) => currentlist === CategoryCode
      ) !== -1
    ? { Status: 'SUBMITTED', catcode: currentlist }
    : { JudgingLevelId: currentlist };

  const onChangeSelection = (selection) => {
    if (
      Array.isArray(selection) &&
      selection.length > 0 &&
      selection[0].JudgingLevelId
    ) {
      setentries(
        selection.map((s) => {
          const allscores = (s.JudgingResults || [])
            .map((result) => ({
              JudgeId: (result || {}).JudgeId,
              JudgeName: (result || {}).JudgeName,
              Points: Object.keys((result || {}).Points || {})
                .map((point) => Number(((result || {}).Points || {})[point]))
                .reduce((a, b) => a + b, 0),
            }))
            .sort((a, b) => b.Points - a.Points)
            .filter(Boolean);
          const newresult = {
            EntryId: s.EntryId,
            EntryName: s.EntryName,
            RefNo: s.RefNo,
            Phone: s.Phone,
            PreviousScores: {
              JudgingLevelId: s.JudgingLevelId,
              JudgingLevelName: s.JudgingLevelName,
              AverageScore: s.AverageScore,
              Scores: allscores,
              HighestScore: (allscores[0] || {}).Points || 0,
              LowestScore:
                (allscores[(allscores.length || 1) - 1] || {}).Points || 0,
              Variation:
                ((allscores[0] || {}).Points || 0) -
                ((allscores[(allscores.length || 1) - 1] || {}).Points || 0),
            },
            Category: {
              ...s.Category,
              _ref: s.Category._ref || s.Category._id,
            },
            StoryFormat: s.StoryFormat,
            TeamMemberNames: s.TeTeamMemberNames || [],
          };
          return newresult;
        })
      );
    } else {
      setentries(
        selection.map((s) => ({
          EntryId: s.id,
          EntryName: s.Title,
          RefNo: s.RefNo,
          Phone: s.Phone,
          Category: { ...s.Category, _ref: s.Category._ref || s.Category._id },
          StoryFormat: s.StoryFormat,
          TeamMemberNames: s.TeamMemberNames,
        }))
      );
    }
  };

  const renderHeader = ({ onSearch }) => (
    <Form
      onSubmit={() => {}}
      initialValues={{
        StoryFormat: currentformat,
      }}
      render={({ form }) => (
        <React.Fragment>
          <Block row wrap right>
            <Field
              name="Submissions"
              label="Select from Submissions"
              component={StaticListSelector}
              options={categories}
              valueExtractor={({ CategoryCode, CategoryName }) => ({
                CategoryCode,
                CategoryName,
              })}
              labelExtractor={(row) =>
                row && row.CategoryName ? row.CategoryName : `${row}`
              }
              key={(row) => (row || {}).key || row}
              onChange={(val) => {
                setupdated(false);
                setcurrentlist(val.CategoryCode);
                setTimeout(() => {
                  setupdated(true);
                }, 50);
              }}
              style={{ minWidth: 250, marginRight: sizes.baseMargin }}
            />
            <Field
              name="levels"
              label="Select from Previous Judging Level"
              component={RemoteSelector}
              url="/judging/judginglevel/getall"
              axiosinstance={adminaxiosinstance}
              labelExtractor={(option) =>
                option && option.Name ? option.Name : `${option}`
              }
              keyExtractor={(option) =>
                option && option.id ? option.id : `${option}`
              }
              valueExtractor={(row) => (row || {}).Name}
              onSelectChange={(val) => {
                setupdated(false);
                setcurrentlist(val.id);
                form.change('levels', val);
                setTimeout(() => {
                  setupdated(true);
                }, 50);
              }}
              style={{ minWidth: 250, marginRight: sizes.baseMargin }}
            />
            <Field
              name="StoryFormat"
              label="Story Format"
              options={['TV', 'Radio', 'Print', 'Digital']}
              component={StaticListSelector}
              onChange={(val) => {
                setupdated(false);
                setcurrentformat(val);
                setTimeout(() => {
                  setupdated(true);
                }, 50);
              }}
              style={{
                minWidth: 150,
                marginRight: sizes.baseMargin,
                marginLeft: sizes.baseMargin,
              }}
            />
            <Block flex={false}>
              <Input
                style={{
                  flex: 1,
                  minWidth: 200,
                  marginTop: sizes.baseMargin * 2.5,
                }}
                icon="search"
                placeholder="Search..."
                onChange={(e) => onSearch(e.target.value)}
              />
            </Block>
            <IconButton
              onClick={() => {
                setupdated(false);
                setcurrentlist(undefined);
                setTimeout(() => {
                  setupdated(true);
                }, 50);
              }}
            >
              <SettingsBackupRestore color="primary" />
            </IconButton>
          </Block>
        </React.Fragment>
      )}
    />
  );

  const judgingResults = (row) => {
    let judgedtimes = 0;
    const judges = [...(row.JudgingResults || [])].length || 0;
    [...(row.JudgingResults || [])].forEach((result) => {
      if (result.Points && typeof result.Points === 'object') {
        const keys = Object.keys(result.Points);
        if (keys && keys.length > 0 && result.Final) {
          judgedtimes += 1;
        }
      }
    });
    const points = [...(row.JudgingResults || [])].map((result) => {
      if (result.Points && typeof result.Points === 'object') {
        const keys = Object.keys(result.Points);
        if (keys && keys.length > 0 && result.Final) {
          let awarded = 0;
          keys.forEach((k) => {
            awarded += result.Points[k];
          });
          return awarded;
        }
      }
      return 0;
    });
    // .filter(Boolean);
    const min = Math.min(...points);
    const max = Math.max(...points);
    // const variation = max - min;
    return {
      Judges: judges,
      Judged: judgedtimes,
      Variance: max - min,
      Disqualified: points.includes(0) && judgedtimes === judges ? 'Yes' : '',
    };
  };

  const cellComponent = ({ row, column }) => {
    if (column.name === 'Judged') {
      return (
        <TableCell>
          <Text bold color={row[column.name] > 0 ? colors.green : colors.red}>
            {row[column.name]}
          </Text>
        </TableCell>
      );
    }
    if (column.name === 'Variance') {
      return (
        <TableCell>
          <Text
            bold
            color={
              maxvariance && row[column.name] > maxvariance
                ? colors.red
                : colors.green
            }
          >
            {row[column.name] >= 0 ? `${row[column.name].toFixed(4)}` : ''}
          </Text>
        </TableCell>
      );
    }
    if (column.name === 'AverageScore') {
      return <TableCell>{Number(row[column.name]).toFixed(2)}</TableCell>;
    }
    if (column.name === 'Name') {
      return (
        <TableCell>
          {row.EntryName ? row.EntryName : `${row.FirstName} ${row.Surname}`}
        </TableCell>
      );
    }
    if (column.name === 'Category') {
      return (
        <TableCell>
          {row.Category && row.Category.CategoryName
            ? row.Category.CategoryName
            : ''}
        </TableCell>
      );
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };
  const cols =
    currentlist &&
    categories.findIndex(({ CategoryCode }) => currentlist === CategoryCode) ===
      -1
      ? [
          { name: 'Name' },
          { name: 'CategoryName', title: 'Category' },
          { name: 'AverageScore', title: 'Average Score' },
          { name: 'Judged', title: 'Judged (times)' },
          { name: 'Variance', title: 'Variation' },
          { name: 'Disqualified' },
        ]
      : [
          { name: 'Title' },
          { name: 'Category' },
          { name: 'StoryFormat', title: 'Format' },
        ];
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" minHeight="90vh">
      <DialogHeader>Add Entries</DialogHeader>
      <DialogContent>
        {updated && (
          <RemoteDataGrid
            url={requesturl}
            axiosinstance={adminaxiosinstance}
            header={renderHeader}
            hiddencolumns={['Actions']}
            params={{ ...requestparams, StoryFormat: currentformat }}
            cellComponent={cellComponent}
            columns={cols}
            allowColumnResizing={false}
            onChangeSelection={onChangeSelection}
            pageSizes={[50, 100, 500, 1200, 2000]}
            dataExtractor={(data) => {
              if (data && Array.isArray(data.items)) {
                return data.items.map((item) => ({
                  ...(item || {}),
                  CategoryName: `${
                    ((item || {}).Category || {}).CategoryName || ''
                  } - ${item.StoryFormat || ''}`,
                  ...(judgingResults(item) || {}),
                }));
              }
              return [];
            }}
            pagingComponent={null}
            // limit={1200}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color={colors.primary}
          onClick={() => {
            pushEntries(entries);
            setTimeout(() => {
              setentries([]);
            });
          }}
        >
          Add Entries
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Entry;
