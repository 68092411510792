import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Button from '@cloudhub-ux/core/Button';
import { Form, Field } from '@cloudhub-ux/core/form';
import toastr from '@cloudhub-ux/core/toastr';
import { DatePicker } from '@cloudhub-ux/core/ant';
import DialogContent from '@cloudhub-ux/core/dialogs/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import useAdminAxios from 'admin/context/useAdminAxios';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import StatisticsForm from './StatisticsForm';

const SystemSettingsForm = () => {
  const [settings, setSettings] = React.useState({});

  const { data, postData } = useAdminAxios({
    url: '/system/settings/getsystemsettings',
  });
  React.useEffect(() => {
    if (data) {
      setSettings(data);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(data)]);
  const saveSettings = async (values) => {
    try {
      const data = await postData({
        url: '/system/settings/updatesystemsettings',
        params: { ...values },
      });
      setSettings(data);
      toastr.success('Settings updated');
    } catch (error) {
      setSettings({});
    }
  };

  return (
    <Block>
      <Text h4>System Settings</Text>

      <Form
        onSubmit={saveSettings}
        initialValues={{ id: 'systemsettings', ...settings }}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent>
              <FieldBlock row wrap>
                <Field
                  label="Opening Date"
                  type="text"
                  name="OpeningDate"
                  component={DatePicker}
                  showTime
                />
                <Field
                  label="Closing Date"
                  type="text"
                  name="ClosingDate"
                  component={DatePicker}
                  showTime
                />
              </FieldBlock>
              <Field name="Statistics" component={StatisticsForm} />
            </DialogContent>

            <DialogActions>
              <Button
                contained
                color="primary"
                disabled={pristine || submitting}
                onClick={handleSubmit}
              >
                <Text white>Update system settings</Text>
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Block>
  );
};

export default SystemSettingsForm;
