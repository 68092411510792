import React from 'react';
import Loadable from '@react-loadable/revised';
import { Router } from '@cloudhub-ux/core/reach';
import Loading from '@cloudhub-ux/core/Loading';
import LocationProvider from '@cloudhub-ux/core/customhooks/LocationProvider';
import { hot } from 'react-hot-loader/root';
import AuthProvider from 'auth/context/AuthProvider';
import AuthContext from 'auth/context/AuthContext';
import AdminApp from 'admin/AdminApp';

const UpdatesListener = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UpdatesListener" */ './serviceworkers/UpdatesListener'
    ).then((module) => module.default),
  loading: Loading,
});

const SocketProvider = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SocketProvider" */ './socket/SocketProvider'
    ).then((module) => module.default),
  loading: Loading,
});

const WebsiteTheme = Loadable({
  loader: () =>
    import(/* webpackChunkName: "WebsiteApp" */ './app/WebsiteTheme').then(
      (module) => module.default
    ),
  loading: Loading,
});

// const AdminApp = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "AdminApp" */ './admin/AdminApp').then(
//       (module) => module.default
//     ),
//   loading: Loading,
// });
const JudgingApp = Loadable({
  loader: () =>
    import(/* webpackChunkName: "JudgingApp" */ './judging/JudgingApp').then(
      (module) => module.default
    ),
  loading: Loading,
});

// const generateClassName = createGenerateClassName({
//   dangerouslyUseGlobalCSS: true,
//   productionPrefix: 'man',
// });

const HomeRouter = () => {
  const { isAuthenticated } = React.useContext(AuthContext);
  return (
    <Router style={{ flex: 1, height: '100%' }}>
      <WebsiteTheme default path="/" />
      {isAuthenticated && <AdminApp path="/admin/*" />}
      {isAuthenticated && <JudgingApp path="/judging/*" />}
    </Router>
  );
};

const App = () => {
  const removeServerSideStyles = () => {
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  };

  React.useEffect(() => {
    removeServerSideStyles();
  }, []);

  return (
    <LocationProvider>
      <AuthProvider>
        <SocketProvider>
          <HomeRouter />
          <UpdatesListener />
        </SocketProvider>
      </AuthProvider>
    </LocationProvider>
  );
};
export default process.env.NODE_ENV === 'development' ? hot(App) : App;
