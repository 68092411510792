import { SW_INIT, SW_UPDATE } from './swActions';

const swReducer = (state, action) => {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        registeredServiceWorker: action.payload,
      };
    default:
      return state;
  }
};

export default swReducer;
