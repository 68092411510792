import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Dashboard from './dashboard/Dashboard';
import SuperAdminRoute from './SuperAdminRoute';
import SystemRoutes from './system/SystemRoutes';
import JudgingRoutes from './judging/JudgingRoutes';
import EntryRoutes from './entry/EntryRoutes';
import WebsiteRoutes from './website/WebsiteRoutes';

const AdminRoutes = () => (
  <Router
    style={{
      display: 'flex',
      flex: 1,
    }}
  >
    <Dashboard default path="/dashboard" />
    <JudgingRoutes path="judging/*" />
    <WebsiteRoutes path="/website/*" />

    <SuperAdminRoute component={SystemRoutes} path="system/*" />
    <EntryRoutes path="entries/*" />
  </Router>
);

export default AdminRoutes;
