import React from 'react';
import { navigate } from '@cloudhub-ux/core/reach';
import { withStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Grid';
import Text from '@cloudhub-ux/core/Text';
import Block from '@cloudhub-ux/core/Block';
import { sizes, colors } from 'theme';
import UserIcon from '@mui/icons-material/AccountCircle';
import LateIcon from '@mui/icons-material/AssignmentLate';
import PasswordIcon from '@mui/icons-material/Security';
import ExitToApp from '@mui/icons-material/ExitToApp';
import EntryIcon from '@mui/icons-material/Receipt';

import DefaultUserImage from 'auth/assets/images/default-user.png';
import AuthContext from './context/AuthContext';
import { UNAUTH_USER } from './context/actions/authTypes';

const styles = () => ({
  accountButton: {
    textTransform: 'capitalize',
  },
  popoverCard: {
    paddingBottom: 45,
  },
  notificationCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: -10,
    marginRight: -10,
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    width: 85,
    height: 85,
  },
  accountInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.darkColor,
  },
  accountDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  editAccountButton: {
    color: colors.darkColor,
    textTransform: 'capitalize',
    borderBottom: '1px solid #CCC',
  },
});

const UserDrawer = ({ open, onCloseUserDrawer }) => {
  const classes = makeStyles(styles);

  const {
    dispatch,
    isAuthenticated,
    currentUser,
    authActions: { openChangePassword },
  } = React.useContext(AuthContext);

  const signOut = () => {
    try {
      localStorage.removeItem('token');
    } catch (error) {}

    dispatch({
      type: UNAUTH_USER,
    });
  };
  return (
    <Drawer anchor="right" open={open} onClose={onCloseUserDrawer}>
      <div
        tabIndex={0}
        role="button"
        onClick={onCloseUserDrawer}
        onKeyDown={onCloseUserDrawer}
      >
        <Block center middle className={classes.accountInfo}>
          <Avatar
            alt={currentUser.name || ''}
            src={currentUser.picture || DefaultUserImage}
            className={classNames(classes.avatar, classes.bigAvatar)}
          />
          <Block padding={sizes.padding}>
            <Text
              title
              style={{ color: colors.milkyWhiteColor, textAlign: 'center' }}
            >
              {currentUser.name}
            </Text>
            <Text
              subTitle
              style={{ color: colors.milkyWhiteColor, textAlign: 'center' }}
            >
              {currentUser.email}
            </Text>
          </Block>
        </Block>
        <Divider />
        <div>
          <List>
            <ListItem onClick={() => navigate('/auth/profile')} button>
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary="My Account" />
            </ListItem>
            <Divider />
            {isAuthenticated &&
              currentUser &&
              Array.isArray(currentUser.UserType) &&
              currentUser.UserType.includes('Entrant') && (
                <React.Fragment>
                  <ListItem button onClick={() => navigate('/entry/myentries')}>
                    <ListItemIcon>
                      <EntryIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Entries" />
                  </ListItem>
                  {/* <ListItem
                    onClick={() => navigate('/entry/mynominations')}
                    button
                  >
                    <ListItemIcon>
                      <EntryIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Nominations" />
                  </ListItem> */}
                </React.Fragment>
              )}
            {isAuthenticated &&
              currentUser &&
              Array.isArray(currentUser.UserType) &&
              currentUser.UserType.includes('Admin') && (
                <ListItem onClick={() => navigate('/admin')} button>
                  <ListItemIcon>
                    <LateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Administration Panel" />
                </ListItem>
              )}
            {isAuthenticated &&
              currentUser &&
              Array.isArray(currentUser.UserType) &&
              currentUser.UserType.includes('Judge') && (
                <ListItem onClick={() => navigate('/judging')} button>
                  <ListItemIcon>
                    <LateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Judging Panel" />
                </ListItem>
              )}
            <ListItem
              button
              onClick={() => {
                openChangePassword();
              }}
            >
              <ListItemIcon>
                <PasswordIcon />
              </ListItemIcon>
              <ListItemText primary="Change Password" />
            </ListItem>

            <ListItem
              button
              style={{ backgroundColor: '#DDD' }}
              onClick={signOut}
            >
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </ListItem>
          </List>
        </div>
      </div>
    </Drawer>
  );
};

UserDrawer.defaultProps = {
  onCloseUserDrawer: () => {},
};

export default withStyles(styles)(UserDrawer);
