import React from 'react';
import AuthContext from 'auth/context/AuthContext';

const useAwardYear = () => {
  const [AwardYear, setAwardYear] = React.useState(null);
  const [AwardYears, setAwardYears] = React.useState([]);

  const { axiosinstance, CONFIG } = React.useContext(AuthContext);

  const getAwardYear = async (id) => {
    try {
      const { data } = await axiosinstance().get(
        `${CONFIG.API_ENDPOINT}/app/system/awardyear/getone`,
        {
          params: { id },
        }
      );

      if (data && data.id) {
        setAwardYear(data);
      }
    } catch (error) {}
  };

  const getAwardYears = async () => {
    try {
      const { data } = await axiosinstance().get(
        `${CONFIG.API_ENDPOINT}/app/system/awardyear/getall`,
        {
          params: { skip: 0, limit: 50 },
        }
      );
      if (data && Array.isArray(data.items)) {
        setAwardYears(data.items);
        const ind = data.items.findIndex((item) => item.Status === 'OPEN');
        setAwardYear(data.items[ind || 0]);
      }
    } catch (error) {}
  };

  const changeAwardYear = (year) => {
    setAwardYear(year);
  };

  React.useEffect(() => {
    getAwardYears();
    // eslint-disable-next-line
  }, []);

  return {
    AwardYear,
    AwardYears,
    awardYearActions: {
      getAwardYear,
      getAwardYears,
      changeAwardYear,
    },
  };
};
export default useAwardYear;
