// leave off @2x/@3x
import Logo from '../assets/images/logo.png';
import AjeaLogo from '../assets/images/ajealogo.png';
import AjeaTrophy from '../assets/images/ajeatrophy.png';
import noImage from '../assets/images/no_available_image.png';
import CEO from '../assets/images/team/CEO.jpg';
import landing from '../assets/images/gallery/landing.jpeg';
// import aboutBanner from '../assets/images/slide.png';

const images = {
  Logo,
  noImage,
  CEO,
  landing,
  AjeaLogo,
  AjeaTrophy,
  logo: '/assets/images/logo.png',
  // aboutBanner,
  twitterImage:
    'https://pesapurse.fra1.digitaloceanspaces.com/images/40cff923-5929-4553-a9fb-dfceaf5161d7.jpg',
};

export default images;
