import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import TextArea from '@cloudhub-ux/core/TextArea';
import Input from '@cloudhub-ux/core/Input';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import Button from '@cloudhub-ux/core/Button';
import { Form, Field } from '@cloudhub-ux/core/form';
import toastr from '@cloudhub-ux/core/toastr';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@cloudhub-ux/core/dialogs';
import { colors } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';

const CriteriaForm = ({ viewing, onClose }) => {
  const { JudgingLevelId, currentscore, currentremarks, JudgeName, EntryName } =
    viewing || {};
  const [judgingcriteria, setjudgingcriteria] = React.useState({});
  // const [weights, setweights] = React.useState({});
  const [maxscores, setmaxscores] = React.useState({});
  const [score, setscore] = React.useState({});
  const [remarks, setremarks] = React.useState(currentremarks);

  const { getData } = useAdminAxios({});

  const getCriteria = React.useCallback(async () => {
    if (JudgingLevelId) {
      try {
        const data = await getData({
          url: '/judging/judgingcriteria/getcriteria',

          params: { JudgingLevelId },
        });
        if (data) {
          setjudgingcriteria(data.item);
        }
      } catch (error) {
        const response = error.response || {};
        const data = response.data || {};
        toastr.error(data.message || error.message || error);
      }
    }
    // eslint-disable-next-line
  }, [JudgingLevelId]);

  React.useEffect(() => {
    getCriteria();
  }, [JudgingLevelId, getCriteria]);

  const getWeights = React.useCallback(() => {
    // const weightobj = {};
    const scoreobj = {};
    const preem = [];
    if (
      judgingcriteria &&
      judgingcriteria.Parameters &&
      Array.isArray(judgingcriteria.Parameters)
    ) {
      judgingcriteria.Parameters.map((parameter) => {
        if (parameter.Questions && Array.isArray(parameter.Questions)) {
          parameter.Questions.map((question) => {
            // weightobj[
            //   `${parameter.ParameterName.replace(
            //     /[^a-zA-Z]/g,
            //     ''
            //   )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
            // ] = question.Weight;
            scoreobj[
              `${parameter.ParameterName.replace(
                /[^a-zA-Z]/g,
                ''
              )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
            ] = question.Max;
            if (question.Preeminent === 'Yes') {
              preem.push(
                `${parameter.ParameterName.replace(
                  /[^a-zA-Z]/g,
                  ''
                )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
              );
            }
            return true;
          });
        }
        return true;
      });
      if (preem.length > 0) {
        preem.map((p) => {
          // delete weightobj[p];
          delete scoreobj[p];
          return p;
        });
      }
      // setweights(weightobj);
      setmaxscores(scoreobj);
    }
  }, [judgingcriteria]);

  React.useEffect(() => {
    getWeights();
  }, [judgingcriteria, getWeights]);

  React.useEffect(() => {
    if (
      currentscore &&
      Object.keys(currentscore || []).length > 0 &&
      // weights &&
      maxscores
    ) {
      const rawscore = {};
      const keys = Object.keys(currentscore || []);
      keys.map((k) => {
        const thisscore = Number(currentscore[k]); // * Number(maxscores[k])/ Number(weights[k])
        rawscore[k] = thisscore;
        return rawscore;
      });
      setscore(rawscore);
    } else {
      setscore({});
    }
  }, [maxscores, currentscore]);
  React.useEffect(() => {
    setremarks(currentremarks);
  }, [currentremarks]);

  return (
    <Dialog
      open={viewing !== null}
      maxWidth="xl"
      minHeight="85%"
      onClose={onClose}
    >
      <DialogHeader>{`${JudgeName} - ${EntryName}`}</DialogHeader>
      <DialogContent>
        <Block>
          <Form
            onSubmit={() => {}}
            initialValues={{ ...score, ...remarks }}
            render={({ values }) => (
              <Block style={{ width: '100%' }}>
                {judgingcriteria &&
                  Array.isArray(judgingcriteria.Parameters) &&
                  judgingcriteria.Parameters.map((parameter) => (
                    <Block key={parameter.ParameterName}>
                      <Text header>{parameter.ParameterName}</Text>
                      <Text bold dark style={{ fontStyle: 'italic' }}>
                        {parameter.Description || ''}
                      </Text>
                      <Text>{parameter.UmbrellaQuestion || ''}</Text>
                      {parameter.Questions &&
                        Array.isArray(parameter.Questions) &&
                        parameter.Questions.map((question, index) => (
                          <React.Fragment key={question.Question}>
                            {(values[
                              `${parameter.ParameterName.replace(
                                /[^a-zA-Z]/g,
                                ''
                              )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
                            ] ||
                              values[
                                `${parameter.ParameterName.replace(
                                  /[^a-zA-Z]/g,
                                  ''
                                )}${question.Question.replace(
                                  /[^a-zA-Z]/g,
                                  ''
                                )}`
                              ] === 0) && (
                              <Field
                                type="number"
                                number
                                name={`${parameter.ParameterName.replace(
                                  /[^a-zA-Z]/g,
                                  ''
                                )}${question.Question.replace(
                                  /[^a-zA-Z]/g,
                                  ''
                                )}`}
                                label={`${index + 1}. ${question.Question}`}
                                component={Input}
                                disabled
                              />
                            )}
                            <FieldBlock>
                              {values[
                                `${parameter.ParameterName.replace(
                                  /[^a-zA-Z]/g,
                                  ''
                                )}${question.Question.replace(
                                  /[^a-zA-Z]/g,
                                  ''
                                )}Remarks`
                              ] && (
                                <Field
                                  name={`${parameter.ParameterName.replace(
                                    /[^a-zA-Z]/g,
                                    ''
                                  )}${question.Question.replace(
                                    /[^a-zA-Z]/g,
                                    ''
                                  )}Remarks`}
                                  label="Remarks:"
                                  component={TextArea}
                                  disabled
                                />
                              )}
                            </FieldBlock>
                          </React.Fragment>
                        ))}
                    </Block>
                  ))}
              </Block>
            )}
          />
        </Block>
      </DialogContent>
      <DialogActions>
        <Button color={colors.secondary} contained onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CriteriaForm;
