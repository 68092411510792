import React from 'react';

import Input from '@cloudhub-ux/core/Input';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import { Form, Field } from '@cloudhub-ux/core/form';
import toastr from '@cloudhub-ux/core/toastr';
import List from '@mui/material/List';
import FieldButton from '@cloudhub-ux/core/FieldButton';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import { colors } from 'theme';
import ListMenuItem from '@cloudhub-ux/core/ListMenuItem';
import SortableList from 'react-sortablejs-list';
import DragHandle from '@mui/icons-material/DragHandle';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import IconButton from '@cloudhub-ux/core/IconButton';

const StatisticsForm = ({ input: { onChange, value } }) => {
  const [editing, setediting] = React.useState({});
  const addStatistic = (stat) => {
    try {
      const ind = (value || []).findIndex(({ Name }) => Name === stat.Name);
      const stats = [...(value || [])];
      if (ind === -1) {
        stats.push(stat);
      } else {
        stats[ind] = stat;
      }
      onChange(stats);
      setTimeout(() => {
        setediting({});
      });
    } catch (error) {
      toastr.error(error.message);
    }
  };

  const removeStat = (index) => {
    const stats = [...(value || [])];
    stats.splice(index, 1);
    onChange(stats);
  };
  return (
    <Form
      onSubmit={addStatistic}
      initialValues={editing || {}}
      render={({ handleSubmit, pristine, submitting }) => (
        <Block row wrap>
          <List>
            <SortableList options={value || []} onChange={onChange}>
              {[...(value || [])].map((item, index) => (
                <ListMenuItem
                  button={false}
                  key={item.Name}
                  icon={
                    <IconButton style={{ cursor: 'move' }}>
                      {' '}
                      <DragHandle />
                    </IconButton>
                  }
                  primary={
                    <Text bold style={{ marginRight: 70 }}>
                      {item.Name}
                    </Text>
                  }
                  secondary={item.Value}
                  action={
                    <Block row>
                      <IconButton onClick={() => setediting(item)}>
                        <Edit color="primary" />
                      </IconButton>
                      <IconButton onClick={() => removeStat(index)}>
                        <Delete style={{ color: colors.error }} />
                      </IconButton>
                    </Block>
                  }
                />
              ))}
            </SortableList>
          </List>
          <FieldBlock row wrap flex>
            <Field
              name="Name"
              type="text"
              label="Statistic Name"
              component={Input}
              flex
            />
            <Field
              name="Value"
              type="text"
              label="Value"
              component={Input}
              flex
              format={(val) => (val || '').replace(/[^0-9+]/, '')}
            />
            <FieldButton
              color={colors.success}
              onClick={handleSubmit}
              disabled={pristine || submitting}
            >
              Add Statistic
            </FieldButton>
          </FieldBlock>
        </Block>
      )}
    />
  );
};

export default StatisticsForm;
