import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Text from '@cloudhub-ux/core/Text';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { colors, hexToRgb, sizes } from 'theme';

const LevelEntry = ({
  id,
  EntryName,
  _id,
  JudgingResults,
  FieldData,
  AverageScore,
  highlighted,
  onAssign,
  onRemove,
  selectEntry,
  activejudge,
  viewScore,
}) => {
  const [isjudged, setisjudged] = React.useState(false);
  React.useEffect(() => {
    setisjudged(false);
    if (highlighted && activejudge) {
      const judged = [...(JudgingResults || [])]
        .map((result) => {
          if (
            result.JudgeId === activejudge.JudgeId &&
            result.Points &&
            Object.keys(result.Points).length > 0
          ) {
            return result.Final ? true : 'NOT_SUBMITTED';
          }
          return false;
        })
        .filter(Boolean);
      setisjudged(judged.length > 0 ? judged[0] : false);
    }
  }, [activejudge, highlighted, JudgingResults, FieldData]);

  return (
    <Block
      key={id}
      middle
      flex={false}
      padding={10}
      margin={10}
      style={{ width: 300, cursor: 'pointer' }}
      shadow
      color={!highlighted ? 'white' : `rgb(${hexToRgb(colors.success)}, 0.3)`}
    >
      <Block row>
        <Block
          row
          space="between"
          style={{ cursor: 'pointer', overflow: 'hidden' }}
          onClick={selectEntry}
        >
          <Text h4 bold>
            {EntryName}
          </Text>
          <Text small>
            Points:
            <Text h4 color={AverageScore ? colors.primary : colors.danger}>
              {`${(AverageScore || 0).toFixed(2)}`}
            </Text>
          </Text>
        </Block>
        {isjudged && (
          <CheckCircleOutline
            onClick={viewScore}
            style={{
              color: isjudged === true ? colors.success : colors.orange,
              fontSize: sizes.h5,
            }}
          />
        )}
      </Block>
      <Block>
        <Text>
          {`Judges: ${
            JudgingResults && Array.isArray(JudgingResults)
              ? JudgingResults.length
              : 0
          }`}
        </Text>
      </Block>
      <Block>
        <Text>
          {`Judged By: ${
            JudgingResults && Array.isArray(JudgingResults)
              ? JudgingResults.map(({ Final }) => (Final ? 1 : 0)).reduce(
                  (a, b) => a + b,
                  0
                )
              : 0
          }`}
        </Text>
      </Block>
      <Block row right>
        <Button
          small
          onClick={() => onAssign({ id, EntryName, _id })}
          color={colors.success}
          contained
        >
          <Text white>{highlighted ? 'Unassign' : 'Assign'}</Text>
        </Button>
        <Button
          contained
          small
          color={colors.error}
          onClick={() => onRemove({ id, EntryName })}
          style={{ marginLeft: 10 }}
        >
          <Text white>Remove</Text>
        </Button>
      </Block>
    </Block>
  );
};

export default LevelEntry;
