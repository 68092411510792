import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import NullLoading from '@cloudhub-ux/core/NullLoading';
import Button from '@cloudhub-ux/core/Button';
import Loadable from '@react-loadable/revised';
import { sizes, colors } from 'theme';
import { navigate } from '@cloudhub-ux/core/reach';
import UserDrawer from './UserDrawer';
import UpdateProfileDialog from './UpdateProfileDialog';
import AuthContext from './context/AuthContext';

const SignInDialog = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SignInDialog" */ 'auth/SignInDialog'),
  loading: NullLoading,
});

const ChangePasswordDialog = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ChangePasswordDialog" */ 'auth/ChangePasswordDialog'
    ),
  loading: NullLoading,
});

const AccountButton = ({ userinterface = 'app', location }) => {
  const [userdraweropen, setUserDrawerOpen] = React.useState(false);

  const { isAuthenticated, currentUser, token } = React.useContext(AuthContext);

  React.useEffect(() => {
    try {
      if (token && localStorage) {
        localStorage.setItem('token', token);
      }
    } catch (error) {}
  }, [token]);

  const signIn = () => {
    if (userinterface === 'app') {
      navigate('/auth/signin', {
        state: {
          pathnamebeforelogin:
            location && location.pathname ? location.pathname : '',
        },
      });
    }
  };

  const renderLinks = () => {
    const name = currentUser.Name || '';
    const names = name.split(' ').sort((a, b) => b.length - a.length);

    let haspath = null;

    if (location && typeof location === 'object') {
      haspath =
        location.pathname.includes('/signin') ||
        location.pathname.includes('/signout');
    }
    return (
      <React.Fragment>
        {!isAuthenticated && !haspath && (
          <Button
            outlined
            color={colors.white}
            onClick={signIn}
            style={{
              borderRadius: sizes.buttonRadius, // 0,
              paddingTop: 0,
              paddingBottom: 0,
              borderColor: isAuthenticated ? colors.primary : colors.primary,
              marginRight: sizes.margin,
            }}
          >
            <Text
              noWrap
              button
              style={{
                color: isAuthenticated ? colors.primary : colors.primary,
              }}
            >
              Sign in
            </Text>
          </Button>
        )}
        {isAuthenticated && (
          <Button
            padding={sizes.padding}
            onClick={() => setUserDrawerOpen(true)}
            color={colors.white}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              marginRight: sizes.margin,
              borderColor:
                userinterface === 'app' ? colors.primary : colors.primary,
            }}
            outlined
          >
            <Text
              body
              noWrap
              style={{
                color:
                  userinterface === 'app' ? colors.primary : colors.primary,
              }}
            >
              {`Hi, ${names[0] || names[1]}`}
            </Text>
          </Button>
        )}
      </React.Fragment>
    );
  };

  return (
    <Block flex={false} center middle>
      {renderLinks()}
      <UserDrawer
        open={userdraweropen}
        onCloseUserDrawer={() => setUserDrawerOpen(false)}
      />

      <SignInDialog />
      <ChangePasswordDialog />
      <UpdateProfileDialog open={false} currentUser={currentUser} />
    </Block>
  );
};

AccountButton.defaultProps = {
  textStyle: {
    color: colors.darkColor,
  },
};

export default AccountButton;
