import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import { makeStyles } from '@mui/styles';
import { colors } from 'theme';
import useLocation from '@cloudhub-ux/core/customhooks/useLocation';
import CircularProgress from '@mui/material/CircularProgress';
import EntryDashboard from './EntryDashboard';
import UserDashboard from './UserDashboard';
import VoteDashboard from './VoteDashboard';

const useStyles = makeStyles({
  bottomContainer: {
    flexDirection: 'row !important',
    '@media (max-width: 1200px)': {
      flexDirection: 'column !important',
    },
  },
  container: {
    maxWidth: '100%',
  },
});
const Dashboard = () => {
  const [updateYear, setupdateYear] = React.useState(false);
  const classes = useStyles();

  const { location } = useLocation();

  React.useEffect(() => {
    if (location.state && location.state.reload) {
      setupdateYear(true);
      setTimeout(() => {
        setupdateYear(false);
      }, 1500);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(location.state)]);

  if (updateYear) {
    return (
      <Block middle center>
        <CircularProgress color="primary" />
      </Block>
    );
  }
  return (
    <Block className={classes.container} color={colors.gray4}>
      <EntryDashboard />
      <Block middle className={classes.bottomContainer}>
        {/* <VoteDashboard /> */}
        <UserDashboard />
      </Block>
    </Block>
  );
};

export default Dashboard;
