import React from 'react';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';
import JudgingCriteriaForm from './forms/JudgingCriteriaForm';

const JudgingCriteria = () => {
  const [editingRow, seteditingRow] = React.useState(null);

  const gridRef = React.useRef();
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const { postData } = useAdminAxios({});
  const deleteRows = async (rows) => {
    const ids = rows.map((item) => item.id);
    const data = await postData({
      url: 'judging/judgingcriteria/delete',
      params: { criteria: ids },
    });
    if (data) {
      gridRef.current.reload();
    }
  };

  const renderHeader = ({
    allowprint,
    onSearch,
    onRefresh,
    onAdd,
    onPrint,
    allowadd,
    title,
  }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={() => onAdd()} disabled={!allowadd}>
            <AddIcon /> Add
          </Button>
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
          <Button onClick={() => onPrint()} disabled={!allowprint}>
            <PrintIcon /> Print
          </Button>
        </Block>
      </Block>
      <Block />
    </Block>
  );

  const renderTemplates = ({ row, column }) => {
    // row, column, style
    if (column.name === 'Questions') {
      return (
        <TableCell>
          <Text bold>
            {Array.isArray(row.Parameters)
              ? String(
                  row.Parameters.map((param) => {
                    if (param && Array.isArray(param.Questions)) {
                      return param.Questions.length;
                    }
                    return 0;
                  }).reduce((a, b) => a + b, 0)
                )
              : '0'}
          </Text>
        </TableCell>
      );
    }
    return (
      <TableCell>
        <Text bold>{row[column.name] || ''}</Text>
      </TableCell>
    );
  };
  const cols = [
    { name: 'Name', title: 'Name' },
    { name: 'Questions', title: 'Questions' },
  ];

  const columnWidths = [
    { columnName: 'Questions', width: 150 },
    { columnName: 'Name', width: 150 },
  ];

  return (
    <Block>
      {!editingRow && (
        <RemoteDataGrid
          title={<Text h2>Judging Criterias</Text>}
          columns={cols}
          columnWidths={columnWidths}
          allowColumnResizing={false}
          header={renderHeader}
          url="/judging/judgingcriteria/getall"
          axiosinstance={adminaxiosinstance}
          cellComponent={renderTemplates}
          onAdd={() => seteditingRow({})}
          onDeleteRows={(row) => deleteRows(row)}
          onEdit={seteditingRow}
          pagingComponent={null}
          ref={gridRef}
        />
      )}
      {editingRow && (
        <JudgingCriteriaForm
          editcriteriaTitle={(editingRow || {}).id}
          editingcriteriaRow={editingRow}
          onCancelEdit={() => seteditingRow(null)}
          onSave={() => gridRef.current.reload()}
        />
      )}
    </Block>
  );
};

export default JudgingCriteria;
