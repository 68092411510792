import React, { Fragment, useState, useRef } from 'react';
import TableCell from '@mui/material/TableCell';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import CheckBox from '@cloudhub-ux/core/CheckBox';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';
import EntryForm from './forms/EntryForm';
import SendSmsForm from '../forms/SendSmsForm';
import ColumnSelector from './forms/ColumnSelector';

const Entry = () => {
  const gridRef = useRef();
  const csvLinkElem = useRef();
  const [phonenumbers, setphonenumbers] = useState([]);
  const [smsDialogOpen, openSmsDialog] = useState(false);
  const [dataarray, setdataarray] = useState([]);
  const [editingRow, seteditingRow] = React.useState(null);
  const [headers, setHeaders] = React.useState([]);
  const [requestParams, setrequestParams] = React.useState({
    Status: 'SUBMITTED',
  });
  const [exporting, setexporting] = React.useState(false);

  const { postData } = useAdminAxios({});
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const onChangeSelection = (selection) => {
    const numbers = [];
    if (Array.isArray(selection)) {
      selection.map((s) => {
        if (numbers.indexOf(s.Phone) === -1) {
          numbers.push(s.Phone);
        }
        return null;
      });
    }
    const entrieswithdates = [...(selection || [])].map((entry) => ({
      ...entry,
      Date: dayjs(entry.updatedAt).format('DD MMM, YYYY hh:mm a'),
      Gender: entry ? entry.Gender : '',
      Category:
        entry && entry.Category && entry.Category.CategoryName
          ? entry.Category.CategoryName
          : '',
      County: entry ? entry.County : '',
      Title: entry ? entry.Title : '',
      Name: entry ? `${entry.FirstName} ${entry.Surname}` : '',
    }));

    setphonenumbers(numbers);
    setdataarray(entrieswithdates);
    // gridRef.current.reload();
  };
  const onOpenSmsDialog = () => {
    openSmsDialog(true);
  };

  const deleteRows = async (rows) => {
    try {
      const ids = rows.map((item) => item.id);
      const data = await postData({
        url: '/entry/entry/delete',
        params: { entries: ids },
      });
      if (data) {
        gridRef.current.reload();
      }
    } catch (error) {}
  };

  const renderHeader = ({ onSearch, onRefresh, title }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={onOpenSmsDialog} disabled={!phonenumbers}>
            <SendIcon /> Send SMS to Selected
          </Button>
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
          <CSVLink
            data={dataarray}
            headers={headers}
            asyncOnClick
            ref={csvLinkElem}
          >
            <Button
              onClick={(event) => {
                event.preventDefault();
                setexporting(true);
              }}
            >
              <ListAltIcon /> Export Selected to Excel
            </Button>
          </CSVLink>
          <CheckBox
            height={sizes.icons.medium}
            tag="Include Deleted"
            value={(requestParams || {}).Status !== 'SUBMITTED'}
            onChange={() =>
              setrequestParams((current) => {
                if (current && current.Status === 'SUBMITTED') {
                  return {};
                }
                return { Status: 'SUBMITTED' };
              })
            }
          />
        </Block>
      </Block>
      <Block />
    </Block>
  );

  const renderTemplates = ({ row, column }) => {
    // row, column, style
    if (column.name === 'TimeStamp') {
      return (
        <TableCell>
          <Text bold>
            {dayjs(row.TimeStamp || row.createdAt).format(
              'DD MMM, YYYY hh:mm a'
            )}
          </Text>
        </TableCell>
      );
    }
    if (column.name === 'Status') {
      return (
        <Fragment>
          <TableCell>
            <Text
              bold={row.Status === 'SUBMITTED'}
              color={row.Status === 'SUBMITTED' ? colors.primary : colors.black}
            >
              {row.Status}
            </Text>
          </TableCell>
        </Fragment>
      );
    }
    if (column.name === 'Name') {
      return (
        <Fragment>
          <TableCell>
            <Text bold>{`${row.FirstName} ${row.Surname}`}</Text>
          </TableCell>
        </Fragment>
      );
    }
    if (column.name === 'MediaOrganization') {
      return (
        <Fragment>
          <TableCell>
            <Text bold>
              {`${
                (row.MediaOrganization || {}).MediahouseName ||
                `${
                  typeof row.MediaOrganization === 'string'
                    ? row.MediaOrganization
                    : 'Freelancer'
                }`
              }`}
            </Text>
          </TableCell>
        </Fragment>
      );
    }
    return (
      <TableCell>
        <Text bold>{row[column.name] || ''}</Text>
      </TableCell>
    );
  };

  const cols = [
    { name: 'Title' },
    { name: 'Name' },
    { name: 'CategoryName', title: 'Category' },
    { name: 'MediaOrganization', title: 'Organization' },
    { name: 'Status' },
    { name: 'StoryFormat', title: 'Format' },
    { name: 'TimeStamp', title: 'Date' },
  ];

  const columnWidths = [
    { columnName: 'Title', width: 150 },
    { columnName: 'Name', width: 150 },
    { columnName: 'Category', width: 150 },
    { columnName: 'MediaOrganization', width: 200 },
    { columnName: 'PlacePublished', width: 200 },
    { columnName: 'Status', width: 150 },
    { columnName: 'StoryFormat', width: 150 },
    { columnName: 'updatedAt', width: 70 },
  ];

  return (
    <Block>
      {!editingRow ? (
        <Block>
          <RemoteDataGrid
            title="Entries"
            columns={cols}
            columnWidths={columnWidths}
            allowColumnResizing={false}
            header={renderHeader}
            url="/entry/entry/getall"
            axiosinstance={adminaxiosinstance}
            cellComponent={renderTemplates}
            onEdit={seteditingRow}
            onDeleteRows={deleteRows}
            onChangeSelection={onChangeSelection}
            ref={gridRef}
            params={requestParams}
            dataExtractor={(data) => {
              if (data && Array.isArray(data.items)) {
                return data.items.map((item) => ({
                  ...(item || {}),
                  CategoryName:
                    ((item || {}).Category || {}).CategoryName || '',
                  DatePublished: dayjs(item.DatePublished).format('DD/MM/YYYY'),
                }));
              }
              return [];
            }}
            pagingComponent={null}
          />
          <SendSmsForm
            numbers={phonenumbers}
            open={smsDialogOpen}
            onClose={() => openSmsDialog(false)}
          />
        </Block>
      ) : (
        <EntryForm
          editTitle={(editingRow || {}).EntryName}
          currentEntry={editingRow}
          onCancelEdit={() => seteditingRow(null)}
        />
      )}

      <ColumnSelector
        headers={headers}
        setHeaders={setHeaders}
        open={exporting}
        onCancel={() => setexporting(false)}
        csvLinkElem={csvLinkElem}
      />
    </Block>
  );
};

export default Entry;
