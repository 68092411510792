import React from 'react';

import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import Button from '@cloudhub-ux/core/Button';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@cloudhub-ux/core/dialogs';
import { colors } from 'theme';

const ConfirmDialog = ({
  title,
  onConfirm,
  onCancel,
  text,
  open,
  confirmText,
}) => (
  <Dialog
    maxWidth="sm"
    minHeight={200}
    style={{ margin: 'auto', height: 200 }}
    onClose={onCancel}
    open={open}
  >
    <DialogHeader>{title}</DialogHeader>
    <DialogContent>{text}</DialogContent>
    <DialogActions>
      <Button contained color={colors.secondary} onClick={onCancel}>
        Cancel
      </Button>
      <Button contained color={colors.primary} onClick={onConfirm}>
        {confirmText || 'OK'}
      </Button>
    </DialogActions>
  </Dialog>
);
export default ConfirmDialog;
