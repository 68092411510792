/* eslint-disable capitalized-comments */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';

import Block from '@cloudhub-ux/core/Block';
import Alert from '@cloudhub-ux/core/Alert';
import useAdminAxios from 'admin/context/useAdminAxios';

import PartnerForm from './forms/PartnerForm';

// const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Partner = () => {
  const { getData, adminaxiosinstance } = useAdminAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const deleteRows = async (deletingRows) => {
    const ids = deletingRows.map((item) => item.id);
    getData({ url: '/website/partner/delete', params: { partners: ids } });
    gridRef.current.onDeleteSuccess(deletingRows);
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [
    { name: 'PartnerName', title: 'Partner Name' },
    { name: 'Email', title: 'Email' },
    { name: 'Phone', title: 'Phone' },
  ];

  const columnWidths = [
    { columnName: 'PartnerName', width: 150 },
    { columnName: 'Email', width: 150 },
    { columnName: 'Phone', width: 150 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="Partners"
        url="/website/partner/getall"
        axiosinstance={adminaxiosinstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
        pagingComponent={null}
      />
      {editingRow && (
        <PartnerForm
          editingRow={editingRow}
          onCancelEdit={() => setEditingRow(null)}
          onSave={(row) => gridRef.current.onSave(row)}
        />
      )}
    </Block>
  );
};

export default Partner;
