import React from 'react';

import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import Button from '@cloudhub-ux/core/Button';
import Text from '@cloudhub-ux/core/Text';
import {
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@cloudhub-ux/core/dialogs';
import { colors } from 'theme';

const ConfirmDeleteDialog = ({
  title,
  onDelete,
  onCancelDelete,
  unassign,
  judge,
  open,
}) => (
  <Dialog
    maxWidth="sm"
    minHeight={200}
    style={{ margin: 'auto', height: 200 }}
    onClose={onCancelDelete}
    open={open}
  >
    <DialogHeader>
      {`${unassign ? 'Unassign' : 'Remove'} ${title}`}
    </DialogHeader>
    <DialogContent>
      {!unassign && (
        <Text>
          Are you sure you want to Remove{' '}
          <Text primary header>
            {title}
          </Text>{' '}
          from Judging Level
        </Text>
      )}
      {unassign && (
        <Text>
          Unassign
          <Text primary header>
            {title}
          </Text>{' '}
          from{' '}
          <Text primary header>
            {judge}
          </Text>
        </Text>
      )}
    </DialogContent>
    <DialogActions>
      <Button contained color={colors.secondary} onClick={onCancelDelete}>
        Cancel
      </Button>
      <Button contained color={colors.primary} onClick={onDelete}>
        {`${unassign ? 'Unassign' : 'Remove'}`}
      </Button>
    </DialogActions>
  </Dialog>
);
export default ConfirmDeleteDialog;
