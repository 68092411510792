import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import User from './users/User';
import SystemSettings from './settings/SystemSettings';
import SystemsRoute from './SystemsRoute';
import Awardyear from './awardyear/Awardyear';

const SystemRoutes = () => (
  <Router style={{ flex: 1, display: 'flex' }}>
    <SystemsRoute component={SystemSettings} default path="systemsettings" />
    <SystemsRoute component={User} path="users" />
    <SystemsRoute component={Awardyear} path="awardyears" />
  </Router>
);

export default SystemRoutes;
