import AuthContext from 'auth/context/AuthContext';
import React from 'react';
import NotAuthorized from './NotAuthorized';

const SuperAdminRoute = ({ component: Component }) => {
  const { currentUser } = React.useContext(AuthContext);

  const { UserType } = currentUser;

  const isAdmin = UserType.includes('Admin');

  if (!isAdmin) {
    return <NotAuthorized />;
  }

  return <Component />;
};

export default SuperAdminRoute;
