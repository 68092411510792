import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import AntTable from '@cloudhub-ux/core/ant/AntTable';
import YesIcon from '@mui/icons-material/CheckBox';
import NoIcon from '@mui/icons-material/Close';
import Block from '@cloudhub-ux/core/Block';
import IconButton from '@cloudhub-ux/core/IconButton';

import { sizes, colors } from 'theme';
import permissions from './permissions';

// In the fifth row, other columns are merged into first column
// by setting it's colSpan to be 0
const renderContent = (value) => {
  const obj = {
    children: `${value}`,
    props: {},
  };
  return obj;
};

const styles = {
  yesicon: {
    margin: sizes.margin,
    color: colors.gray,
    width: 36,
    height: 36,
    '&:hover': {
      fill: colors.green,
    },
  },
  noicon: {
    fill: colors.gray,
    width: 36,
    height: 36,
    '&:hover': {
      fill: colors.gray,
    },
  },
};

const initialData = () =>
  permissions.map((item) => ({
    key: item.toLowerCase(),
    modulename: item,
    permissions: {
      R: false,
      RW: false,
      RWD: false,
    },
  }));

class UserRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: initialData(),
      columns: [
        {
          title: 'Module Name',
          dataIndex: 'modulename',
          render: renderContent,
        },
        {
          title: 'Permissions',
          children: [
            {
              title: 'Read',
              dataIndex: 'permissions.R',
              render: (text, row, index) =>
                this.renderPermission({ text, row, index, permission: 'R' }),
              width: 150,
            },
            {
              title: 'Read Write',
              dataIndex: 'permissions.RW',
              render: (text, row, index) =>
                this.renderPermission({ text, row, index, permission: 'RW' }),
              width: 150,
            },
            {
              title: 'Read Write Delete',
              dataIndex: 'permissions.RWD',
              render: (text, row, index) =>
                this.renderPermission({ text, row, index, permission: 'RWD' }),
              width: 150,
            },
          ],
        },
      ],
    };
  }

  componentDidMount() {
    this.setPermissions();
  }
  //
  // componentWillReceiveProps(nextProps){
  //     if(nextProps.input.value!==this.props.input.value){
  //         this.setPermissions();
  //     }
  // }

  setPermissions = () => {
    const perms = this.props.input.value || [];
    const permissions = this.state.data.map((perm) => {
      const ind = perms.findIndex((item) => item.key === perm.key);
      if (ind !== -1) {
        const p = perms[ind].permissions || {};
        return { ...perm, permissions: { ...perm.permissions, ...p } };
      }
      return perm;
    });
    this.setState({ data: permissions });
  };

  changeVal = ({ row, text, permission }) => {
    const ind = this.state.data.findIndex((item) => item.key === row.key);
    const perms = this.state.data || [];
    if (ind !== -1) {
      perms[ind] = {
        ...row,
        permissions: { ...row.permissions, [permission]: text },
      };
      this.props.input.onChange(perms);
      this.setState({ data: perms });
    }
  };

  renderPermission = ({ row, text, permission }) => {
    const { classes } = this.props;
    const renderIcon = () => {
      if (text === true) {
        return (
          <YesIcon
            classes={{
              root: classes.yesicon,
            }}
          />
        );
      }
      return (
        <NoIcon
          classes={{
            root: classes.noicon,
          }}
        />
      );
    };
    return {
      children: (
        <Block center>
          <IconButton
            aria-label="Delete"
            color="primary"
            onClick={() => this.changeVal({ text: !text, row, permission })}
          >
            {renderIcon()}
          </IconButton>
        </Block>
      ),
      props: {},
    };
  };

  render() {
    const { meta } = this.props;
    return (
      <Block>
        <AntTable
          columns={this.state.columns}
          dataSource={this.state.data}
          bordered
        />
        {meta.touched && meta.error && (
          <span className="error">{meta.error}</span>
        )}
      </Block>
    );
  }
}

export default withStyles(styles)(UserRoles);
