import React from 'react';
import { Form, Field } from '@cloudhub-ux/core/form';
import Input from '@cloudhub-ux/core/Input';
import Text from '@cloudhub-ux/core/Text';
import Block from '@cloudhub-ux/core/Block';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Button from '@cloudhub-ux/core/Button';
import toastr from '@cloudhub-ux/core/toastr';
import SaveIcon from '@mui/icons-material/Save';
import { DraftEditor } from '@cloudhub-ux/core/editors';
import AdminContext from 'admin/context/AdminContext';

import CircularProgress from '@mui/material/CircularProgress';

const validate = (values) => {
  const errors = {};
  if (!values.SenderName) {
    errors.SenderName = 'Required';
  }
  if (!values.ReplyEmail) {
    errors.ReplyEmail = 'Required';
  }
  return errors;
};

const EmailsConfigForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [emails, setEmails] = React.useState({});
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const saveEmails = async (values) => {
    try {
      const { data } = await adminaxiosinstance().post(
        '/system/settings/updateemails',
        {
          ...values,
        }
      );
      setEmails(data);
      toastr.success('Emails updated');
    } catch (error) {
      setEmails({});
    }
  };

  const loadEmails = async () => {
    setLoading(true);
    try {
      const { data } = await adminaxiosinstance().get(
        '/system/settings/getemails'
      );
      setEmails(data);
    } catch (error) {
      setEmails({});
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadEmails();
    // eslint-disable-next-line
  }, []);

  return (
    <Block>
      {loading && (
        <Block flex={false} center>
          <CircularProgress />
          <Text>Loading...</Text>
        </Block>
      )}
      <Form
        onSubmit={saveEmails}
        validate={validate}
        initialValues={{ ...emails }}
        render={({ handleSubmit, submitting, pristine }) => (
          <Block>
            <Block row right>
              <Button onClick={handleSubmit} disabled={submitting || pristine}>
                <SaveIcon /> Save
              </Button>
            </Block>
            <FieldBlock row>
              <FieldBlock flex>
                <label>Sender Name</label>
                <Field type="text" name="SenderName" component={Input} />
              </FieldBlock>
              <FieldBlock flex>
                <label>Reply Email</label>
                <Field type="text" name="ReplyEmail" component={Input} />
              </FieldBlock>
            </FieldBlock>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Registration Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="RegistrationEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="RegistrationEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Account Confirmation Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="AccountConfirmationEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="AccountConfirmationEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Support Auto Reply Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="SupportAutoReply.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="SupportAutoReply.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Reset Password Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="ResetPasswordEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="ResetPasswordEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Change Password Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="ChangePasswordEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="ChangePasswordEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Order Received Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="OrderReceivedEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="OrderReceivedEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Order Payment Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="OrderPaymentEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="OrderPaymentEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>

            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Referal Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="ReferalEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="ReferalEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
          </Block>
        )}
      />
    </Block>
  );
};

export default EmailsConfigForm;
