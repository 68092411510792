import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import FieldBlock from '@cloudhub-ux/core/IconButton';
import TextArea from '@cloudhub-ux/core/TextArea';
import { Form, Field } from '@cloudhub-ux/core/form';
import PhoneInput from '@cloudhub-ux/core/PhoneInput';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import S3ImagesUploader from '@cloudhub-ux/core/uploaders/S3ImagesUploader';
import axios from 'axios';
import AddressComponent from 'localcomponents/AddressComponent';
import { colors } from 'theme';
import { navigate } from '@cloudhub-ux/core/reach';
import AuthContext from './context/AuthContext';

const ProfileForm = () => {
  const [uploading, setuploading] = React.useState(false);
  const {
    currentUser,
    authActions: { updateProfile },
    CONFIG,
    axiosinstance,
    isAuthenticated,
  } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/signin');
    }
  }, [isAuthenticated]);

  const onUpdateProfile = (values) => {
    updateProfile(values);
  };

  return (
    <Form
      onSubmit={onUpdateProfile}
      initialValues={currentUser}
      render={({ handleSubmit, submitting, pristine }) => (
        <React.Fragment>
          <Block flex={false}>
            <Field label="Full Name" name="Name" component={Input} required />

            <FieldBlock row wrap>
              <Field
                label="Email"
                name="Email"
                component={Input}
                readOnly={Boolean(currentUser && currentUser.Email)}
                required
                email
                flex
              />

              <Field
                label="Phone"
                type="tel"
                name="Phone"
                component={PhoneInput}
                required
                readOnly
                flex
              />
            </FieldBlock>
            <FieldBlock>
              <AddressComponent />
            </FieldBlock>
            {currentUser &&
              Array.isArray(currentUser.UserType) &&
              currentUser.UserType.includes('Expert') && (
                <Block>
                  <Field
                    type="text"
                    name="ShortAbout"
                    label="Short About"
                    component={TextArea}
                    maxLength={200}
                  />
                  <Field
                    type="text"
                    name="Bio"
                    label="Bio"
                    component={TextArea}
                    height={400}
                  />
                  <Field
                    name="ProfilePicture"
                    component={S3ImagesUploader}
                    mime="image/jpeg"
                    label="ProfilePicture"
                    signaxiosinstance={axiosinstance}
                    uploadaxiosinstance={axios}
                    signurl={`${CONFIG.API_ENDPOINT}/media/upload/signput`}
                    deleteurl={`${CONFIG.API_ENDPOINT}/media/upload/deletefiles`}
                    minWidth={200}
                    maxSize={512}
                    uploading={uploading}
                    setuploading={setuploading}
                  />
                </Block>
              )}
          </Block>
          <DialogActions>
            <Button
              contained
              onClick={handleSubmit}
              disabled={submitting || pristine || uploading}
              color={colors.success}
            >
              <Text button white>
                Update Profile
              </Text>
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    />
  );
};

export default ProfileForm;
