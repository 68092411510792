import React from 'react';
import useLocation from '@cloudhub-ux/core/customhooks/useLocation';
import useAuthentication from './useAuthentication';
import AuthContext from './AuthContext';

const AuthProvider = (props) => {
  const { location } = useLocation();

  const { isAuthenticated, currentUser, token, authActions, ...rest } =
    useAuthentication();

  const { checkAuthStatus } = authActions;

  React.useEffect(() => {
    if (token && !isAuthenticated) {
      checkAuthStatus();
    }
    // eslint-disable-next-line
  }, [token, isAuthenticated]);

  React.useEffect(() => {
    if (isAuthenticated && currentUser.UserType.includes('Admin')) {
      if (!location.pathname.includes('/admin')) {
        // navigate("/admin");
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, currentUser]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        token,
        UserRoles: currentUser.UserRoles || [],
        UserType: currentUser.UserType,
        authActions,
        ...rest,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
