import React from 'react';

import Tab from '@cloudhub-ux/core/Tab';
import Tabs from '@cloudhub-ux/core/Tabs';
import Block from '@cloudhub-ux/core/Block';

import {
  CompanyInfoForm,
  EmailsConfigForm,
  HomePageMessagesForm,
  StaticPagesForm,
} from './forms';
import SystemSettingsForm from './forms/SystemSettingsForm';

function TabContainer(props) {
  return (
    <Block style={{ padding: 20 }} absolute>
      {props.children}
    </Block>
  );
}

const SystemSettings = () => {
  const [tabvalue, setTabValue] = React.useState(0);

  return (
    <Block>
      <Block flex={false}>
        <Tabs
          value={tabvalue}
          onChange={(e, tabvalue) => setTabValue(tabvalue)}
        >
          <Tab label="System Settings" />
          <Tab label="Company Information" />
          <Tab label="Email Settings" />
          <Tab label="Home Page Settings" />
          <Tab label="Static Pages" />
        </Tabs>
      </Block>

      <Block>
        {tabvalue === 0 && (
          <TabContainer>
            <SystemSettingsForm />
          </TabContainer>
        )}
        {tabvalue === 1 && (
          <TabContainer>
            <CompanyInfoForm />
          </TabContainer>
        )}
        {tabvalue === 2 && (
          <TabContainer>
            <EmailsConfigForm />
          </TabContainer>
        )}
        {tabvalue === 3 && (
          <TabContainer>
            <HomePageMessagesForm />
          </TabContainer>
        )}
        {tabvalue === 4 && (
          <TabContainer>
            <StaticPagesForm />
          </TabContainer>
        )}
      </Block>
    </Block>
  );
};

export default SystemSettings;
