import React from 'react';

import ListMenu from '@cloudhub-ux/core/ListMenu';
import ListSubMenu from '@cloudhub-ux/core/widgets/ListSubMenu';
import ListMenuItem from '@cloudhub-ux/core/widgets/ListMenuItem';
import { Link } from '@cloudhub-ux/core/reach';
import HomeIcon from '@mui/icons-material/Home';
import AppIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import Description from '@mui/icons-material/Description';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import Comment from '@mui/icons-material/Comment';
import AccountTree from '@mui/icons-material/AccountTree';
import Lens from '@mui/icons-material/Lens';
import PermMedia from '@mui/icons-material/PermMedia';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import { colors } from 'theme';

const DrawerMenu = () => (
  <ListMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      header="Main Menu"
    >
      <Link to="/admin">
        <ListMenuItem
          icon={<HomeIcon style={{ height: 14 }} />}
          primary="Dashboard"
        />
      </Link>
    </ListSubMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      headerIcon={<Description />}
      header="Website"
    >
      <Link to="/admin/website/infopages">
        <ListMenuItem
          icon={<AppIcon style={{ height: 14 }} />}
          primary="Info Pages"
        />
      </Link>

      <Link to="/admin/website/testimonies">
        <ListMenuItem
          icon={<Comment style={{ height: 14 }} />}
          primary="Testimonies"
        />
      </Link>
      <Link to="/admin/website/partners">
        <ListMenuItem
          icon={<AssignmentInd style={{ height: 14 }} />}
          primary="Partners"
        />
      </Link>
      <Link to="/admin/website/categories">
        <ListMenuItem
          icon={<AccountTree style={{ height: 14 }} />}
          primary="Categories"
        />
      </Link>
      <Link to="/admin/website/mediahouses">
        <ListMenuItem
          icon={<PermMedia style={{ height: 14 }} />}
          primary="Media Houses"
        />
      </Link>
      <Link to="/admin/website/mediastations">
        <ListMenuItem
          icon={<PermMedia style={{ height: 14 }} />}
          primary="TV &amp; Radio Stations"
        />
      </Link>
      <Link to="/admin/website/messages">
        <ListMenuItem
          icon={<PermMedia style={{ height: 14 }} />}
          primary="Messages"
        />
      </Link>
    </ListSubMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      headerIcon={<SettingsIcon />}
      header="Entries"
    >
      <Link to="/admin/entries/entries">
        <ListMenuItem
          icon={<Lens style={{ height: 14 }} />}
          primary="Entries"
        />
      </Link>
      {/* <Link to="/admin/entries/votes">
        <ListMenuItem icon={<Lens style={{ height: 14 }} />} primary="Votes" />
      </Link>
      <Link to="/admin/entries/votesms">
        <ListMenuItem
          icon={<Lens style={{ height: 14 }} />}
          primary="Vote SMS"
        />
      </Link> */}
    </ListSubMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      headerIcon={<SettingsIcon />}
      header="Judging"
      expanded
    >
      <Link to="/admin/judging/judginglevels">
        <ListMenuItem
          icon={<Lens style={{ height: 14 }} />}
          primary="Judging Levels"
        />
      </Link>
      <Link to="/admin/judging/judgingcriteria">
        <ListMenuItem
          icon={<Lens style={{ height: 14 }} />}
          primary="Judging Criteria"
        />
      </Link>
      <Link to="/admin/judging/judges">
        <ListMenuItem icon={<Lens style={{ height: 14 }} />} primary="Judges" />
      </Link>
    </ListSubMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      headerIcon={<SettingsIcon />}
      header="System Settings"
    >
      <Link to="/admin/system/users">
        <ListMenuItem
          icon={<AppIcon style={{ height: 14 }} />}
          primary="Users"
        />
      </Link>
      <Link to="/admin/system/awardyears">
        <ListMenuItem
          icon={<EmojiEvents style={{ height: 14 }} />}
          primary="Award Years"
        />
      </Link>
      <Link to="/admin/system/settings">
        <ListMenuItem
          icon={<SettingsIcon style={{ height: 14 }} />}
          primary=" Settings"
        />
      </Link>
    </ListSubMenu>
  </ListMenu>
);

export default DrawerMenu;
