import React from 'react';
import axios from 'axios';
import useLocation from '@cloudhub-ux/core/customhooks/useLocation';
import useAuthStore from './useAuthStore';
import CONFIG from './Config';
import authActions from './actions/authActions';

const getToken = () => {
  try {
    return localStorage.getItem('token');
  } catch (error) {
    return '';
  }
};

const useAuthentication = () => {
  const { dispatch, ...state } = useAuthStore((state) => state);
  const { isAuthenticated, currentUser, token, ...rest } = state;

  const { navigate } = useLocation();

  // const dispatchActions = useAuthActions({ dispatch, token, currentUser });

  const axiosinstance = React.useMemo(
    () => () =>
      axios.create({
        headers: {
          prxy: 'no-proxy',
          'Content-Type': 'application/json',
          authorization: `Bearer ${getToken()}`,
        },
      }),
    []
  );

  const dispatchActions = React.useMemo(
    () => authActions({ dispatch, navigate, axiosinstance }),
    // eslint-disable-next-line
    [axiosinstance, dispatch]
  );

  React.useEffect(() => {
    // if (
    //   isAuthenticated &&
    //   Array.isArray((currentUser || {}).UserType) &&
    //   currentUser.UserType.includes('Judge') &&
    //   !currentUser.UserType.includes('Admin')
    // ) {
    //   navigate('/judging');
    // } else if (
    //   isAuthenticated &&
    //   Array.isArray((currentUser || {}).UserType) &&
    //   currentUser.UserType.includes('Admin')
    // ) {
    //   navigate('/admin');
    //   // navigate('/entry/nominate');
    // } else if (
    //   isAuthenticated
    //   // &&
    //   // !(currentUser.UserType || []).includes('Admin')
    // ) {
    //   navigate('/');
    //   // navigate('/entry/myentries');
    // }
    // eslint-disable-next-line
  }, [isAuthenticated, JSON.stringify(currentUser)]);

  return {
    isAuthenticated,
    currentUser,
    dispatch,
    authActions: dispatchActions,
    token,
    CONFIG,
    axiosinstance,
    ...rest,
  };
};

export default useAuthentication;
