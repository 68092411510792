/* eslint-disable capitalized-comments */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';

import Block from '@cloudhub-ux/core/Block';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import ListMenuItem from '@cloudhub-ux/core/ListMenuItem';
import ActionsMenu from '@cloudhub-ux/core/ActionsMenu';
import Button from '@cloudhub-ux/core/Button';
import Alert from '@cloudhub-ux/core/Alert';
import useAdminAxios from 'admin/context/useAdminAxios';
import Menu from '@mui/icons-material/Menu';

import MessageForm from './forms/MessageForm';

// const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Message = () => {
  const { getData, adminaxiosinstance } = useAdminAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const deleteRows = async (deletingRows) => {
    const ids = deletingRows.map((item) => item.id);
    getData({ url: '/website/message/delete', params: { messages: ids } });
    gridRef.current.onDeleteSuccess(deletingRows);
  };

  const actionsComponent = ({ row }) => (
    <TableCell>
      <Block>
        <ActionsMenu
          anchorComponent={
            <Button>
              <Menu />
            </Button>
          }
        >
          <ListMenu>
            <ListMenuItem onClick={() => setEditingRow(row)}>View</ListMenuItem>
          </ListMenu>
        </ActionsMenu>
      </Block>
    </TableCell>
  );

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [
    { name: 'Fullname', title: 'Name' },
    { name: 'Email', title: 'Email' },
    { name: 'Phone', title: 'Phone' },
  ];

  const columnWidths = [
    { columnName: 'Fullname', width: 120 },
    { columnName: 'Email', width: 150 },
    { columnName: 'Phone', width: 150 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="Messages"
        url="/website/message/getall"
        axiosinstance={adminaxiosinstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        actionsComponent={actionsComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
        pagingComponent={null}
      />
      <MessageForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => gridRef.current.onSave(row)}
      />
    </Block>
  );
};

export default Message;
