import React from 'react';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';
import { colors, hexToRgb } from 'theme';
// import Collapse from '@mui/material/Collapse';
import JudgingLevelForm from '../forms/JudgingLevelForm';
import AssignmentForm from './AssignmentForm';

const JudgingLevel = () => {
  const [editingRow, seteditingRow] = React.useState(null);
  const [assigning, setassigning] = React.useState(false);
  const [progress, setprogress] = React.useState({ Percentage: 0 });
  const gridRef = React.useRef();
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const { postData, getData } = useAdminAxios({});
  const deleteRows = async (rows) => {
    try {
      const ids = rows.map((item) => item.id);
      const data = await postData({
        url: '/judging/judginglevel/delete',
        params: { levels: ids },
      });
      if (data) {
        gridRef.current.reload();
      }
    } catch (error) {}
  };

  const getProgress = React.useCallback(async () => {
    try {
      const data = await getData({
        url: '/judging/judgingresult/progress',
      });
      if (data && data.Assignments) {
        const Percentage = ((data.Judged || 0) / (data.Assignments || 1)) * 100;
        setprogress({ ...data, Percentage: Percentage || 0 });
      }
    } catch (error) {}

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getProgress();
  }, [getProgress]);

  const progressColor = (progress) => {
    if (progress < 50) {
      return colors.red;
    }
    if (progress < 75) {
      return colors.orange;
    }
    if (progress < 100) {
      return colors.success;
    }
    return colors.green;
  };
  const renderHeader = ({
    allowprint,
    onSearch,
    onRefresh,
    onAdd,
    onPrint,
    title,
  }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={() => onAdd()}>
            <AddIcon /> Add
          </Button>
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
          <Button onClick={() => onPrint()} disabled={!allowprint}>
            <PrintIcon /> Print
          </Button>
        </Block>
      </Block>
      {/* <Collapse
        addEndListener={() => {
          if (typeof progress.Assignments !== 'number') {
            getProgress();
          }
        }}
      > */}
      <Block
        card
        center
        middle
        flex={false}
        style={{
          border: `1px solid ${progressColor(progress.Percentage)}`,
          background: `linear-gradient(90deg, rgb(${hexToRgb(
            progressColor(progress.Percentage)
          )}, ${
            progress.Percentage === 0 || progress.Percentage === 100 ? 1 : 0.2
          }) ${progress.Percentage}%, ${colors.milkyWhite}  ${
            100 - progress.Percentage
          }%)`,
        }}
      >
        <Text>
          {`${progress.Percentage.toFixed(2)}% of ${
            progress.Assignments
          } Assignments Completed`}
        </Text>
      </Block>
      {/* </Collapse> */}
      <Block />
    </Block>
  );

  const renderTemplates = ({ row, column }) => (
    // row, column, style
    <TableCell>
      <Text bold>{row[column.name] || ''}</Text>
    </TableCell>
  );
  const cols = [
    { name: 'Name', title: 'Level Name' },
    { name: 'Description', title: 'Description' },
  ];

  const columnWidths = [
    { columnName: 'Name', width: 150 },
    { columnName: 'Description', width: 500 },
  ];

  return (
    <Block>
      {!editingRow && (
        <RemoteDataGrid
          title={<Text h2>Judging Levels</Text>}
          columns={cols}
          columnWidths={columnWidths}
          allowColumnResizing={false}
          header={renderHeader}
          url="/judging/judginglevel/getall"
          axiosinstance={adminaxiosinstance}
          cellComponent={renderTemplates}
          onAdd={() => seteditingRow({})}
          onDeleteRows={(row) => deleteRows(row)}
          onEdit={seteditingRow}
          onView={(row) => {
            setassigning(true);
            seteditingRow(row);
          }}
          ref={gridRef}
          pagingComponent={null}
        />
      )}
      {editingRow && !assigning && (
        <JudgingLevelForm
          editingRow={editingRow}
          onCancelEdit={() => seteditingRow(null)}
          onSave={() => {
            seteditingRow(null);
            gridRef.current.reload();
          }}
        />
      )}
      {editingRow && assigning && (
        <AssignmentForm
          editingRow={editingRow}
          onCancelEdit={() => {
            seteditingRow(null);
            setassigning(false);
          }}
          onSave={() => {
            seteditingRow(null);
            gridRef.current.reload();
          }}
        />
      )}
    </Block>
  );
};

export default JudgingLevel;
