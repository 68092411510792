import React, { useState, useRef } from 'react';
import TableCell from '@mui/material/TableCell';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
// import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';
import VoteForm from './forms/VoteForm';
import SendSmsForm from '../forms/SendSmsForm';

const Vote = () => {
  const gridRef = useRef();
  const [phonenumbers, setphonenumbers] = useState([]);
  const [smsDialogOpen, openSmsDialog] = useState(false);
  const [dataarray, setdataarray] = useState([]);
  const [editingRow, seteditingRow] = React.useState(null);

  // const { postData } = useAdminAxios({});
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const headers = [
    { label: 'Station', key: 'MediastationName' },
    { label: 'Code', key: 'VotingCode' },
    { label: 'Phone', key: 'Phone' },
    { label: 'Date', key: 'TimeStamp' },
    { label: 'Method', key: 'Channel' },
  ];

  const onChangeSelection = (selection) => {
    const numbers = [];
    if (Array.isArray(selection)) {
      selection.map((s) => {
        if (
          s.ContactPerson &&
          s.ContactPerson.Phone &&
          numbers.indexOf(s.ContactPerson.Phone) === -1
        ) {
          numbers.push(s.ContactPerson.Phone);
        }
        return null;
      });
    }
    const entrieswithdates = [...(selection || [])].map((entry) => ({
      ...entry,
      Date: dayjs(entry.updatedAt).format('DD MMM, YYYY hh:mm a'),
      Gender: entry.ContactPerson ? entry.ContactPerson.Gender : '',
      County: entry.Project ? entry.Project.County : '',
      Theme: entry.Project ? entry.Project.Theme : '',
    }));

    setphonenumbers(numbers);
    setdataarray(entrieswithdates);
    gridRef.current.reload();
  };
  const onOpenSmsDialog = () => {
    openSmsDialog(true);
  };

  const deleteRows = async () => {
    try {
      // const ids = rows.map((item) => item.id);
      // const data = await postData({
      //   url: '/entry/nomination/delete',
      //   params: { entries: ids },
      // });
      // if (data) {
      //   gridRef.current.reload();
      // }
    } catch (error) {}
  };

  const renderHeader = ({ onSearch, onRefresh, title }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={onOpenSmsDialog} disabled={!phonenumbers}>
            <SendIcon /> Send SMS to Selected
          </Button>
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
          <CSVLink data={dataarray} headers={headers}>
            <Button>
              <ListAltIcon /> Export Selected to Excel
            </Button>
          </CSVLink>
        </Block>
      </Block>
      <Block />
    </Block>
  );

  const renderTemplates = ({ row, column }) => {
    // row, column, style
    if (column.name === 'TimeStamp') {
      return (
        <TableCell>
          <Text bold>
            {dayjs(row.TimeStamp || row.createdAt).format(
              'DD MMM, YYYY hh:mm a'
            )}
          </Text>
        </TableCell>
      );
    }
    return (
      <TableCell>
        <Text bold>{row[column.name] || ''}</Text>
      </TableCell>
    );
  };

  const cols = [
    { name: 'MediastationName', title: 'Station' },
    { name: 'VotingCode', title: 'Code' },
    { name: 'Phone', title: 'Phone' },
    { name: 'Channel', title: 'Voting Method' },
    { name: 'TimeStamp', title: 'Date' },
  ];

  const columnWidths = [
    { columnName: 'MediastationName', width: 150 },
    { columnName: 'VotingCode', width: 70 },
    { columnName: 'Phone', width: 150 },
    { columnName: 'Channel', width: 100 },
    { columnName: 'UserId', width: 200 },
    { columnName: 'updatedAt', width: 70 },
  ];

  return (
    <Block>
      {!editingRow ? (
        <Block>
          <RemoteDataGrid
            title="Votes"
            columns={cols}
            columnWidths={columnWidths}
            allowColumnResizing={false}
            header={renderHeader}
            url="/entry/vote/getall"
            axiosinstance={adminaxiosinstance}
            cellComponent={renderTemplates}
            onEdit={seteditingRow}
            onDeleteRows={deleteRows}
            onChangeSelection={onChangeSelection}
            ref={gridRef}
            pagingComponent={null}
          />
          <SendSmsForm
            numbers={phonenumbers}
            open={smsDialogOpen}
            onClose={() => openSmsDialog(false)}
          />
        </Block>
      ) : (
        <VoteForm
          editTitle={(editingRow || {}).EntryName}
          currentEntry={editingRow}
          onCancelEdit={() => seteditingRow(null)}
          editingRow={editingRow}
        />
      )}
    </Block>
  );
};

export default Vote;
