import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import Block from '@cloudhub-ux/core/Block';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import { Form, Field } from '@cloudhub-ux/core/form';
import toastr from '@cloudhub-ux/core/toastr';
import { DraftEditor } from '@cloudhub-ux/core/editors';
// import AdminContext from 'admin/context/AdminContext';
import useAdminAxios from 'admin/context/useAdminAxios';
import { sizes } from 'theme';

const HomePageMessagesForm = () => {
  const [messages, setMessages] = React.useState({});
  // const { adminaxiosinstance, ADMINCONFIG } = React.useContext(AdminContext);
  const { data, postData } = useAdminAxios({
    url: '/system/settings/getmessages',
  });

  React.useEffect(() => {
    if (data && typeof data === 'object') {
      setMessages(data);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(data)]);

  const saveMessages = async (values) => {
    try {
      const data = await postData({
        url: '/system/settings/updatemessages',
        params: { ...values },
        successMessage: 'Messages Updated',
      });
      setMessages(data);
      toastr.success('Emails updated');
    } catch (error) {
      setMessages({});
    }
  };

  return (
    <Form
      onSubmit={saveMessages}
      initialValues={{ ...messages }}
      render={({ handleSubmit, submitting, pristine }) => (
        <Block>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Button onClick={handleSubmit} disabled={submitting || pristine}>
              <SaveIcon /> Save
            </Button>
          </div>
          <fieldset
            className="scheduler-border"
            style={{ padding: sizes.padding }}
          >
            <legend className="scheduler-border">Message 1</legend>

            <FieldBlock>
              <label>Message Title</label>
              <Field
                type="text"
                name="M1.Title"
                marginRight={0}
                component={Input}
              />
            </FieldBlock>

            <FieldBlock>
              <Field name="M1.Body" component={DraftEditor} height={400} />
            </FieldBlock>
          </fieldset>
          <fieldset
            className="scheduler-border"
            style={{ padding: sizes.padding }}
          >
            <legend className="scheduler-border">Message 2</legend>
            <FieldBlock>
              <label>Message Title</label>
              <Field
                type="text"
                name="M2.Title"
                marginRight={0}
                component={Input}
              />
            </FieldBlock>
            <FieldBlock>
              <Field name="M2.Body" component={DraftEditor} height={400} />
            </FieldBlock>
          </fieldset>
          <fieldset
            className="scheduler-border"
            style={{ padding: sizes.padding }}
          >
            <legend className="scheduler-border">Message 3</legend>
            <FieldBlock>
              <label>Message Title</label>
              <Field
                type="text"
                name="M3.Title"
                marginRight={0}
                component={Input}
              />
            </FieldBlock>
            <FieldBlock>
              <Field name="M3.Body" component={DraftEditor} height={400} />
            </FieldBlock>
          </fieldset>
          <fieldset
            className="scheduler-border"
            style={{ padding: sizes.padding }}
          >
            <legend className="scheduler-border">Message 4</legend>
            <FieldBlock>
              <label>Message Title</label>
              <Field
                type="text"
                name="M4.Title"
                marginRight={0}
                component={Input}
              />
            </FieldBlock>
            <FieldBlock>
              <Field name="M4.Body" component={DraftEditor} height={400} />
            </FieldBlock>
          </fieldset>
        </Block>
      )}
    />
  );
};

export default HomePageMessagesForm;
