import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Text from '@cloudhub-ux/core/Text';
import Fab from '@mui/material/Fab';
import { colors, sizes, hexToRgb } from 'theme';
import toastr from '@cloudhub-ux/core/toastr';
import useAdminAxios from 'admin/context/useAdminAxios';
import ConfirmDialog from './ConfirmDialog';

const LevelCategories = ({
  entries = [],
  selectCategory = () => {},
  activejudge,
  activecategory,
  getEntries = () => {},
  setactivejudge = () => {},
  getJudges = () => {},
}) => {
  const [categories, setcategories] = React.useState([]);
  const [removing, setremoving] = React.useState(null);
  const [unassign, setunassign] = React.useState(null);
  const { postData, error, reset } = useAdminAxios({});

  React.useEffect(() => {
    if (error) {
      toastr.error(error);
      setTimeout(() => {
        reset();
      });
    }
    // eslint-disable-next-line
  }, [error]);

  React.useEffect(() => {
    const cats = [];
    entries.forEach((entry) => {
      const ind = cats.findIndex(
        (cat) =>
          entry &&
          entry.Category &&
          cat &&
          cat.CategoryCode === entry.Category.CategoryCode &&
          cat.StoryFormat === entry.StoryFormat
      );
      if (ind === -1) {
        cats.push({
          CategoryName: (entry.Category || {}).CategoryName,
          CategoryCode: (entry.Category || {}).CategoryCode,
          StoryFormat: entry.StoryFormat,
          Entries: 1,
        });
      } else {
        cats[ind].Entries += 1;
      }
    });
    setcategories(cats);
    // eslint-disable-next-line
  }, [JSON.stringify(activejudge), JSON.stringify(entries)]);

  const judgeEntries = (catcode, storyformat) => {
    const catentries = entries
      .map((entry) => {
        if (
          entry &&
          entry.Category &&
          entry.Category.CategoryCode === catcode &&
          entry.StoryFormat === storyformat
        ) {
          if (activejudge) {
            const judgeind = (entry.JudgingResults || []).findIndex(
              (result) =>
                activejudge && result && result.JudgeId === activejudge.JudgeId
            );
            if (judgeind !== -1) {
              if (entry.JudgingResults[judgeind].Final) {
                return 1;
              }
              return 0;
            }
            return null;
          }
          const entrypoints = (entry.JudgingResults || []).map((result) =>
            result && result.Final ? 1 : 0
          );
          const entryprogress =
            entrypoints.reduce((a, b) => a + b, 0) / (entrypoints.length || 1);
          return entryprogress;
        }
        return null;
      })
      .filter((elem) => typeof elem === 'number');
    return {
      Entries: catentries.length,
      Progress:
        catentries.reduce((a, b) => a + b, 0) / (catentries.length || 1),
    };
  };

  const assignEntries = async (catcode, storyformat) => {
    if (catcode && storyformat && activejudge) {
      const assigned = entries
        .map((entry) => {
          if (
            entry &&
            entry.Category &&
            entry.Category.CategoryCode === catcode &&
            entry.StoryFormat === storyformat
          ) {
            return {
              EntryName: entry.EntryName,
              id: entry.id,
              _id: entry._id,
              _ref: entry._id,
            };
          }
          return null;
        })
        .filter(Boolean);

      try {
        const data = await postData({
          url: '/judging/judge/assignentries',
          params: {
            JudgeId: activejudge.id,
            JudgeName: activejudge.JudgeName,
            entries: assigned,
          },
        });
        if (data) {
          getJudges();
          getEntries();
        }
      } catch (error) {}
    }
  };

  const removeEntries = async () => {
    if (removing && removing.CategoryCode && removing.StoryFormat) {
      const removed = entries
        .map((entry) => {
          if (
            entry &&
            entry.Category &&
            entry.Category.CategoryCode === removing.CategoryCode &&
            entry.StoryFormat === removing.StoryFormat
          ) {
            return entry.id;
          }
          return null;
        })
        .filter(Boolean);
      try {
        const data = await postData({
          url: '/judging/judgingresult/deleteentries',
          params: {
            ids: removed,
          },
        });
        if (data) {
          setremoving(null);
          toastr.success('Entries removed successfully');
          getJudges();
          getEntries();
        }
      } catch (error) {}
    }
  };

  const unassignEntries = async () => {
    try {
      if (unassign && unassign.CategoryCode && unassign.StoryFormat) {
        const unassigned = entries
          .map((entry) => {
            if (
              entry &&
              entry.Category &&
              entry.Category.CategoryCode === unassign.CategoryCode &&
              entry.StoryFormat === unassign.StoryFormat
            ) {
              return entry.id;
            }
            return null;
          })
          .filter(Boolean);
        try {
          const data = await postData({
            url: '/judging/judge/unassignentries',
            params: {
              judge: activejudge.id,
              entries: unassigned,
            },
          });
          if (data) {
            toastr.success('Entries unassigned successfully');
            setunassign(null);
            setactivejudge(null);
            getJudges();
            getEntries();
          }
        } catch (error) {}
      }
    } catch (error) {}
  };

  return (
    <Block row wrap style={{ minWidth: '100%' }}>
      {categories.map((cat) => {
        const { Entries, Progress } = judgeEntries(
          cat.CategoryCode,
          cat.StoryFormat
        );
        const hasStrength = () => {
          if (activejudge && Array.isArray(activejudge.Strengths)) {
            const ind = activejudge.Strengths.findIndex(
              (strength) =>
                ((strength || {}).Category || {}).CategoryCode ===
                  cat.CategoryCode &&
                (strength || {}).StoryFormat === cat.StoryFormat
            );
            return ind !== -1;
          }
        };
        if (
          !activecategory ||
          (activecategory &&
            activecategory.CategoryCode === cat.CategoryCode &&
            activecategory.StoryFormat === cat.StoryFormat)
        ) {
          return (
            <Block
              key={`${cat.CategoryCode}${cat.StoryFormat}`}
              middle
              flex={false}
              padding={10}
              margin={10}
              style={{
                width: 300,
                cursor: 'pointer',
                boxShadow: hasStrength()
                  ? `5px 5px 5px  rgba(${hexToRgb(colors.primary)}, 0.8)`
                  : `6px 2px 5px ${colors.gray2}`,
              }}
              paper
              color={
                !activejudge || (activejudge && Entries === 0)
                  ? 'white'
                  : `rgb(${hexToRgb(colors.success)}, ${
                      (activecategory || {}).CategoryCode ===
                        cat.CategoryCode &&
                      activecategory.StoryFormat &&
                      activejudge &&
                      Entries > 0
                        ? 1
                        : 0.3
                    })`
              }
            >
              <Block
                space="between"
                style={{ cursor: 'pointer', overflow: 'hidden' }}
                onClick={() =>
                  selectCategory({
                    CategoryCode: cat.CategoryCode,
                    StoryFormat: cat.StoryFormat,
                  })
                }
              >
                <Text h5 bold>
                  {cat.CategoryName} Category - {cat.StoryFormat}
                </Text>
              </Block>

              <Block>
                <Text>{`Entries: ${cat.Entries}`}</Text>
                <Block>
                  <Text>{`Progress: ${(Progress * 100).toFixed(2)}%`}</Text>
                </Block>
              </Block>
              <Block row right>
                {activejudge && (
                  <Button
                    small
                    onClick={() =>
                      Entries === 0
                        ? assignEntries(cat.CategoryCode, cat.StoryFormat)
                        : setunassign(cat)
                    }
                    color={colors.success}
                    contained
                  >
                    <Text white>{Entries > 0 ? 'Unassign' : 'Assign'}</Text>
                  </Button>
                )}
                <Button
                  small
                  onClick={() => setremoving(cat)}
                  color={colors.error}
                  contained
                  style={{ marginLeft: sizes.smallMargin }}
                >
                  <Text white>Remove</Text>
                </Button>
              </Block>
            </Block>
          );
        }
        return null;
      })}
      {activecategory && (
        <Fab
          onClick={() => selectCategory(null)}
          variant="extended"
          color="primary"
          style={{
            position: 'absolute',
            right: sizes.section,
            top: sizes.section,
            whiteSpace: 'nowrap',
            color: colors.white,
          }}
        >
          All Categories
        </Fab>
      )}
      <ConfirmDialog
        open={unassign !== null || removing !== null}
        onCancel={() => {
          setunassign(null);
          setremoving(null);
        }}
        onConfirm={() => (unassign ? unassignEntries() : removeEntries())}
        text={
          unassign
            ? `Unassign ${unassign.CategoryName || ''} -${
                unassign.StoryFormat
              } from ${activejudge.JudgeName}?`
            : `Remove ${(removing || {}).CategoryName || ''} -${
                (removing || {}).StoryFormat
              } entries from Judging Level?`
        }
        confirmText={unassign ? 'Unassign' : 'Remove'}
      />
    </Block>
  );
};

export default LevelCategories;
