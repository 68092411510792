import React from 'react';
import { makeStyles } from '@mui/styles';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import IconButton from '@cloudhub-ux/core/IconButton';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Divider from '@cloudhub-ux/core/Divider';
import { sizes } from 'theme';

const getStyles = ({ drawerWidth }) => {
  const useStyles = makeStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
      borderWidth: 0,
      marginRight: 1,
    },
    drawerContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
  });

  return {
    useStyles,
  };
};

const Drawer = ({ drawerWidth, open, onClose = () => null, ...props }) => {
  const classes = getStyles({ drawerWidth }).useStyles();

  return (
    <MuiDrawer
      variant="persistent"
      classes={{
        root: classes.root,
        paper: classes.drawerPaper,
      }}
      open={open}
    >
      <Block row middle flex={false}>
        <Block row middle center style={{ height: sizes.adminNavBarHeight }}>
          <Block flex={false}>
            <Text header>AJEA MIS</Text>
            <Text subHeader>Admin Panel</Text>
          </Block>
        </Block>
        <Block flex={false}>
          <IconButton style={{ alignItems: 'center' }} onClick={onClose}>
            <ChevronLeft />
          </IconButton>
        </Block>
      </Block>
      <Divider />
      <Block>
        <Block style={{ position: 'relative' }}>
          <Block absolute style={{ overflowY: 'auto' }}>
            {props.children}
          </Block>
        </Block>
        <Block flex={false} shadow middle center>
          <Text small facebook>
            Developed By Cloud Hub
          </Text>
        </Block>
      </Block>
    </MuiDrawer>
  );
};

Drawer.defaultProps = {
  drawerWidth: 270,
};

export default Drawer;
