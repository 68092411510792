import React from 'react';
import Button from '@cloudhub-ux/core/Button';
import Text from '@cloudhub-ux/core/Text';
import Block from '@cloudhub-ux/core/Block';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import { Form, Field } from '@cloudhub-ux/core/form';
import toastr from '@cloudhub-ux/core/toastr';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Fab from '@mui/material/Fab';
import Close from '@mui/icons-material/Close';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { cloudUrl } from 'localcomponents/appconstants';
import dayjs from 'dayjs';
import StaticListSelector from '@cloudhub-ux/core/selectors/StaticListSelector';
import FieldButton from '@cloudhub-ux/core/FieldButton';
import Download from '@mui/icons-material/Download';

const EntryView = ({ onCancelEdit, currentEntry }) => {
  const [owner, setowner] = React.useState({});
  const { data, postData, error } = useAdminAxios({
    url: '/system/user/getuser',
    params: { id: (currentEntry || {}).Owner },
  });

  const updateEntry = async (entry) => {
    try {
      const data = await postData({
        url: '/entry/entry/updateentry',
        params: entry,
      });
      if (data) {
        toastr.success('Entry updated');
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (data) {
      setowner(data);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(data)]);

  React.useEffect(() => {
    if (error) {
      toastr.error(error);
    }
  }, [error]);

  return (
    <Block absolute row margin={30}>
      {currentEntry && (
        <Block style={{ overflow: 'scroll' }}>
          <Text h2>{currentEntry.Title || ''}</Text>

          <Block>
            <Block paper margin={[30, 0]}>
              <Text h3 primary style={{ textAlign: 'center' }}>
                SECTION A: PERSONAL INFORMATION
              </Text>
            </Block>
            <Block paper margin={[30, 30]}>
              <Block>
                <Table style={{ minWidth: '50%' }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Ref. No.
                      </TableCell>
                      <TableCell align="left">
                        <Text bold>{currentEntry.RefNo}</Text>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Submitted By:
                      </TableCell>
                      <TableCell align="left">
                        <Text bold>
                          {`Name: ${owner.Name || ''} Email: ${
                            owner.Email || ''
                          } Type of User: ${(owner.UserType || []).join(', ')}`}
                        </Text>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Block>
              <Text header>CATEGORY </Text>
              <Block>
                <Table style={{ minWidth: '50%' }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Category
                      </TableCell>
                      <TableCell align="left">
                        <Form
                          onSubmit={updateEntry}
                          initialValues={{
                            Category: currentEntry.Category,
                            id: currentEntry.id,
                          }}
                          render={({ pristine, submitting, handleSubmit }) => (
                            <FieldBlock row flex>
                              <Field
                                type="text"
                                name="Category"
                                component={StaticListSelector}
                                options={[
                                  {
                                    CategoryName: 'Best MCK Grantee',
                                    CategoryCode: 'best_mck_grantee',
                                  },
                                ]}
                                valueExtractor={(row) => row}
                                labelExtractor={(row) =>
                                  row && row.CategoryName
                                    ? row.CategoryName
                                    : `${row}`
                                }
                                required
                                readOnly
                                flex
                              />
                              <FieldButton
                                color={colors.primary}
                                onClick={handleSubmit}
                                disabled={pristine || submitting}
                              >
                                Update
                              </FieldButton>
                            </FieldBlock>
                          )}
                        />
                        {/* <Text bold>
                          {(currentEntry.Category || {}).CategoryName || ''}
                        </Text> */}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Story Format
                      </TableCell>
                      <TableCell>
                        <Form
                          onSubmit={updateEntry}
                          initialValues={{
                            StoryFormat: currentEntry.StoryFormat,
                            id: currentEntry.id,
                          }}
                          render={({ pristine, submitting, handleSubmit }) => (
                            <FieldBlock row flex>
                              <Field
                                type="text"
                                name="StoryFormat"
                                component={StaticListSelector}
                                options={['TV', 'Radio', 'Print', 'Digital']}
                                disabled={Boolean(currentEntry.StoryFormat)}
                                required
                                readOnly
                                flex
                              />
                              <FieldButton
                                color={colors.primary}
                                onClick={handleSubmit}
                                disabled={pristine || submitting}
                              >
                                Update
                              </FieldButton>
                            </FieldBlock>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Block>
            </Block>
            <Block paper margin={[30, 30]}>
              {currentEntry.Surname && (
                <Block>
                  <Table style={{ minWidth: '50%' }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Full Name
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>
                            {`${currentEntry.Surname} ${
                              currentEntry.FirstName
                            } ${currentEntry.MiddleName || ''}`}
                          </Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          County
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>{currentEntry.County || ''}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Gender
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>{currentEntry.Gender}</Text>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Email Address
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>{currentEntry.Email}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Mobile Phone
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>{currentEntry.Phone}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Passport Photo
                        </TableCell>
                        <TableCell align="left">
                          {((currentEntry.Picture &&
                            currentEntry.Picture[0] &&
                            currentEntry.Picture[0].Location) ||
                            (currentEntry.Picture &&
                              currentEntry.Picture.Location)) && (
                            <Block style={{ minHeight: 300, maxWidth: 500 }}>
                              <img
                                src={
                                  ((currentEntry.Picture || [])[0] || {})
                                    .Location ||
                                  (currentEntry.Picture || {}).Location
                                }
                                alt="passport"
                              />
                            </Block>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Block>
              )}

              {currentEntry.MediaCard && currentEntry.MediaCard.Code && (
                <Block padding={[sizes.padding * 2, sizes.padding]}>
                  <Text h3 primary style={{ textAlign: 'center' }}>
                    MCK Accreditation Card
                  </Text>
                  <Block>
                    <Table
                      style={{ minWidth: '50%' }}
                      aria-label="simple table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Code
                          </TableCell>
                          <TableCell align="left">
                            <Text bold>{currentEntry.MediaCard.Code}</Text>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Name:
                          </TableCell>
                          <TableCell align="left">
                            <Text bold>{currentEntry.MediaCard.Name}</Text>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Status:
                          </TableCell>
                          <TableCell align="left">
                            <Text bold>{currentEntry.MediaCard.Status}</Text>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Expiry:
                          </TableCell>
                          <TableCell align="left">
                            <Text bold>
                              {currentEntry.MediaCard.Exp ||
                                currentEntry.MediaCard.Expiry}
                            </Text>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Block>
                </Block>
              )}
            </Block>

            <Block paper margin={[30, 0]}>
              <Text h3 primary style={{ textAlign: 'center' }}>
                SECTION B: ENTRY INFORMATION
              </Text>
              <Block paper margin={[30, 30]}>
                <Block>
                  <Table style={{ minWidth: '50%' }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Type of Entry
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>{currentEntry.EntryType || ''}</Text>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Title
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>{`${currentEntry.Title}`}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Date Published
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>
                            {typeof currentEntry.DatePublished === 'number'
                              ? dayjs(currentEntry.DatePublished || 0).format(
                                  'DD MMM, YYYY'
                                )
                              : currentEntry.DatePublished}
                          </Text>
                        </TableCell>
                      </TableRow>
                      {Array.isArray(currentEntry.TeamMemberNames) &&
                        currentEntry.EntryType === 'Joint Story' && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Team Members Names
                            </TableCell>
                            <TableCell align="left">
                              <Text bold>
                                {currentEntry.TeamMemberNames.map((member) =>
                                  typeof member === 'string'
                                    ? member
                                    : `Name: ${
                                        (member || {}).Name || ''
                                      }\n Phone: ${
                                        (member || {}).Phone || ''
                                      }\nEmail: ${(member || {}).Email || ''}\n`
                                ).join('\n')}
                              </Text>
                            </TableCell>
                          </TableRow>
                        )}

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Language Published in
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>{currentEntry.Language || ''}</Text>
                        </TableCell>
                      </TableRow>

                      {((currentEntry.Translation || {}).Location ||
                        ((currentEntry.Translation || [])[0] || {})
                          .Location) && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Translation
                          </TableCell>
                          <TableCell align="left">
                            <a
                              href={`${
                                (currentEntry.Translation || {}).Location ||
                                ((currentEntry.Translation || [])[0] || {})
                                  .Location
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button contained>
                                <Download />
                                Download Translation
                              </Button>
                            </a>
                          </TableCell>
                        </TableRow>
                      )}

                      {((currentEntry.Script || {}).Location ||
                        ((currentEntry.Script || [])[0] || {}).Location) && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Script
                          </TableCell>
                          <TableCell align="left">
                            <a
                              href={`${
                                (currentEntry.Script || {}).Location ||
                                ((currentEntry.Script || [])[0] || {}).Location
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button contained>
                                <Download />
                                Download Script
                              </Button>
                            </a>
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Nationality
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>{currentEntry.Nationality || ''}</Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Freelancer
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>
                            {currentEntry.Freelance ? 'Yes' : 'No'}
                          </Text>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Nme of publication/Station/Website
                        </TableCell>
                        <TableCell align="left">
                          <Text bold>
                            {(currentEntry.MediaOrganization || {})
                              .MediahouseName ||
                              `${
                                typeof currentEntry.MediaOrganization ===
                                'string'
                                  ? currentEntry.MediaOrganization
                                  : ''
                              }`}
                          </Text>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Block>
              </Block>
            </Block>
            {currentEntry.Narration && (
              <Block paper margin={[30, 0]}>
                <Text h3 bold primary style={{ textAlign: 'center' }}>
                  Narration
                </Text>
                <Text style={{ padding: sizes.padding }}>
                  {currentEntry.Narration || ''}
                </Text>
              </Block>
            )}
            {currentEntry.Synopsis && (
              <Block paper margin={[30, 0]}>
                <Text h3 bold primary style={{ textAlign: 'center' }}>
                  Synopsis
                </Text>
                <Text style={{ padding: sizes.padding }}>
                  {currentEntry.Synopsis || ''}
                </Text>
              </Block>
            )}
            <Block paper margin={[30, 0]}>
              <Text h3 primary style={{ textAlign: 'center' }}>
                SECTION C: FILES
              </Text>
              <Block paper margin={[30, 30]}>
                <Block>
                  {Array.isArray(currentEntry.PrintPdf) &&
                    currentEntry.PrintPdf.map(
                      (doc) =>
                        doc &&
                        doc.Location && (
                          <Block
                            padding={[sizes.padding * 2, sizes.padding]}
                            style={{ minHeight: '100vh' }}
                            key={doc.Location}
                          >
                            <Text h3 primary style={{ textAlign: 'center' }}>
                              Print Media PDF Scan
                            </Text>
                            <iframe
                              title={currentEntry.Surname}
                              src={`${cloudUrl}${doc.fd}`}
                              width="100%"
                              height="100%"
                            />
                          </Block>
                        )
                    )}
                  {currentEntry &&
                    currentEntry.ContentLink &&
                    currentEntry.ContentLink.href && (
                      <Block flex={false} center>
                        <a
                          href={currentEntry.ContentLink.href}
                          target="_blank"
                          rel="noreferrer noopener"
                          style={{
                            width: '100%',
                            padding: sizes.padding,
                            marginTop: sizes.doubleBaseMargin,
                          }}
                        >
                          <Button
                            contained
                            color={colors.primary}
                            style={{ width: '100%' }}
                          >
                            Go to Entry{' '}
                            {currentEntry.ContentLink.Type === 'Print'
                              ? 'Article'
                              : currentEntry.ContentLink.Type}
                          </Button>
                        </a>
                      </Block>
                    )}
                  {Array.isArray(currentEntry.PrintImage) &&
                    currentEntry.PrintImage.map(
                      (clip) =>
                        clip &&
                        clip.Location && (
                          <Block
                            padding={[sizes.padding * 2, sizes.padding]}
                            key={clip.Location}
                          >
                            <Text h3 primary style={{ textAlign: 'center' }}>
                              Print Media Scan Image
                            </Text>
                            <img
                              style={{ minHeight: 100 }}
                              src={`${cloudUrl}${clip.fd}`}
                              alt="media-card"
                            />
                          </Block>
                        )
                    )}
                  {currentEntry.PrintImage && currentEntry.PrintImage.Location && (
                    <Block padding={[sizes.padding * 2, sizes.padding]}>
                      <Text h3 primary style={{ textAlign: 'center' }}>
                        Print Media Scan Image
                      </Text>
                      <img
                        style={{ minHeight: 100 }}
                        src={`${cloudUrl}${currentEntry.PrintImage.fd}`}
                        alt="media-card"
                      />
                    </Block>
                  )}
                  {Array.isArray(currentEntry.RadioClip) &&
                    currentEntry.RadioClip.map(
                      (clip) =>
                        clip &&
                        clip.Location && (
                          <Block
                            padding={[sizes.padding * 2, sizes.padding]}
                            key={clip.Location}
                          >
                            <Text h3 primary style={{ textAlign: 'center' }}>
                              Radio Clip
                            </Text>
                            <audio
                              controls
                              style={{ width: '100%' }}
                              src={`${cloudUrl}${clip.fd}`}
                            />
                          </Block>
                        )
                    )}
                  {Array.isArray(currentEntry.VideoClip) &&
                    currentEntry.VideoClip.map(
                      (clip) =>
                        clip &&
                        clip.Location && (
                          <Block
                            padding={[sizes.padding * 2, sizes.padding]}
                            key={clip.Location}
                          >
                            <Text h3 primary style={{ textAlign: 'center' }}>
                              Video Clip
                            </Text>
                            <video controls src={`${cloudUrl}${clip.fd}`} />
                          </Block>
                        )
                    )}
                </Block>
              </Block>
            </Block>
          </Block>
        </Block>
      )}
      <Fab
        onClick={onCancelEdit}
        color="secondary"
        style={{ position: 'absolute', top: 10, right: 10 }}
      >
        <Close />
      </Fab>
    </Block>
  );
};
export default EntryView;
