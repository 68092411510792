import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@cloudhub-ux/core/Avatar';
import Text from '@cloudhub-ux/core/Text';
import Block from '@cloudhub-ux/core/Block';
import IconButton from '@cloudhub-ux/core/IconButton';
import Close from '@mui/icons-material/Close';
import { colors, hexToRgb } from 'theme';

const LevelJudge = ({
  index,
  activejudge,
  selectJudge = () => {},
  entries = [],
  j,
  setdeletingjudge = () => {},
  activeentry = {},
  activecategory,
}) => {
  const isJudgeHighlighted = (entries) => {
    if (activeentry) {
      const ind = [...(entries || [])].findIndex(
        ({ id }) => id === activeentry.id
      );
      return ind !== -1;
    }

    return false;
  };
  const judgeProgress = (judge) => {
    let progress = 0;
    const hasentry = [...(entries || [])]
      .map((entry) => {
        const ind = [...(entry.JudgingResults || [])].findIndex(
          ({ JudgeId }) => {
            if (activecategory) {
              return (
                judge === JudgeId &&
                (entry.Category || {}).CategoryCode ===
                  activecategory.CategoryCode &&
                entry.StoryFormat === activecategory.StoryFormat
              );
            }
            return judge === JudgeId;
          }
        );
        if (ind !== -1) {
          return {
            judged:
              Object.keys((entry.JudgingResults[ind] || {}).Points).length >
                0 && (entry.JudgingResults[ind] || {}).Final,
          };
        }
        return null;
      })
      .filter(Boolean);
    const hasjudged = hasentry.filter((item) => item.judged === true);
    progress = (hasjudged.length / hasentry.length) * 100;
    return progress;
  };
  return (
    <ListItem
      key={j.id}
      style={{
        cursor: 'pointer',
        backgroundColor:
          isJudgeHighlighted(j.Entries, j) ||
          (activejudge && activejudge.id === j.id)
            ? `rgb(${hexToRgb(colors.success)}, 0.3)`
            : 'white',
      }}
      onClick={() => selectJudge(j)}
    >
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: colors.dark }}>
          <Text h2>{index + 1}</Text>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Block flex={false}>
            <Text h4 bold>
              {j.JudgeName}
            </Text>

            <Text small>
              {`Entries: ${
                j.Entries && Array.isArray(j.Entries) ? j.Entries.length : 0
              }`}
            </Text>
            <Text small>
              Progress:{' '}
              <Text
                color={
                  Number(judgeProgress(j.JudgeId) || 0) < 50
                    ? colors.red
                    : Number(judgeProgress(j.JudgeId) || 0) < 100
                    ? colors.dark
                    : colors.success
                }
              >
                {`${Number(judgeProgress(j.JudgeId) || 0).toFixed(2)}%`}
              </Text>
            </Text>
          </Block>
        }
      />
      <ListItemAvatar>
        <Avatar style={{ backgroundColor: colors.dark }}>
          <IconButton color={colors.danger} onClick={() => setdeletingjudge(j)}>
            <Close style={{ color: colors.milkyWhite }} />
          </IconButton>
        </Avatar>
      </ListItemAvatar>
    </ListItem>
  );
};

export default LevelJudge;
