import AuthContext from 'auth/context/AuthContext';
import React from 'react';
import NotAuthorized from './NotAuthorized';

const AdminRoute = ({ component: Component }) => {
  const {
    currentUser: { UserType },
  } = React.useContext(AuthContext);

  const isAuthorized = UserType.includes('Admin');
  if (!isAuthorized) {
    return <NotAuthorized />;
  }

  return <Component />;
};

export default AdminRoute;
