import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import Input from '@cloudhub-ux/core/Input';
import Block from '@cloudhub-ux/core/Block';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Button from '@cloudhub-ux/core/Button';
import Tab from '@cloudhub-ux/core/Tab';
import Tabs from '@cloudhub-ux/core/Tabs';
import SwipeableViews from '@cloudhub-ux/core/SwipeableViews';
import { Form, Field } from '@cloudhub-ux/core/form';
import toastr from '@cloudhub-ux/core/toastr';

import { DraftEditor } from '@cloudhub-ux/core/editors';
import { sizes, colors } from 'theme';
import AdminContext from 'admin/context/AdminContext';

function TabContainer(props) {
  return (
    <Block style={{ height: '100%', overflow: 'hidden' }} {...props}>
      {props.children}
    </Block>
  );
}

const StaticPagesForm = () => {
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const [pages, setPages] = React.useState({});
  const [tabvalue, setTabValue] = React.useState(0);

  const onSubmit = async (values) => {
    const { data } = await adminaxiosinstance().post(
      '/system/settings/updatepages',
      values
    );
    if (data) {
      toastr.success('Update successful');
    }
  };

  const getPages = async () => {
    try {
      const { data } = await adminaxiosinstance().get(
        '/system/settings/getpages'
      );

      setPages({
        ...data,
      });
    } catch (error) {
      //
    }
  };
  React.useEffect(() => {
    getPages();
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...pages }}
      render={({ handleSubmit, pristine, submitting }) => (
        <Block>
          <Block flex={false} color={colors.gray2}>
            <Tabs
              value={tabvalue}
              onChange={(event, tabvalue) => setTabValue(tabvalue)}
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab label="About Us" />
              <Tab label="How to" />
              <Tab label="Terms and Conditions" />
              <Tab label="Contact Us" />
              <Tab label="Policy" />
            </Tabs>
            <Block flex={false} row right padding={sizes.padding}>
              <Button
                contained
                onClick={handleSubmit}
                disabled={pristine || submitting}
              >
                <SaveIcon /> Save{' '}
              </Button>
            </Block>
          </Block>

          <Block padding={sizes.padding} color={colors.gray2}>
            <SwipeableViews
              index={tabvalue}
              onChangeIndex={(i) => setTabValue(i)}
              style={{ flex: 1 }}
            >
              <Block style={{ height: '100%', overflow: 'hidden' }}>
                <FieldBlock>
                  <label>Message Title</label>
                  <Field
                    type="text"
                    name="Aboutus.Title"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>
                <FieldBlock flex>
                  <Field
                    wrap={false}
                    name="Aboutus.Body"
                    component={DraftEditor}
                  />
                </FieldBlock>
              </Block>

              <Block style={{ height: '100%', overflow: 'hidden' }}>
                <FieldBlock>
                  <label>Message Title</label>
                  <Field
                    type="text"
                    name="Howto.Title"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>
                <FieldBlock flex>
                  <Field
                    wrap={false}
                    name="Howto.Body"
                    component={DraftEditor}
                  />
                </FieldBlock>
              </Block>

              <Block style={{ height: '100%', overflow: 'hidden' }}>
                <FieldBlock>
                  <label>Message Title</label>
                  <Field
                    type="text"
                    name="Tnc.Title"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>
                <FieldBlock flex>
                  <Field
                    wrap={false}
                    name="Tnc.Body"
                    headingplugin
                    component={DraftEditor}
                  />
                </FieldBlock>
              </Block>

              <TabContainer>
                <FieldBlock>
                  <label>Message Title</label>
                  <Field
                    type="text"
                    name="Contactus.Title"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>
                <FieldBlock flex>
                  <Field
                    wrap={false}
                    name="Contactus.Body"
                    component={DraftEditor}
                  />
                </FieldBlock>
              </TabContainer>

              <TabContainer>
                <FieldBlock>
                  <label>Message Title</label>
                  <Field
                    type="text"
                    name="Policy.Title"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>
                <FieldBlock flex>
                  <Field
                    wrap={false}
                    name="Policy.Body"
                    headingplugin
                    component={DraftEditor}
                  />
                </FieldBlock>
              </TabContainer>
            </SwipeableViews>
          </Block>
        </Block>
      )}
    />
  );
};

export default StaticPagesForm;
