import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Input from '@cloudhub-ux/core/Input';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import TextArea from '@cloudhub-ux/core/TextArea';
import { Form, Field } from '@cloudhub-ux/core/form';
import Alert from '@cloudhub-ux/core/Alert';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import DialogHeader from '@cloudhub-ux/core/dialogs/DialogHeader';
import DialogContent from '@cloudhub-ux/core/dialogs/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import { colors } from 'theme';

import useAdminAxios from 'admin/context/useAdminAxios';
import ImagesUpload from '@cloudhub-ux/core/uploaders/uploader/ImagesUpload';
import { reset } from 'numeral';

const TestimonyForm = (props) => {
  const { postData, error } = useAdminAxios();

  const onSaveTestimony = async (values) => {
    const data = await postData({
      url: '/website/testimony/savetestimony',
      params: {
        ...values,
      },
    });

    if (data) {
      props.onSave(data);
      props.onCancelEdit();
    }
  };

  const { editingRow } = props;
  const values = editingRow || {};

  const title = values.id
    ? values.CustomerName || 'Edit Testimony'
    : 'Add Testimony';

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      minHeight={700}
    >
      <DialogHeader onClose={props.onCancelEdit}>{`${title}`}</DialogHeader>

      <Form
        onSubmit={onSaveTestimony}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent>
              <Block>
                <FieldBlock row>
                  <Field
                    label="Customer Name"
                    name="CustomerName"
                    component={Input}
                    required
                    flex
                  />
                  <Field
                    label="Customer Occupation"
                    name="CustomerOccupation"
                    component={Input}
                    flex
                    required
                  />
                </FieldBlock>
                <Block row top>
                  <Block flex={false} style={{ width: 300 }}>
                    <Field
                      label="Customer Phote"
                      name="CustomerPhoto"
                      component={ImagesUpload}
                      maxSize={300}
                    />
                  </Block>
                  <Block>
                    <Field
                      label="Feedback"
                      name="Feedback"
                      component={TextArea}
                      maxLength={500}
                    />
                  </Block>
                </Block>
              </Block>
            </DialogContent>

            <DialogActions>
              <Block>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset()} />
                )}
              </Block>
              <Button
                contained
                color={colors.danger}
                onClick={props.onCancelEdit}
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                contained
                color={colors.success}
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                {submitting ? 'Please wait...' : 'Save'}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

TestimonyForm.defaultProps = {
  onSave: () => {},
};

export default TestimonyForm;
