/* eslint-disable capitalized-comments */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';

import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import Alert from '@cloudhub-ux/core/Alert';
import useAdminAxios from 'admin/context/useAdminAxios';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { CSVLink } from 'react-csv';
import MediastationForm from './forms/MediastationForm';
import SendSmsForm from '../forms/SendSmsForm';

// const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Mediastation = () => {
  const { getData, adminaxiosinstance } = useAdminAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);
  const [dataarray, setdataarray] = React.useState([]);
  const [phonenumbers, setphonenumbers] = React.useState([]);
  const [smsDialogOpen, openSmsDialog] = React.useState(false);

  const deleteRows = async (deletingRows) => {
    const ids = deletingRows.map((item) => item.id);
    getData({
      url: '/website/mediastation/delete',
      params: { mediastations: ids },
    });
    gridRef.current.onDeleteSuccess(deletingRows);
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const headers = [
    { label: 'Name', key: 'MediastationName' },
    { label: 'Type', key: 'MediastationType' },
    { label: 'Voting Code', key: 'VotingCode' },
    // { label: 'Alternate Voting Code', key: 'AlternateCode' },
  ];

  const onChangeSelection = (selection) => {
    const numbers = [];
    if (Array.isArray(selection)) {
      selection.map((s) => {
        if (numbers.indexOf(s.Phone) === -1) {
          numbers.push(s.Phone);
        }
        return null;
      });
    }

    setphonenumbers(numbers);
    setdataarray([...(selection || [])]);
    gridRef.current.reload();
  };

  const onOpenSmsDialog = () => {
    openSmsDialog(true);
  };
  const renderHeader = ({ onSearch, onRefresh, title, onAdd }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={onAdd}>
            <AddIcon />
          </Button>
          <Button onClick={onOpenSmsDialog} disabled={!phonenumbers}>
            <SendIcon /> Send SMS to Selected
          </Button>
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
          <CSVLink data={dataarray} headers={headers}>
            <Button>
              <ListAltIcon /> Export Selected to Excel
            </Button>
          </CSVLink>
        </Block>
      </Block>
      <Block />
    </Block>
  );
  const cols = [
    { name: 'MediastationName', title: 'Station Name' },
    { name: 'MediastationCode', title: 'Station Code' },
    { name: 'MediastationType', title: 'Type' },
    { name: 'VotingCode', title: 'Voting Code' },
    // { name: 'AlternateCode', title: 'Alternate Voting Code' },
  ];

  const columnWidths = [
    { columnName: 'MediastationCode', width: 120 },
    { columnName: 'MediastationName', width: 150 },
    { columnName: 'MediastationType', width: 120 },
    { columnName: 'VotingCode', width: 120 },
    // { columnName: 'AlternateCode', width: 120 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="TV &amp; Radio Stations"
        url="/website/mediastation/getall"
        axiosinstance={adminaxiosinstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
        header={renderHeader}
        onChangeSelection={onChangeSelection}
        pagingComponent={null}
      />
      {editingRow && (
        <MediastationForm
          editingRow={editingRow}
          onCancelEdit={() => setEditingRow(null)}
          onSave={(row) => gridRef.current.onSave(row)}
        />
      )}
      <SendSmsForm
        numbers={phonenumbers}
        open={smsDialogOpen}
        onClose={() => openSmsDialog(false)}
      />
    </Block>
  );
};

export default Mediastation;
