import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import IconButton from '@cloudhub-ux/core/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import { colors } from 'theme';

const QuestionsTable = ({
  removeQuestion,
  Questions,
  seteditingquestion = () => {},
}) => (
  <Block paper>
    <Table style={{ minWidth: '50%' }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row">
            <Text header>Question</Text>
          </TableCell>
          <TableCell align="left">
            <Text header>Scale</Text>
          </TableCell>
          <TableCell align="left">
            <Text header>Disqualifying</Text>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(Questions) &&
          Questions.map((q) => (
            <TableRow key={q.Question}>
              <TableCell component="th" scope="row">
                {q.Question}
              </TableCell>
              <TableCell align="left">{`${q.Min}-${q.Max}`}</TableCell>
              <TableCell align="left">{q.Preeminent}</TableCell>
              <TableCell>
                <Block row>
                  <IconButton onClick={() => seteditingquestion(q)}>
                    <Edit style={{ color: colors.primary }} />
                  </IconButton>
                  <IconButton onClick={() => removeQuestion(q)}>
                    <Close style={{ color: colors.red }} />
                  </IconButton>
                </Block>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </Block>
);

export default QuestionsTable;
