import React from 'react';
import { WindowSize, navigate } from '@cloudhub-ux/core/reach';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Block from '@cloudhub-ux/core/Block';
import IconButton from '@cloudhub-ux/core/IconButton';
import Text from '@cloudhub-ux/core/Text';
import HoverButton from '@cloudhub-ux/core/HoverButton';
import { colors, sizes } from 'theme';
import AuthContext from 'auth/context/AuthContext';

import AccountButton from 'auth/AccountButton';
import DrawerMenu from './menu/DrawerMenu';
import Drawer from './menu/Drawer';
import AwardYearSelector from './AwardYearSelector';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  appFrame: {
    marginLeft: -drawerWidth,
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: 'white', // theme.palette.background.default,
  },
  appBar: {
    position: 'absolute',

    height: sizes.adminNavBarHeight,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: sizes.margin,
    marginRight: sizes.margin,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    heigh: '100%',
    overflow: 'hidden',
    width: drawerWidth,
    borderWidth: 0,
  },
  drawerInner: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },

  contentShift: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const AdminHome = (props) => {
  const classes = useStyles();
  const { isAuthenticated } = React.useContext(AuthContext);

  const [draweropen, setDrawerOpen] = React.useState(true);

  const renderMenuIcon = () => {
    if (isAuthenticated && !draweropen) {
      return (
        <IconButton
          aria-label="open drawer"
          onClick={() => setDrawerOpen(true)}
          className={classNames(classes.menuButton, draweropen && classes.hide)}
        >
          <MenuIcon style={{ color: colors.primary }} />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <WindowSize>
      {({ height, width }) => (
        <Block row style={{ height, width }}>
          <Block
            flex={false}
            style={{
              position: 'relative',
              borderWidth: 0.5,
              borderTopWidth: 0,
              borderColor: colors.gray,
              borderStyle: 'solid',
              maxHeight: height - 1,
            }}
          >
            <Drawer open={draweropen} onClose={() => setDrawerOpen(false)}>
              <DrawerMenu />
            </Drawer>
          </Block>
          <Block
            className={classNames(
              classes.appFrame,
              draweropen && classes.contentShift
            )}
          >
            <Block
              row
              middle
              shadow
              flex={false}
              padding={[0, sizes.padding]}
              style={{ minHeight: sizes.adminNavBarHeight, zIndex: 9 }}
            >
              {renderMenuIcon()}
              <AwardYearSelector />
              <Block row left>
                <Block flex={false}>
                  <HoverButton
                    small
                    flex={false}
                    color={colors.primary}
                    hoverColor={colors.gray}
                    textColor={colors.white}
                    textHoverColor={colors.primary}
                    borderColor={colors.primary}
                    hoverBorderColor={colors.primary}
                    // hoverStyle={{ borderColor: colors.primary }}
                    // style={{ borderColor: colors.primary }}
                    suffix={null}
                    onClick={() => navigate('/')}
                  >
                    <Text h6 bold color="inherit">
                      Website
                    </Text>
                  </HoverButton>
                </Block>
              </Block>
              <Block flex={false} right>
                <AccountButton
                  userinterface="admin"
                  textStyle={{ color: colors.milkyWhite }}
                />
              </Block>
            </Block>
            <Block
              style={{
                minHeight: height - sizes.adminNavBarHeight,
                maxHeight: height - sizes.adminNavBarHeight,
                position: 'relative',
                maxWidth: width,
              }}
            >
              <Block
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {props.children}
              </Block>
            </Block>
          </Block>
        </Block>
      )}
    </WindowSize>
  );
};

export default AdminHome;
