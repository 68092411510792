import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Text from '@cloudhub-ux/core/Text';
import Dialog, {
  DialogContent,
  DialogActions,
  DialogHeader,
} from '@cloudhub-ux/core/dialogs';
import { sizes } from 'theme';
import useMetrics from '@cloudhub-ux/core/customhooks/useMetrics';
import useAdminAxios from 'admin/context/useAdminAxios';
import AllResults from './AllResults';

const ResultsForm = (props) => {
  const [judgingcriteria, setjudgingcriteria] = React.useState({});
  const [weights, setweights] = React.useState({});
  const [maxscores, setmaxscores] = React.useState({});
  const { height } = useMetrics();
  const { viewing, onClose } = props;
  const { getData } = useAdminAxios({});

  const getCriteria = React.useCallback(async () => {
    if (viewing && viewing.JudgingLevelId) {
      try {
        const data = await getData({
          url: '/judging/judgingcriteria/getcriteria',
          params: { JudgingLevelId: viewing.JudgingLevelId },
        });
        if (data && data.item) {
          setjudgingcriteria(data.item);
        }
      } catch (error) {}
    }
    // eslint-disable-next-line
  }, [viewing]);

  React.useEffect(() => {
    getCriteria();
  }, [getCriteria]);

  const getWeights = React.useCallback(() => {
    const weightobj = {};
    const scoreobj = {};
    const preem = [];
    if (
      judgingcriteria &&
      judgingcriteria.Parameters &&
      Array.isArray(judgingcriteria.Parameters)
    ) {
      judgingcriteria.Parameters.map((parameter) => {
        if (parameter.Questions && Array.isArray(parameter.Questions)) {
          parameter.Questions.map((question) => {
            weightobj[
              `${parameter.ParameterName.replace(
                /[^a-zA-Z]/g,
                ''
              )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
            ] = question.Weight;
            scoreobj[
              `${parameter.ParameterName.replace(
                /[^a-zA-Z]/g,
                ''
              )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
            ] = question.Max;
            if (question.Preeminent === 'Yes') {
              preem.push(
                `${parameter.ParameterName.replace(
                  /[^a-zA-Z]/g,
                  ''
                )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
              );
            }
            return true;
          });
        }
        return true;
      });
      if (preem.length > 0) {
        preem.map((p) => {
          delete weightobj[p];
          delete scoreobj[p];
          return p;
        });
      }
      setweights(weightobj);
      setmaxscores(scoreobj);
    }
  }, [judgingcriteria]);

  React.useEffect(() => {
    getWeights();
  }, [judgingcriteria, getWeights]);

  const totalscore = (points) => {
    const score = Object.keys({ ...(points || {}) }).map(
      (k) => (points || {})[k] || 0
    );
    return score.length > 0 ? score.reduce((a, b) => a + b) : 0;
  };

  return (
    <Dialog
      open={viewing !== null}
      onClose={onClose}
      // eslint-disable-next-line no-mixed-operators
      minHeight={height - sizes.margin * 2}
    >
      <DialogHeader onClose={props.onCancelEdit}>
        {`${viewing.EntryName}  (${viewing.AverageScore || 0})`}
      </DialogHeader>
      <Block>
        <DialogContent>
          <Block>
            {viewing.JudgingResults &&
              Array.isArray(viewing.JudgingResults) &&
              viewing.JudgingResults.map((result) => (
                <Block
                  paper
                  center
                  padding={sizes.padding}
                  marging={sizes.margin}
                  key={result.JudgeId}
                >
                  <Text
                    h2
                    primary
                    underline
                    style={{ width: '100%', textAlign: 'center' }}
                  >
                    {`${result.JudgeName} (${totalscore(result.Points)})`}
                  </Text>
                  <AllResults
                    JudgeName={result.JudgeName}
                    JudgeId={result.JudgeId}
                    JudgingLevelId={viewing.JudgingLevelId}
                    currentscore={result.Points || {}}
                    currentremarks={result.Remarks || {}}
                    judgingcriteria={judgingcriteria}
                    weights={weights}
                    maxscores={maxscores}
                  />
                </Block>
              ))}
          </Block>
        </DialogContent>
      </Block>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="danger"
          style={{ marginRight: sizes.margin }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResultsForm;
