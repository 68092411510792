import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import JudgingCriteria from './judgingcriteria/JudgingCriteria';
import JudgingLevel from './judginglevel/JudgingLevel';
import Judge from './judge/Judge';

const JudgingRoutes = () => (
  <Router style={{ flex: 1, display: 'flex' }}>
    <JudgingCriteria path="judgingcriteria" />
    <JudgingLevel path="judginglevels" />
    <Judge path="judges" />
  </Router>
);

export default JudgingRoutes;
