import React from 'react';
import TableCell from '@mui/material/TableCell';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';

import Block from '@cloudhub-ux/core/Block';
import toastr from '@cloudhub-ux/core/toastr';
import Alert from '@cloudhub-ux/core/Alert';
import AdminContext from 'admin/context/AdminContext';
import useAdminAxios from 'admin/context/useAdminAxios';
import dayjs from 'dayjs';
import AwardyearForm from './AwardyearForm';

const Awardyear = () => {
  const gridRef = React.useRef();
  const [editingRow, setEditingRow] = React.useState(null);
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const { postData, error, reset } = useAdminAxios({});

  const deleteRows = async (deletingRows) => {
    try {
      const ids = deletingRows.map((item) => item.id);
      const data = await postData({
        url: '/system/user/delete',
        params: { users: ids },
      });
      if (data) {
        gridRef.current.onDeleteSuccess(deletingRows);
        toastr.success('Success', 'record(s) deleted');
        reset();
      }
    } catch {}
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'OpeningDate' || column.name === 'ClosingDate') {
      return (
        <TableCell>{dayjs(row[column.name]).format('MMM DD, YYYY')}</TableCell>
      );
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };
  const cols = [
    { name: 'Name', title: 'Award Year Name' },
    { name: 'AwardYearCode', title: 'Code' },
    { name: 'OpeningDate', title: 'Opening Date' },
    { name: 'ClosingDate', title: 'Closing Date' },
    { name: 'Status' },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => reset()} />
        )}
      </Block>

      <RemoteDataGrid
        title="Award Years"
        url="/system/awardyear/getall"
        axiosinstance={adminaxiosinstance}
        columns={cols}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({ Permissions: [] })}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
        pagingComponent={null}
      />

      <AwardyearForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => gridRef.current.onSave(row)}
        keepDirtyOnReinitialize
      />
    </Block>
  );
};

export default Awardyear;
