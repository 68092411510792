import React from 'react';
import TableCell from '@mui/material/TableCell';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import toastr from '@cloudhub-ux/core/toastr';
import Alert from '@cloudhub-ux/core/Alert';
import AdminContext from 'admin/context/AdminContext';
import useAdminAxios from 'admin/context/useAdminAxios';
import SendSmsForm from 'admin/forms/SendSmsForm';
import UserForm from './forms/UserForm';

const User = () => {
  const gridRef = React.useRef();
  const [editingRow, setEditingRow] = React.useState(null);
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const [phonenumbers, setphonenumbers] = React.useState([]);
  const [smsDialogOpen, openSmsDialog] = React.useState(false);
  const { postData, error, reset } = useAdminAxios({});

  const onChangeSelection = (selection) => {
    const numbers = [];
    if (Array.isArray(selection)) {
      selection.map((s) => {
        if (numbers.indexOf(s.Phone) === -1) {
          numbers.push(s.Phone);
        }
        return null;
      });
    }
    setphonenumbers(numbers);
    // gridRef.current.reload();
  };
  const onOpenSmsDialog = () => {
    openSmsDialog(true);
  };

  const deleteRows = async (deletingRows) => {
    try {
      const ids = deletingRows.map((item) => item.id);
      const data = await postData({
        url: '/system/user/delete',
        params: { users: ids },
      });
      if (data) {
        gridRef.current.onDeleteSuccess(deletingRows);
        toastr.success('Success', 'record(s) deleted');
        reset();
      }
    } catch {}
  };
  const renderHeader = ({ onSearch, onRefresh, title, onAdd }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={onAdd} disabled={!phonenumbers}>
            <AddIcon /> Add
          </Button>
          <Button onClick={onOpenSmsDialog} disabled={!phonenumbers}>
            <SendIcon /> Send SMS to Selected
          </Button>
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
        </Block>
      </Block>
      <Block />
    </Block>
  );
  const cellComponent = ({ row, column }) => (
    // Row, column, style
    <TableCell>
      {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
    </TableCell>
  );
  const cols = [
    { name: 'Name', title: 'Full Name' },
    { name: 'Email', title: 'Email' },
    { name: 'Phone', title: 'Phone' },
    { name: 'UserType', title: 'User Type' },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => reset()} />
        )}
      </Block>

      <RemoteDataGrid
        title="Users"
        header={renderHeader}
        url="/system/user/getall"
        axiosinstance={adminaxiosinstance}
        columns={cols}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({ Permissions: [] })}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
        pagingComponent={null}
        onChangeSelection={onChangeSelection}
      />

      <SendSmsForm
        numbers={phonenumbers}
        open={smsDialogOpen}
        onClose={() => openSmsDialog(false)}
      />
      <UserForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => gridRef.current.onSave(row)}
        keepDirtyOnReinitialize
      />
    </Block>
  );
};

export default User;
