import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import TextArea from '@cloudhub-ux/core/TextArea';
import PhoneInput from '@cloudhub-ux/core/PhoneInput';
import Input from '@cloudhub-ux/core/Input';
import { Form, Field } from '@cloudhub-ux/core/form';
import Alert from '@cloudhub-ux/core/Alert';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import DialogHeader from '@cloudhub-ux/core/dialogs/DialogHeader';
import DialogContent from '@cloudhub-ux/core/dialogs/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import { colors } from 'theme';

import useAdminAxios from 'admin/context/useAdminAxios';
import { RemoteSelector } from '@cloudhub-ux/core/selectors';
import ImagesUpload from '@cloudhub-ux/core/uploaders/uploader/ImagesUpload';

const NomineeForm = (props) => {
  const { postData, error, reset, adminaxiosinstance } = useAdminAxios();

  const onSaveNominee = async (values) => {
    const data = await postData({
      url: '/website/nominee/savenominee',
      params: {
        ...values,
      },
    });

    if (data) {
      props.onSave(data);
      props.onCancelEdit();
    }
  };

  const { editingRow } = props;
  const values = editingRow || {};

  const title = values.id
    ? values.NomineeName || 'Edit Nominee'
    : 'Add Nominee';

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      minHeight={700}
    >
      <DialogHeader onClose={props.onCancelEdit}>{`${title}`}</DialogHeader>

      <Form
        onSubmit={onSaveNominee}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent>
              <Block>
                <FieldBlock row>
                  <Field
                    label="Nominee Name"
                    name="NomineeName"
                    component={Input}
                    required
                    flex
                  />
                  <Field
                    label="Nominee Code"
                    name="NomineeCode"
                    component={Input}
                    required
                    maxLength={3}
                  />
                  <Field
                    label="Media House"
                    name="MediaHouse"
                    component={RemoteSelector}
                    url="/website/mediahouse/filtersearch"
                    returnkeys={[
                      'id',
                      '_id',
                      'MediahouseName',
                      'MediahouseCode',
                    ]}
                    displayField="MediahouseName"
                    axiosinstance={adminaxiosinstance}
                    flex
                    required
                  />
                </FieldBlock>
                <Block row top>
                  <Block flex={false}>
                    <Field
                      label="Category"
                      name="Category"
                      component={RemoteSelector}
                      url="/website/entrycategory/filtersearch"
                      returnkeys={['id', '_id', 'CategoryName', 'CategoryCode']}
                      displayField="CategoryName"
                      axiosinstance={adminaxiosinstance}
                      required
                    />
                    <Block flex={false} style={{ width: 300 }}>
                      <Field
                        label="Nominee Phote"
                        name="NomineePhoto"
                        component={ImagesUpload}
                        maxSize={300}
                      />
                    </Block>
                  </Block>
                  <Block style={{ marginLeft: 5 }}>
                    <Field
                      label="Nominee Bio"
                      name="NomineeBio"
                      component={TextArea}
                      rows={15}
                      maxLength={1000}
                    />
                  </Block>
                </Block>
                <FieldBlock row>
                  <Field
                    label="Phone"
                    name="Phone"
                    component={PhoneInput}
                    flex
                  />
                  <Field label="Email" name="Email" component={Input} flex />
                </FieldBlock>
                <FieldBlock row>
                  <Field
                    label="Twitter"
                    name="Twitter"
                    component={Input}
                    flex
                  />
                  <Field
                    label="Facebook"
                    name="Facebook"
                    component={Input}
                    flex
                  />
                  <Field
                    label="Youtube"
                    name="Youtube"
                    component={Input}
                    flex
                  />
                </FieldBlock>
              </Block>
            </DialogContent>

            <DialogActions>
              <Block>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset()} />
                )}
              </Block>
              <Button
                contained
                color={colors.danger}
                onClick={props.onCancelEdit}
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                contained
                color={colors.success}
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                {submitting ? 'Please wait...' : 'Save'}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

NomineeForm.defaultProps = {
  onSave: () => {},
};

export default NomineeForm;
