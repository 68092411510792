const isNode = require('detect-node');

export default {
  API_ENDPOINT: isNode ? 'http://127.0.0.1:1120/api' : '/api',

  FACEBOOK_ID: '279750363012126',
  GOOGLE_ID:
    '36532691394-jig7pr9fq45hjb7hgl9uc36o5sqk9j60.apps.googleusercontent.com',
  GOOGLE_APIKEY: 'AIzaSyA1alkRfDOOXteAhNEPbIqo9CDmCmikSWs',
};
