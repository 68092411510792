import React, { Fragment } from 'react';
import {
  DialogHeader,
  DialogActions,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import PhoneInput from '@cloudhub-ux/core/PhoneInput';
import Input from '@cloudhub-ux/core/Input';
import Text from '@cloudhub-ux/core/Text';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors } from 'theme';

const VoteForm = (props) => {
  const { editingRow, onCancelEdit } = props;
  return (
    <Dialog open={editingRow !== null} maxWidth="md" minHeight="40vh">
      <Form
        onSubmit={() => {}}
        initialValues={editingRow}
        render={() => (
          <Fragment>
            <DialogHeader onClose={onCancelEdit}>
              <Text header>{(editingRow || {}).MediastationName}</Text>
            </DialogHeader>

            <DialogContent>
              <Block>
                <FieldBlock row wrap>
                  <Field
                    name="MediastationName"
                    label="Station Name"
                    component={Input}
                    readOnly
                    required
                    flex
                  />
                  <Field
                    name="MediastationCode"
                    label="Station Code"
                    component={Input}
                    readOnly
                    flex
                  />
                  <Field
                    name="VotingCode"
                    label="Voting Code"
                    component={Input}
                    readOnly
                    flex
                  />
                </FieldBlock>

                <Field
                  name="Phone"
                  label="Voter Phone Number"
                  component={PhoneInput}
                  readOnly
                />
              </Block>
            </DialogContent>
            <DialogActions>
              <Button contained onClick={onCancelEdit} color={colors.secondary}>
                Close
              </Button>
            </DialogActions>
          </Fragment>
        )}
      />
    </Dialog>
  );
};

export default VoteForm;
