import React from 'react';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Input from '@cloudhub-ux/core/Input';
import Fieldset from '@cloudhub-ux/core/form/Fieldset';
import CountrySelector from '@cloudhub-ux/core/baseweb/CountrySelector';
import { Field } from '@cloudhub-ux/core/form';
import PhoneInput from '@cloudhub-ux/core/PhoneInput';

const AddressCompoenent = ({ addressLabel }) => (
  <React.Fragment>
    <Fieldset label={addressLabel || 'Address'}>
      <Field
        label="Physical Address"
        type="text"
        name="Address.PhysicalAddress"
        component={Input}
      />
      <FieldBlock row>
        <Field
          label="Phone"
          type="number"
          name="Address.Phone"
          component={PhoneInput}
          flex
        />
        <Field
          label="Email"
          type="email"
          name="Address.Email"
          component={Input}
          flex
        />
        <Field
          label="Alternative  Phone"
          type="tel"
          name="Address.AlternatePhone"
          component={PhoneInput}
          flex
        />
      </FieldBlock>
      <FieldBlock row>
        <Field
          label="City/Town"
          type="text"
          name="Address.City"
          component={Input}
          flex
        />
        <Field
          label="Country"
          type="text"
          name="Address.Country"
          component={CountrySelector}
          valueExtractor={({ id, name }) => ({ id, name })}
          flex
        />
      </FieldBlock>
    </Fieldset>
  </React.Fragment>
);

export default AddressCompoenent;
