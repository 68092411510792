import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import TextArea from '@cloudhub-ux/core/TextArea';
import Input from '@cloudhub-ux/core/Input';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import Text from '@cloudhub-ux/core/Text';
import { Form, Field, FormSpy } from '@cloudhub-ux/core/form';
import {
  StaticListSelector,
  RemoteSelector,
} from '@cloudhub-ux/core/selectors';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';
import Alert from '@cloudhub-ux/core/Alert';
import QuestionsTable from './QuestionsTable';
import ParametersTable from './ParametersTable';

const JudgingCriteriaForm = (props) => {
  const [editingparam, seteditingparam] = React.useState({});
  const [editingquetion, seteditingquestion] = React.useState({});
  const [criteriaError, setcriteriaError] = React.useState('');
  const { postData, error, reset } = useAdminAxios({});
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const updateParam = (val) => {
    if (val && JSON.stringify(val) !== JSON.stringify(editingparam)) {
      seteditingparam(val);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      const data = await postData({
        url: `/judging/judgingcriteria/${values.id ? 'update' : 'new'}`,
        params: values,
      });
      if (data) {
        reset();
        setcriteriaError('');
        props.onCancelEdit();
        props.onSave();
      }
    } catch (error) {
      setcriteriaError(error.message || `${error}`);
    }
  };

  const addQuestion = (question, form) => {
    const questions = [...(editingparam.Questions || [])];
    const ind = questions.findIndex(
      (q) => q && q.Question && q.Question === question.Question
    );
    if (ind === -1) {
      questions.push(question);
    } else {
      questions[ind] = question;
    }
    seteditingparam({ ...editingparam, Questions: questions });
    seteditingquestion({});
    setTimeout(() => {
      form.reset();
    });
  };

  const removeQuestion = (val) => {
    const questions = [...(editingparam.Questions || [])];
    questions.splice(questions.indexOf(val), 1);
    seteditingparam({ ...editingparam, Questions: questions });
  };

  const { editingcriteriaRow, editcriteriaTitle, onCancelEdit } = props;
  return (
    <Block absolute padding={sizes.padding}>
      <Text h3>{editcriteriaTitle}</Text>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={editingcriteriaRow}
        render={({ handleSubmit, submitting, form, values }) => {
          const addParameter = (parameter) => {
            const parameters = [...((values || {}).Parameters || [])];
            const ind = parameters.findIndex(
              (param) =>
                param && param.ParameterName === (parameter || {}).ParameterName
            );
            if (ind !== -1) {
              parameters[ind] = parameter;
            } else {
              parameters.push(parameter);
            }
            form.change('Parameters', parameters);
            seteditingparam({});
          };

          const removeParameter = (val) => {
            const parameters = [...(values.Parameters || [])];
            parameters.splice(parameters.indexOf(val), 1);
            form.change('Parameters', parameters);
          };

          return (
            <React.Fragment>
              <Block row>
                <Block>
                  <Block paper margin={sizes.baseMargin} flex={false}>
                    <Field
                      name="Name"
                      style={{ flex: 1 }}
                      label="Criteria Name"
                      component={Input}
                      required
                    />
                    <Field
                      name="JudgingLevel"
                      label="JudgingLevel"
                      style={{ flex: 1 }}
                      component={RemoteSelector}
                      url="/judging/judginglevel/getall"
                      axiosinstance={adminaxiosinstance}
                      labelExtractor={(option) =>
                        option && option.Name ? option.Name : `${option}`
                      }
                      valueExtractor={(row) =>
                        row && row.id
                          ? {
                              id: row.id,
                              Name: row.Name,
                              _id: row._id,
                              _ref: row._id,
                            }
                          : row
                      }
                      required
                    />
                    <FieldBlock flex>
                      <Field
                        name="Description"
                        style={{ width: '100%' }}
                        label="Description"
                        component={TextArea}
                      />
                    </FieldBlock>
                    <Field
                      type="number"
                      name="MaximumVariance"
                      style={{ flex: 1 }}
                      label="Maximum Variation"
                      component={Input}
                      maxValue={120}
                    />
                  </Block>
                  <ParametersTable
                    removeParameter={removeParameter}
                    Parameters={values.Parameters}
                    seteditingparam={seteditingparam}
                  />
                </Block>
                <Block>
                  <Form
                    onSubmit={addParameter}
                    initialValues={editingparam || {}}
                    render={({ values, handleSubmit }) => (
                      <Block paper>
                        <Block paper margin={sizes.baseMargin} flex={false}>
                          <Field
                            type="text"
                            name="ParameterName"
                            label="Parameter"
                            component={Input}
                            required
                          />
                          <FieldBlock row>
                            <Field
                              type="text"
                              flex
                              style={{ flex: 1 }}
                              name="Description"
                              label="Description"
                              component={TextArea}
                            />
                            <Field
                              flex
                              type="text"
                              style={{ flex: 1 }}
                              name="UmbrellaQuestion"
                              label="Umbrella Question (for multiple questions under one parameter)"
                              component={TextArea}
                            />
                          </FieldBlock>
                        </Block>
                        <Block>
                          <Form
                            onSubmit={addQuestion}
                            initialValues={editingquetion || {}}
                            render={({ values, handleSubmit }) => (
                              <Block margin={sizes.baseMargin}>
                                <Field
                                  type="text"
                                  name="Question"
                                  label="Question"
                                  component={TextArea}
                                  required
                                />
                                <FieldBlock row wrap={false}>
                                  <Field
                                    type="number"
                                    name="Min"
                                    label="Minimum Possible Score"
                                    component={Input}
                                    required
                                  />
                                  <Field
                                    type="number"
                                    name="Max"
                                    label="Maximum Possible Score"
                                    component={Input}
                                    required
                                  />
                                  <Field
                                    type="text"
                                    name="Preeminent"
                                    label="Does this Aspect qualify/disqualify the entry from further judging"
                                    component={StaticListSelector}
                                    options={['Yes', 'No']}
                                    required
                                    validate={(val) => {
                                      if (
                                        val === 'Yes' &&
                                        (Number(values.Min) !== 0 ||
                                          Number(values.Max) !== 1)
                                      ) {
                                        return 'Disqualifying questions must be on a scale of 0 - 1';
                                      }
                                      return undefined;
                                    }}
                                  />
                                </FieldBlock>
                                <Button
                                  contained
                                  color={colors.primary}
                                  onClick={handleSubmit}
                                  disabled={
                                    !values.Question ||
                                    !values.Min ||
                                    !values.Max ||
                                    !values.Preeminent
                                  }
                                  style={{ marginTop: sizes.baseMargin }}
                                >
                                  Add Question
                                </Button>
                              </Block>
                            )}
                          />
                          <QuestionsTable
                            removeQuestion={removeQuestion}
                            Questions={
                              editingparam && editingparam.Questions
                                ? editingparam.Questions
                                : []
                            }
                            seteditingquestion={seteditingquestion}
                          />
                        </Block>
                        <Button
                          contained
                          color={colors.primary}
                          onClick={handleSubmit}
                          disabled={
                            !(values || {}).ParameterName ||
                            ((values || {}).Questions || []) < 1
                          }
                        >
                          Add Parameter
                        </Button>
                        <FormSpy
                          subscription={{ values: true }}
                          onChange={({ values }) => {
                            updateParam(values);
                          }}
                        />
                      </Block>
                    )}
                  />
                </Block>
              </Block>
              {(error || criteriaError) && (
                <Alert
                  error
                  message={error || criteriaError}
                  onClose={() => {
                    reset();
                    setcriteriaError('');
                  }}
                />
              )}
              <Block row right padding={sizes.padding * 2}>
                <Button
                  contained
                  onClick={onCancelEdit}
                  color={colors.secondary}
                  style={{ marginRight: sizes.doubleBaseMargin }}
                >
                  Cancel
                </Button>
                <Button
                  contained
                  onClick={handleSubmit}
                  disabled={submitting}
                  color={colors.primary}
                >
                  Save
                </Button>
              </Block>
            </React.Fragment>
          );
        }}
      />
    </Block>
  );
};

export default JudgingCriteriaForm;
