import toastr from '@cloudhub-ux/core/toastr';
import CONFIG from '../Config';

import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  SET_TOKEN,
  OPEN_SIGN_IN,
  CLOSE_SIGN_IN,
  OPEN_CHANGE_PASSWORD,
  CLOSE_CHANGE_PASSWORD,
  OPEN_UPDATE_PROFILE,
  CLOSE_UPDATE_PROFILE,
  SEND_CONFIRMATION_CODE,
  CONFIRM_ACCOUNT,
  OPEN_GET_STARTED,
  CLOSE_GET_STARTED,
} from './authTypes';

const authActions = ({ dispatch, navigate, axiosinstance }) => {
  const actions = {
    authUser: (data) => {
      dispatch({
        type: AUTH_USER,
        payload: data,
      });
    },

    signinUser: async (values) => {
      try {
        const { data } = await axiosinstance().post(
          `${CONFIG.API_ENDPOINT}/auth/user/emaillogin`,
          { ...values }
        );

        actions.authUser(data);
        dispatch({
          type: CLOSE_SIGN_IN,
        });
        navigate(
          (((data || {}).user || {}).UserType || []).includes('Judge')
            ? '/judging'
            : '/'
        );
      } catch (error) {
        const response = error.response || {};
        const data = response.data || {};
        toastr.error(data.message || error.message || 'Error signing in');
        dispatch(
          actions.authError(data.message || error.message || 'Bad login info')
        );
        return {};
      }
    },

    checkAccount: (values) =>
      axiosinstance()
        .get(`${CONFIG.API_ENDPOINT}/auth/user/checkaccount`, {
          params: { ...values },
        })
        .then(({ data }) => Promise.resolve(data))
        .catch(() => Promise.reject()),

    signoutUser: () => {
      try {
        localStorage.removeItem('token');
      } catch (error) {}

      dispatch({
        type: UNAUTH_USER,
      });
    },

    signupUser: async ({ user }) => {
      try {
        const { data } = await axiosinstance().post(
          `${CONFIG.API_ENDPOINT}/auth/user/signup`,
          user
        );
        // actions.authUser(data);
        // actions.closeSignIn();
        // navigate('/entry/fillentry');
        return data;
      } catch (error) {
        const { response } = error;
        const message = response
          ? response.data.message || 'Error in your request'
          : error;
        toastr.error(message);
        dispatch(actions.authError(message));
      }
    },

    loginWithFacebook: ({ params }) => {
      axiosinstance()
        .post(`${CONFIG.API_ENDPOINT}/auth/user/facebooklogin`, { ...params })
        .then(({ data }) => {
          actions.authUser(data);
          dispatch({
            type: CLOSE_SIGN_IN,
          });
        })
        .catch(async () => {});
    },

    loginWithGoogle: ({ params }) => {
      axiosinstance()
        .post(`${CONFIG.API_ENDPOINT}/auth/user/googlelogin`, { ...params })
        .then(({ data }) => {
          actions.authUser(data);
          dispatch({
            type: CLOSE_SIGN_IN,
          });
        })
        .catch(async (error) => {
          try {
            localStorage.removeItem('google_access_code');
          } catch (error) {}

          dispatch(
            actions.authError(
              error.response.data.message ? error.response.data.message : error
            )
          );
        });
    },

    checkAuthStatus: async () => {
      try {
        let token;
        try {
          dispatch({
            type: AUTH_ERROR,
            payload: '',
          });
          try {
            token = localStorage.getItem('token');
          } catch (error) {}
        } catch (error) {
          //
        }

        dispatch({
          type: SET_TOKEN,
          payload: token,
        });

        const { data } = await axiosinstance().get(
          `${CONFIG.API_ENDPOINT}/auth/user/getuser`
        );
        if (data && data.user && data.user.id) {
          actions.authUser(data);

          const user = data.user || {};

          const href = window ? window.location.href || '' : '';

          if (
            Array.isArray(user.UserType) &&
            data.user.UserType.includes('Admin') &&
            !href.includes('/admin')
          ) {
            // navigate('/admin');
          }

          if (href.includes('/auth')) {
            navigate('/');
          }
        }
      } catch (error) {
        const response = error.response || {};
        const data = response.data || {};
        dispatch({
          type: AUTH_ERROR,
          payload: `${data.message || 'Server error'}`.replace(/_/g, ' '),
        });
        actions.signoutUser();
      }
    },

    sendConfirmationCode: () => {
      axiosinstance()
        .get(`${CONFIG.API_ENDPOINT}/auth/user/sendconfirmcode`)
        .then(() => {
          dispatch({
            type: SEND_CONFIRMATION_CODE,
          });
        });
    },

    confirmAccount: (code) => {
      axiosinstance()
        .post(`${CONFIG.API_ENDPOINT}/auth/user/confirmaccount`, { code })
        .then(() => {
          dispatch({
            type: CONFIRM_ACCOUNT,
          });
        })
        .catch(() => {
          toastr.error('Problem with your code');
        });
    },

    resetPassword: async ({ Email }) => {
      await axiosinstance().get(
        `${CONFIG.API_ENDPOINT}/auth/user/resetpassword`,
        { params: { Email } }
      );

      try {
        navigate('/auth/resetpasswordsuccess');
        toastr.success(
          'Password has been reset and sent to your email. Please chack your email in a few minutes'
        );
        return Promise.resolve();
      } catch (error) {
        toastr.error('Problem with your code');
        return Promise.reject();
      }
    },

    changePassword: ({ CurrentPassword, Password }) => {
      axiosinstance()
        .post(`${CONFIG.API_ENDPOINT}/auth/user/changepassword`, {
          CurrentPassword,
          Password,
        })
        .then(() => {
          dispatch({
            type: CLOSE_CHANGE_PASSWORD,
          });
          toastr.success('Your password was changed successfully');
        })
        .catch(() => {
          toastr.error('Problem with your code');
        });
    },

    updateProfile: (profile) => {
      axiosinstance()
        .post(`${CONFIG.API_ENDPOINT}/auth/user/updateprofile`, profile)
        .then(({ data }) => {
          actions.authUser(data);
          toastr.success('Your profile is now updated');
        })
        .catch((error) => {
          const response = error.response || {};
          const data = response.data || {};
          toastr.error(data.message || 'Problem with your profile');
        });
    },

    openSignIn: () =>
      dispatch({
        type: OPEN_SIGN_IN,
      }),

    openGetStarted: () =>
      dispatch({
        type: OPEN_GET_STARTED,
      }),

    closeGetStarted: () =>
      dispatch({
        type: CLOSE_GET_STARTED,
      }),

    closeSignIn: () =>
      dispatch({
        type: CLOSE_SIGN_IN,
      }),

    openChangePassword: () =>
      dispatch({
        type: OPEN_CHANGE_PASSWORD,
      }),

    closeChangePassword: () =>
      dispatch({
        type: CLOSE_CHANGE_PASSWORD,
      }),

    openUpdateProfile: () =>
      dispatch({
        type: OPEN_UPDATE_PROFILE,
      }),

    closeUpdateProfile: () =>
      dispatch({
        type: CLOSE_UPDATE_PROFILE,
      }),

    setToken: (token) => ({
      type: SET_TOKEN,
      payload: token,
    }),

    authError: (error) => ({
      type: AUTH_ERROR,
      payload: error,
    }),
  };

  return actions;
};
export default authActions;
