import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  categories: {
    minHeight: 500,
    maxHeight: 500,
    minWidth: 150,
    overflow: 'auto',
    display: 'flex',
    '@media (max-width: 1200px)': {
      display: 'none !important',
    },
  },
  chartContainer: {
    maxWidth: '100%',
    width: '80%',
    // backgroundColor: colors.gray4,
    '@media (max-width: 1200px)': {
      width: '95% !important',
      maxWidth: '95% !important',
    },
  },
});

const formatColors = [
  colors.primary,
  colors.secondary,
  colors.tertiary,
  colors.accent,
  colors.orange,
];

const EntryDashboard = () => {
  const [categories, setcategories] = React.useState([]);
  const [catcounts, setcatcounts] = React.useState([]);
  const [entryCount, setentryCount] = React.useState({});
  const [submitted, setsubmitted] = React.useState(0);
  const [today, settoday] = React.useState(0);
  const [daily, setdaily] = React.useState([]);
  const [byformat, setbyformat] = React.useState([]);
  const [bylanguage, setbylanguage] = React.useState([]);
  const { getData } = useAdminAxios({});

  const classes = useStyles();

  const getCount = async () => {
    try {
      const data = await getData({
        url: 'entry/entry/count',
      });
      if (data && data.entries) {
        setentryCount(data);
      }
    } catch (error) {}
  };

  const getCategories = async () => {
    try {
      const data = await getData({
        url: '/website/entrycategory/getall',
      });
      if (data && data.items) {
        setcategories(
          data.items
            .map((cat) => {
              if (cat && cat.AllowEntry) {
                return cat;
              }
              return null;
            })
            .filter(Boolean)
        );
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getCategories();
    getCount();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (entryCount && entryCount.entries) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      const totalSubmitted = entryCount.entries
        .map((entry) => (entry.Status === 'SUBMITTED' ? entry.Count : 0))
        .reduce(reducer, 0);
      setsubmitted(totalSubmitted);

      const catcounts = categories
        .map(({ CategoryCode, CategoryName }) => {
          const catsubmitted = entryCount.entries
            .map((entry) =>
              (entry.Category || {}).CategoryCode === CategoryCode &&
              entry.Status === 'SUBMITTED'
                ? Number(entry.Count)
                : 0
            )
            .filter(Boolean)[0];
          return { CategoryName, Count: catsubmitted || 0 };
        })
        .sort((a, b) => b.Count - a.Count);
      setcatcounts(catcounts);
      const todayentries = entryCount.daily
        ? entryCount.daily
            .map((entries) => {
              const d = new Date();
              return Number(entries.Date.split('-')[0]) === d.getFullYear() &&
                Number((entries.Date || '').split('-')[1]) ===
                  d.getMonth() + 1 &&
                Number((entries.Date || '').split('-')[2]) === d.getDate()
                ? entries.Entries
                : null;
            })
            .filter(Boolean)[0]
        : 0;
      settoday(todayentries);
      setdaily(
        (entryCount.daily || [])
          .map((day) => {
            if (day && day.Date) {
              const date = (day.Date || '')
                .split('-')
                .map((item) => (item && item.length === 1 ? `0${item}` : item))
                .join('-');
              return { Date: date, Entries: day.Entries };
            }
            return day;
          })
          .sort((a, b) => dayjs(a.Date).valueOf() - dayjs(b.Date).valueOf())
      );
      setbyformat(entryCount.byformat || []);
      setbylanguage(entryCount.bylanguage || []);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(entryCount), JSON.stringify(categories)]);
  return (
    <Block row flex={false} style={{ minHeight: 650, maxWidth: '100%' }}>
      <Block margin={sizes.padding}>
        <Block row middle space="between" flex={false} padding={sizes.padding}>
          <Block>
            <Text h1 primary center>
              Entries
            </Text>
          </Block>

          <Block paper row right padding={[sizes.padding, sizes.padding * 2]}>
            <Text h3>
              Total :{' '}
              <Text h1 primary>
                {submitted}
              </Text>
            </Text>
          </Block>

          <Block center middle padding={[sizes.padding, sizes.padding * 2]}>
            <Text header>
              Today:{' '}
              <Text primary h1>
                {Number(today) || 0}
              </Text>
            </Text>
          </Block>
        </Block>
        <Block row>
          <Block
            className={classes.chartContainer}
            paper
            margin={sizes.smallMargin}
          >
            <ResponsiveContainer width="100%" height="90%">
              <LineChart
                data={daily}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Date" />
                <YAxis />
                <Tooltip />
                <Legend
                  content={
                    <Block>
                      <Text h3 bold primary underline>
                        Daily Entries
                      </Text>
                    </Block>
                  }
                  verticalAlign="top"
                />
                <Line
                  type="monotone"
                  dataKey="Entries"
                  stroke={colors.primary}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </Block>
          <Block
            flex={false}
            paper
            className={classes.categories}
            margin={sizes.smallMargin}
          >
            <List>
              {catcounts.map((cat) => (
                <ListItem key={cat.CategoryName} divider dense>
                  <ListItemText
                    primary={
                      <Block row>
                        <Block>
                          <Text h5 bold>
                            {cat.CategoryName}:{' '}
                          </Text>
                        </Block>
                        <Text h2 primary bold>
                          {cat.Count || 0}
                        </Text>
                      </Block>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Block>
        </Block>
        <Block margin={sizes.baseMargin} flex={false}>
          <Text h4 primary bold underline style={{ alignSelf: 'center' }}>
            Entries by Story Format
          </Text>
          <Block row style={{ minHeight: sizes.padding }} color="cyan">
            {byformat.map((storyformat, index) => (
              <Block
                center
                color={formatColors[index]}
                middle
                key={storyformat.StoryFormat}
                flex={false}
                style={{
                  height: '100%',
                  width: `${
                    (storyformat.Count /
                      byformat
                        .map(({ Count }) => Count)
                        .reduce((a, b) => a + b, 0)) *
                    100
                  }%`,
                  borderRight: '1px solid white',
                }}
              >
                <Text> </Text>
              </Block>
            ))}
          </Block>
          <Block row space="around">
            {byformat.map((storyformat, index) => (
              <Block row flex={false} middle key={storyformat.StoryFormat}>
                <Block
                  paper
                  color={formatColors[index]}
                  padding={sizes.padding / 2}
                  margin={sizes.baseMargin}
                >
                  {' '}
                </Block>
                <Text h6 semibold>
                  {storyformat.StoryFormat} ({storyformat.Count})
                </Text>
              </Block>
            ))}
          </Block>
        </Block>
        <Block flex={false} row wrap={false} style={{ overflow: 'auto' }}>
          <Text caption primary style={{ marginRight: sizes.doubleBaseMargin }}>
            Entries by Language:
          </Text>
          {bylanguage.map((language) => (
            <Text
              caption
              key={language.Language}
              style={{ marginRight: sizes.doubleBaseMargin }}
              charcoal
            >
              {language.Language || 'N/A'}: <strong>{language.Count}</strong>
            </Text>
          ))}
        </Block>
      </Block>
    </Block>
  );
};
export default EntryDashboard;
