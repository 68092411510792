import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Entry from './Entry';
import Vote from './Vote';
import Votesms from './Votesms';

const EntryRoutes = () => (
  <Router style={{ flex: 1, display: 'flex' }}>
    <Entry path="entries" />
    <Vote path="votes" />
    <Votesms path="votesms" />
  </Router>
);

export default EntryRoutes;
