import React, { useState, useRef } from 'react';
import TableCell from '@mui/material/TableCell';
import RefreshIcon from '@mui/icons-material/Refresh';

import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Button from '@cloudhub-ux/core/Button';
import dayjs from 'dayjs';
import AdminContext from 'admin/context/AdminContext';
import VoteForm from './forms/VoteForm';
import SendSmsForm from '../forms/SendSmsForm';
import SyncSms from './forms/SyncSms';

const Vote = () => {
  const gridRef = useRef();
  const [phonenumbers, setphonenumbers] = useState([]);
  const [smsDialogOpen, openSmsDialog] = useState(false);
  const [editingRow, seteditingRow] = React.useState(null);

  // const { postData } = useAdminAxios({});
  const {
    adminaxiosinstance,
    auth: { currentUser },
  } = React.useContext(AdminContext);

  const onChangeSelection = (selection) => {
    const numbers = [];
    if (Array.isArray(selection)) {
      selection.map((s) => {
        if (
          s.ContactPerson &&
          s.ContactPerson.Phone &&
          numbers.indexOf(s.ContactPerson.Phone) === -1
        ) {
          numbers.push(s.ContactPerson.Phone);
        }
        return null;
      });
    }

    setphonenumbers(numbers);
    gridRef.current.reload();
  };

  const deleteRows = async () => {
    try {
      // const ids = rows.map((item) => item.id);
      // const data = await postData({
      //   url: '/entry/nomination/delete',
      //   params: { entries: ids },
      // });
      // if (data) {
      //   gridRef.current.reload();
      // }
    } catch (error) {}
  };

  const renderHeader = ({ onSearch, onRefresh, title }) => (
    <Block paper>
      <Block row space="between" bottom padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false} bottom>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>

          {currentUser &&
            Array.isArray(currentUser.UserRoles) &&
            currentUser.UserRoles.includes('Super Admin') && (
              <SyncSms onSynced={(gridRef.current || {}).reload} />
            )}
        </Block>
      </Block>
      <Block />
    </Block>
  );

  const renderTemplates = ({ row, column }) => {
    // row, column, style
    if (column.name === 'TimeStamp') {
      return (
        <TableCell>
          <Text bold>
            {dayjs(row.TimeStamp || row.createdAt).format(
              'DD MMM, YYYY hh:mm a'
            )}
          </Text>
        </TableCell>
      );
    }
    return (
      <TableCell>
        <Text bold>{row[column.name] || ''}</Text>
      </TableCell>
    );
  };

  const cols = [
    { name: 'from', title: 'Phone' },
    { name: 'text', title: 'Code' },
    { name: 'status', title: 'status' },
    { name: 'smsid', title: 'ID' },
    { name: 'TimeStamp', title: 'Date' },
  ];

  const columnWidths = [
    { columnName: 'from', width: 150 },
    { columnName: 'text', width: 70 },
    { columnName: 'status', width: 150 },
    { columnName: 'smsid', width: 100 },
    { columnName: 'TimeStamp', width: 70 },
  ];

  return (
    <Block>
      {!editingRow ? (
        <Block>
          <RemoteDataGrid
            title="Vote SMS"
            columns={cols}
            columnWidths={columnWidths}
            allowColumnResizing={false}
            header={renderHeader}
            url="/entry/votesms/getall"
            axiosinstance={adminaxiosinstance}
            cellComponent={renderTemplates}
            onEdit={seteditingRow}
            onDeleteRows={deleteRows}
            onChangeSelection={onChangeSelection}
            ref={gridRef}
            pagingComponent={null}
          />
          <SendSmsForm
            numbers={phonenumbers}
            open={smsDialogOpen}
            onClose={() => openSmsDialog(false)}
          />
        </Block>
      ) : (
        <VoteForm
          editTitle={(editingRow || {}).EntryName}
          currentEntry={editingRow}
          onCancelEdit={() => seteditingRow(null)}
          editingRow={editingRow}
        />
      )}
    </Block>
  );
};

export default Vote;
