import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Text from '@cloudhub-ux/core/Text';
import IconButton from '@cloudhub-ux/core/IconButton';
import Divider from '@cloudhub-ux/core/Divider';
import toastr from '@cloudhub-ux/core/toastr';
import List from '@mui/material/List';
import ListIcon from '@mui/icons-material/List';
import ViewModule from '@mui/icons-material/ViewModule';
import useAdminAxios from 'admin/context/useAdminAxios';
import Input from '@cloudhub-ux/core/Input';
import Judges from './Judge';
import Entry from './Entry';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import { colors, hexToRgb, sizes } from '../../../theme';
import ResultsGrid from './ResultsGrid';
import LevelEntry from './LevelEntry';
import CriteriaForm from '../forms/CriteriaForm';
import LevelJudge from './LevelJudge';
import LevelCategories from './LevelCategories';

const AssignmentForm = ({ editingRow, onCancelEdit = () => {} }) => {
  const [listview, setlistview] = React.useState(false);
  const [judges, setjudges] = React.useState([]);
  const [entries, setentries] = React.useState([]);
  const [openentries, setopenentries] = React.useState(false);
  const [openjudges, setopenjudges] = React.useState(false);
  const [activejudge, setactivejudge] = React.useState(null);
  const [activeentry, setactiveentry] = React.useState(null);
  const [deletingjudge, setdeletingjudge] = React.useState(null);
  const [deletingentry, setdeletingentry] = React.useState(null);
  const [unassign, setunassign] = React.useState(null);
  const [viewing, setviewing] = React.useState(null);
  const [activecategory, setactivecategory] = React.useState(null);
  const [searchPhrase, setsearchPhrase] = React.useState('');

  const { postData, getData, error } = useAdminAxios({});

  React.useEffect(() => {
    if (error) {
      toastr.error(error);
    }
  }, [error]);

  const getJudges = React.useCallback(async () => {
    try {
      const data = await getData({
        url: '/judging/judge/judges',
        params: {
          JudgingLevel: editingRow.id,
        },
      });
      if (data && data.items) {
        setjudges(data.items);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [JSON.stringify(editingRow)]);

  const getEntries = React.useCallback(async () => {
    try {
      const data = await getData({
        url: '/judging/judgingresult/entries',
        params: {
          JudgingLevel: editingRow.id,
        },
      });
      if (data && data.items) {
        setentries(data.items);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [JSON.stringify(editingRow)]);

  React.useEffect(() => {
    getEntries();
    getJudges();
  }, [getEntries, getJudges]);

  const assignJudges = async (judges) => {
    try {
      const data = await postData({
        url: '/judging/judge/newjudges',
        params: {
          JudgingLevelId: editingRow.id,
          JudgingLevelName: editingRow.Name,
          judges,
        },
      });
      if (data) {
        getJudges();
        setopenjudges(false);
      }
    } catch (error) {}
  };

  const assignEntries = async (entries) => {
    try {
      const data = await postData({
        url: '/judging/judgingresult/newresult',
        params: {
          JudgingLevelId: editingRow.id,
          JudgingLevelName: editingRow.Name,
          Moderation: editingRow.Moderation,
          entries,
        },
      });
      if (data) {
        getEntries();
        setopenentries(false);
      }
    } catch (error) {}
  };

  const assignEntry = async (entry) => {
    try {
      if (activejudge) {
        const ind =
          activejudge.Entries && Array.isArray(activejudge.Entries)
            ? activejudge.Entries.findIndex(({ id }) => id === entry.id)
            : -1;
        if (ind === -1) {
          const data = await postData({
            url: '/judging/judge/assignentry',
            params: {
              JudgeId: activejudge.id,
              JudgeName: activejudge.JudgeName,
              entry,
            },
          });
          if (data) {
            const judgearray = [...(judges || [])].map((judge) => {
              if (judge.id === data.judge.id) {
                return { ...data.judge };
              }
              return judge;
            });
            setjudges(judgearray);
            setactivejudge(
              judgearray[judgearray.findIndex(({ id }) => id === data.judge.id)]
            );
            const entryarray = [...(entries || [])].map((entry) => {
              if (entry.id === data.judgingresult.id) {
                return { ...data.judgingresult };
              }
              return entry;
            });
            setentries(entryarray);
          }
        } else {
          setunassign(entry);
        }
      }
    } catch (error) {}
  };

  const removeJudge = async () => {
    try {
      const data = await postData({
        url: '/judging/judge/delete',
        params: {
          id: deletingjudge.id,
        },
      });
      if (data) {
        setdeletingjudge(null);
        toastr.success('Judge removed successfully');
        getJudges();
        getEntries();
      }
    } catch (error) {}
  };

  const removeEntry = async () => {
    try {
      const data = await postData({
        url: '/judging/judgingresult/delete',
        params: {
          id: deletingentry.id,
        },
      });
      if (data) {
        setdeletingentry(null);
        toastr.success('Entry removed successfully');
        getJudges();
        getEntries();
      }
    } catch (error) {}
  };

  const unassignEntry = async () => {
    try {
      const data = await postData({
        url: '/judging/judge/unassign',
        params: {
          judge: activejudge.id,
          entry: unassign.id,
        },
      });
      if (data) {
        toastr.success('Entry unassigned successfully');
        setunassign(null);
        setactivejudge(null);
        getJudges();
        getEntries();
      }
    } catch (error) {}
  };
  const selectEntry = (entry) => {
    setactivejudge(null);
    setactiveentry(activeentry && activeentry.id === entry.id ? null : entry);
  };
  const selectJudge = (judge) => {
    setactiveentry(null);
    setactivejudge(activejudge && activejudge.id === judge.id ? null : judge);
  };

  const isHighlighted = (results) => {
    if (activejudge) {
      const ind =
        results && Array.isArray(results)
          ? results.findIndex(({ JudgeId }) => JudgeId === activejudge.JudgeId)
          : -1;
      return ind !== -1;
    }

    return false;
  };

  const viewScore = (entry) => {
    let currentscore = null;
    let currentremarks = {};
    entry.JudgingResults.forEach((result) => {
      if (result.JudgeId === activejudge.JudgeId) {
        currentscore = result.Points;
        currentremarks = result.Remarks || {};
      }
    });
    setviewing({
      currentremarks,
      currentscore,
      JudgingLevelId: entry.JudgingLevelId,
      JudgeName: activejudge.JudgeName,
      EntryName: entry.EntryName,
      FieldData: entry.FieldData,
    });
  };

  const levelProgress = (entries) => {
    let progress = 0;
    const complete = [...(entries || [])]
      .map(({ JudgingResults }) => {
        const done = [...(JudgingResults || [])].map(
          ({ Points, JudgeId, Final }) => {
            if (Object.keys(Points).length > 0 && Final) {
              return JudgeId;
            }
            return null;
          }
        );
        if (done.length > 0) return done;
        return null;
      })
      .filter(Boolean);
    const flattened = complete.flat();
    const filtered = flattened.filter(Boolean);
    progress =
      Number(filtered.length || 0) / Number(flattened.length || 0) || 0;
    return progress * 100;
  };

  const progressColor = (progress) => {
    if (progress < 50) {
      return colors.red;
    }
    if (progress < 75) {
      return colors.orange;
    }
    if (progress < 100) {
      return colors.success;
    }
    return colors.green;
  };
  const assignedEntries = () => {
    const unassigned = entries
      .map(({ JudgingResults }) =>
        (JudgingResults || []).length === 0 ? 1 : 0
      )
      .reduce((a, b) => a + b, 0);
    if (unassigned === 0) {
      return (
        <Text h4 bold success>
          All Entries are Assigned
        </Text>
      );
    }
    return (
      <Text h4 bold error>
        {unassigned} of {entries.length} Entries are not Assigned
      </Text>
    );
  };

  return (
    <Block margin={20}>
      <Block row space="between" flex={false}>
        <Text header center>
          {(editingRow || {}).Name || 'Assign'}
        </Text>
        <Button onClick={onCancelEdit}>
          <Text secondary>Close</Text>
        </Button>
      </Block>
      <Block row>
        <Block
          padding={20}
          style={{
            borderRight: '5px solid green',
          }}
        >
          <Text h2>Entries</Text>
          <Block row flex={false} style={{ alignSelf: 'flex-end' }} middle>
            <IconButton
              onClick={() => {
                setlistview((currentview) => !currentview);
              }}
              style={{ marginRight: sizes.doubleBaseMargin }}
            >
              {!listview ? (
                <ListIcon fontSize="large" color="primary" />
              ) : (
                <ViewModule fontSize="large" color="primary" />
              )}
            </IconButton>
            <Button
              contained
              color={colors.primary}
              onClick={() => setopenentries(true)}
            >
              Add Entries
            </Button>
          </Block>

          <Block
            card
            center
            middle
            flex={false}
            style={{
              border: `1px solid ${progressColor(levelProgress(entries))}`,
              background: `linear-gradient(90deg, rgb(${hexToRgb(
                progressColor(levelProgress(entries))
              )}, ${
                levelProgress(entries) === 0 || levelProgress(entries) === 100
                  ? 1
                  : 0.2
              }) ${levelProgress(entries)}%, ${colors.milkyWhite}  ${
                100 - levelProgress(entries)
              }%)`,
            }}
          >
            <Text>{`${levelProgress(entries).toFixed(2)}%`}</Text>
          </Block>
          {!listview && (
            <Block
              flex={false}
              style={{
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              <Block padding={sizes.padding} center style={{ width: '100%' }}>
                {assignedEntries()}
              </Block>
              <Block center>
                <Input
                  name="SearchPhrase"
                  label="Search Entry"
                  value={searchPhrase}
                  onChange={(ev) => {
                    try {
                      setsearchPhrase(ev.target.value);
                    } catch (error) {}
                  }}
                />
              </Block>
              {!searchPhrase && (
                <LevelCategories
                  entries={entries}
                  onAssign={assignEntry}
                  onUnassign={unassignEntry}
                  onRemove={removeEntry}
                  activejudge={activejudge}
                  selectCategory={setactivecategory}
                  activecategory={activecategory}
                  onCancelEdit={onCancelEdit}
                  getEntries={getEntries}
                  getJudges={getJudges}
                  setactivejudge={setactivejudge}
                />
              )}
              <Divider />
              <Block row wrap margin={[sizes.doubleBaseMargin, 0, 0, 0]}>
                {entries
                  .sort(
                    (a, b) =>
                      (a.JudgingResults || []).length -
                      (b.JudgingResults || []).length
                  )
                  .map((entry) => {
                    if (
                      !((entry || {}).EntryName || '')
                        .toLowerCase()
                        .includes((searchPhrase || '').toLowerCase())
                    ) {
                      return null;
                    }
                    if (
                      (activecategory &&
                        entry &&
                        entry.Category &&
                        entry.Category.CategoryCode ===
                          (activecategory || {}).CategoryCode &&
                        entry.StoryFormat ===
                          (activecategory || {}).StoryFormat) ||
                      !activecategory
                    ) {
                      return (
                        <LevelEntry
                          key={entry.id}
                          onAssign={assignEntry}
                          onRemove={(e) => setdeletingentry(e)}
                          highlighted={
                            isHighlighted(entry.JudgingResults, entry) ||
                            (activeentry && activeentry.id === entry.id)
                          }
                          selectEntry={() => selectEntry(entry)}
                          {...entry}
                          activejudge={activejudge}
                          viewScore={() => viewScore(entry)}
                          FieldData={entry.FieldData}
                        />
                      );
                    }
                    return null;
                  })}
              </Block>
            </Block>
          )}
          {listview && (
            <ResultsGrid
              JudgingLevelId={editingRow.id}
              JudgingLevelName={editingRow.name}
            />
          )}
          <Entry
            open={openentries}
            onClose={() => setopenentries(false)}
            pushEntries={(entries) => assignEntries(entries)}
          />
        </Block>

        <Block padding={20} flex={false} style={{ width: 400 }}>
          <Text h2>{activejudge ? activejudge.JudgeName : 'Judges'}</Text>
          <Button
            contained
            color={colors.primary}
            style={{ alignSelf: 'flex-end' }}
            onClick={() => setopenjudges(true)}
          >
            Add Judges
          </Button>
          <List>
            {judges.map((j, index) => (
              <LevelJudge
                key={j.id}
                entries={entries}
                selectJudge={selectJudge}
                index={index}
                j={j}
                setdeletingjudge={setdeletingjudge}
                activeentry={activeentry}
                activejudge={activejudge}
                activecategory={activecategory}
              />
            ))}
          </List>
          <Judges
            pushJudges={(judges) => assignJudges(judges)}
            onClose={() => setopenjudges(false)}
            open={openjudges}
          />
        </Block>
      </Block>
      <ConfirmDeleteDialog
        title={deletingentry ? deletingentry.EntryName : ''}
        open={deletingentry !== null}
        onDelete={removeEntry}
        onCancelDelete={() => setdeletingentry(null)}
      />
      <ConfirmDeleteDialog
        title={deletingjudge ? deletingjudge.JudgeName : ''}
        open={deletingjudge !== null}
        onDelete={removeJudge}
        onCancelDelete={() => setdeletingjudge(null)}
      />
      <ConfirmDeleteDialog
        title={unassign ? unassign.EntryName : ''}
        judge={activejudge ? activejudge.JudgeName : ''}
        open={unassign !== null}
        onDelete={unassignEntry}
        unassign
        onCancelDelete={() => setunassign(null)}
      />

      <CriteriaForm viewing={viewing} onClose={() => setviewing(null)} />
    </Block>
  );
};

export default AssignmentForm;
