/* eslint-disable capitalized-comments */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';

import Block from '@cloudhub-ux/core/Block';
import Alert from '@cloudhub-ux/core/Alert';
import useAdminAxios from 'admin/context/useAdminAxios';

import NomineeForm from './forms/NomineeForm';

// const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Nominee = () => {
  const { getData, adminaxiosinstance } = useAdminAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const deleteRows = async (deletingRows) => {
    const ids = deletingRows.map((item) => item.id);
    getData({ url: '/website/nominee/delete', params: { nominees: ids } });
    gridRef.current.onDeleteSuccess(deletingRows);
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'Category') {
      const Category = row.Category || {};
      return <TableCell>{`${Category.CategoryName || ''}`}</TableCell>;
    }
    if (column.name === 'MediaHouse') {
      const MediaHouse = row.MediaHouse || {};
      return <TableCell>{`${MediaHouse.MediahouseName || ''}`}</TableCell>;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [
    { name: 'NomineeCode', title: 'Nominee Code' },
    { name: 'NomineeName', title: 'Nominee Name' },
    { name: 'Category', title: 'Category' },
    { name: 'MediaHouse', title: 'Media House' },
  ];

  const columnWidths = [
    { columnName: 'NomineeCode', width: 120 },
    { columnName: 'NomineeName', width: 150 },
    { columnName: 'Category', width: 150 },
    { columnName: 'MediaHouse', width: 150 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="Nominees"
        url="/website/nominee/getall"
        axiosinstance={adminaxiosinstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
        pagingComponent={null}
      />
      {editingRow && (
        <NomineeForm
          editingRow={editingRow}
          onCancelEdit={() => setEditingRow(null)}
          onSave={(row) => gridRef.current.onSave(row)}
        />
      )}
    </Block>
  );
};

export default Nominee;
