import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import Button from '@cloudhub-ux/core/Button';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import TextArea from '@cloudhub-ux/core/TextArea';
import Text from '@cloudhub-ux/core/Text';
import { Form, Field } from '@cloudhub-ux/core/form';
import {
  DialogHeader,
  DialogActions,
  DialogContent,
} from '@cloudhub-ux/core/dialogs';
import { colors } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';

const SendSmsForm = (props) => {
  const { open, numbers } = props;
  const { postData } = useAdminAxios({});

  const handleFormSubmit = async (values) => {
    try {
      const data = await postData({
        url: '/system/sms/sendsms',
        params: values,
      });
      if (data) {
        props.onClose();
      }
    } catch (error) {}
  };

  return (
    <Dialog
      open={open}
      onClose={() => props.onClose}
      style={{
        minWidth: '500px',
        height: '400px',
      }}
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{ numbers: [...(numbers || [])] }}
        render={({ handleSubmit }) => (
          <React.Fragment>
            {' '}
            <DialogHeader>
              <Text h2>Send SMS</Text>
            </DialogHeader>
            <DialogContent>
              <div>
                <label>
                  To:{' '}
                  {numbers.length > 0
                    ? `${numbers[0]}${
                        numbers.length > 1
                          ? ` and ${numbers.length - 1} others`
                          : ''
                      }`
                    : 'No numbers selected'}
                </label>
              </div>
              <Field
                type="text"
                name="message"
                required
                label="Message"
                rows={6}
                component={TextArea}
              />
            </DialogContent>
            <DialogActions>
              <Button
                contained
                onClick={() => props.onClose()}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button contained onClick={handleSubmit} color={colors.secondary}>
                <SendIcon />
                Send
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default SendSmsForm;
