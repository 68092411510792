// import ThemeProvider from 'cloudhub-front/dist/theme/ThemeProvider';
import ClientThemeProvider from '@cloudhub-ux/min/theme';
import React from 'react';
import { colors, fonts, sizes } from 'theme';
import BaseTheme from '@cloudhub-ux/core/baseweb/theme/BaseTheme';
import CONFIG from 'config/CONFIG';
import AdminHome from './AdminHome';
import AdminRoutes from './AdminRoutes';
import AdminProvider from './context/AdminProvider';

const AdminApp = () => (
  <ClientThemeProvider
    colors={colors}
    fonts={fonts}
    sizes={sizes}
    CONFIG={CONFIG}
  >
    <BaseTheme>
      <AdminProvider>
        <AdminHome>
          <AdminRoutes />
        </AdminHome>
      </AdminProvider>
    </BaseTheme>
  </ClientThemeProvider>
);

export default AdminApp;
