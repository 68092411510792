import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import dayjs from 'dayjs';
import { sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';

const isoWeek = require('dayjs/plugin/isoWeek');

dayjs.extend(isoWeek);

const UserDashboard = () => {
  const [registrations, setregistrations] = React.useState(0);
  const [allusers, setallusers] = React.useState(0);

  const { data } = useAdminAxios({
    url: '/system/user/count',
  });
  React.useEffect(() => {
    if (data) {
      const thisweek = data.newusers
        ? data.newusers
            .map((week) =>
              Number((week.Week || '').split('-')[0]) === dayjs().year() &&
              Number((week.Week || '').split('-')[1]) === dayjs().isoWeek()
                ? week.Registered
                : null
            )
            .filter(Boolean)[0]
        : 0;
      setallusers(data.allusers || 0);
      setregistrations(thisweek);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(data)]);
  return (
    <Block paper margin={sizes.doubleBaseMargin} center middle row>
      <Text h1 primary style={{ padding: sizes.padding * 2 }}>
        Users
      </Text>
      <Block padding={sizes.padding * 2}>
        <Block right middle>
          <Text header>
            Total :{' '}
            <Text h1 primary>
              {allusers || '0'}
            </Text>
          </Text>
        </Block>
        <Block right middle>
          <Text header>
            This week :{' '}
            <Text h1 primary>
              {registrations || '0'}
            </Text>
          </Text>
        </Block>
      </Block>
    </Block>
  );
};
export default UserDashboard;
