export const SET_TOKEN = 'SET_TOKEN';
export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const AUTH_ERROR = 'AUTH_ERROR';
export const OPEN_SIGN_IN = 'OPEN_SIGN_IN';
export const CLOSE_SIGN_IN = 'CLOSE_SIGN_IN';
export const OPEN_CHANGE_PASSWORD = 'OPEN_CHANGE_PASSWORD';
export const CLOSE_CHANGE_PASSWORD = 'CLOSE_CHANGE_PASSWORD';
export const OPEN_UPDATE_PROFILE = 'OPEN_UPDATE_PROFILE';
export const CLOSE_UPDATE_PROFILE = 'CLOSE_UPDATE_PROFILE';
export const SEND_CONFIRMATION_CODE = 'SEND_CONFIRMATION_CODE';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';

export const OPEN_GET_STARTED = 'OPEN_GET_STARTED';
export const CLOSE_GET_STARTED = 'CLOSE_GET_STARTED';
