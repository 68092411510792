import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Button from '@cloudhub-ux/core/Button';
import Input from '@cloudhub-ux/core/Input';
import TextArea from '@cloudhub-ux/core/TextArea';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import { Form, Field } from '@cloudhub-ux/core/form';
import Alert from '@cloudhub-ux/core/Alert';
import Dialog from '@cloudhub-ux/core/dialogs/Dialog';
import DialogHeader from '@cloudhub-ux/core/dialogs/DialogHeader';
import DialogContent from '@cloudhub-ux/core/dialogs/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialogs/DialogActions';
import { colors } from 'theme';
import moment from 'moment';

import useAdminAxios from 'admin/context/useAdminAxios';

const MessageForm = (props) => {
  const { postData, error, reset } = useAdminAxios();

  const onSaveMessage = async (values) => {
    const data = await postData({
      url: '/website/message/savemessage',
      params: {
        ...values,
      },
    });

    if (data) {
      props.onSave(data);
      props.onCancelEdit();
    }
  };

  const { editingRow } = props;
  const values = editingRow || {};

  const title = values.id ? values.Fullname || 'View Message' : 'Add Message';

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      minHeight={700}
    >
      <DialogHeader onClose={props.onCancelEdit}>
        {`${title} @ ${moment(values.createdAt).format(
          'DD MMM yyyy: hh:mm a'
        )}`}
      </DialogHeader>

      <Form
        onSubmit={onSaveMessage}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting }) => (
          <React.Fragment>
            <DialogContent>
              <Block>
                <Field
                  label="Full Name"
                  name="Fullname"
                  component={Input}
                  readOnly
                />
                <FieldBlock row>
                  <Field
                    label="Email"
                    name="Email"
                    component={Input}
                    flex
                    readOnly
                  />
                  <Field
                    label="Phone"
                    name="Phone"
                    component={Input}
                    flex
                    readOnly
                  />
                </FieldBlock>
                <Field
                  label="Message"
                  name="Message"
                  component={TextArea}
                  rows={10}
                  readOnly
                />
              </Block>
            </DialogContent>

            <DialogActions>
              <Block>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset()} />
                )}
              </Block>
              <Button
                contained
                color={colors.danger}
                onClick={props.onCancelEdit}
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                contained
                color={colors.success}
                onClick={handleSubmit}
                disabled
              >
                {submitting ? 'Please wait...' : 'Save'}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

MessageForm.defaultProps = {
  onSave: () => {},
};

export default MessageForm;
