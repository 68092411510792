import React from 'react';

import Block from '@cloudhub-ux/core/Block';
import Text from '@cloudhub-ux/core/Text';
import TextArea from '@cloudhub-ux/core/TextArea';
import Input from '@cloudhub-ux/core/Input';
import FieldBlock from '@cloudhub-ux/core/FieldBlock';
import { Form, Field } from '@cloudhub-ux/core/form';

const CriteriaForm = ({
  currentscore,
  currentremarks,
  maxscores,
  judgingcriteria,
}) => {
  const [score, setscore] = React.useState({});
  const [remarks, setremarks] = React.useState(currentremarks);

  React.useEffect(() => {
    if (
      currentscore &&
      Object.keys(currentscore || []).length > 0 &&
      // weights &&
      maxscores
    ) {
      const rawscore = {};
      const keys = Object.keys(currentscore || []);
      keys.map((k) => {
        const thisscore = Number(currentscore[k]); //* Number(maxscores[k])) / Number(weights[k])

        rawscore[k] = thisscore;
        return rawscore;
      });
      setscore(rawscore);
    } else {
      setscore({});
    }
  }, [maxscores, currentscore]);

  React.useEffect(() => {
    setremarks(currentremarks);
  }, [currentremarks]);

  return (
    <Block>
      <Form
        onSubmit={() => {}}
        initialValues={{ ...score, ...remarks }}
        render={({ values }) => (
          <Block style={{ width: '100%' }}>
            {judgingcriteria &&
              Array.isArray(judgingcriteria.Parameters) &&
              judgingcriteria.Parameters.map((parameter) => (
                <Block key={parameter.ParameterName}>
                  <Text header>{parameter.ParameterName}</Text>
                  <Text bold dark style={{ fontStyle: 'italic' }}>
                    {parameter.Description || ''}
                  </Text>
                  <Text>{parameter.UmbrellaQuestion || ''}</Text>
                  {parameter.Questions &&
                    Array.isArray(parameter.Questions) &&
                    parameter.Questions.map((question, index) => (
                      <React.Fragment key={question.Question}>
                        {(values[
                          `${parameter.ParameterName.replace(
                            /[^a-zA-Z]/g,
                            ''
                          )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
                        ] ||
                          values[
                            `${parameter.ParameterName.replace(
                              /[^a-zA-Z]/g,
                              ''
                            )}${question.Question.replace(/[^a-zA-Z]/g, '')}`
                          ] === 0) && (
                          <Field
                            type="number"
                            name={`${parameter.ParameterName.replace(
                              /[^a-zA-Z]/g,
                              ''
                            )}${question.Question.replace(/[^a-zA-Z]/g, '')}`}
                            label={`${index + 1}. ${question.Question}`}
                            component={Input}
                            disabled
                          />
                        )}
                        <FieldBlock>
                          {values[
                            `${parameter.ParameterName.replace(
                              /[^a-zA-Z]/g,
                              ''
                            )}${question.Question.replace(
                              /[^a-zA-Z]/g,
                              ''
                            )}Remarks`
                          ] && (
                            <Field
                              type="number"
                              name={`${parameter.ParameterName.replace(
                                /[^a-zA-Z]/g,
                                ''
                              )}${question.Question.replace(
                                /[^a-zA-Z]/g,
                                ''
                              )}Remarks`}
                              label="Remarks:"
                              component={TextArea}
                              disabled
                            />
                          )}
                        </FieldBlock>
                      </React.Fragment>
                    ))}
                </Block>
              ))}
          </Block>
        )}
      />
    </Block>
  );
};
export default CriteriaForm;
